import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  List,
  ListItem,
  TextField,
  Paper,
  IconButton,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { GOOGLE_API_KEY } from "utils/constants";
import Map from "../Map";
import { selectStation, selectStationAddress, selectStationCoordinates } from "app/containers/Stations/redux/selector";
import { actions } from "app/containers/Stations/redux/slice";

const Address = ({
  disabled,
  isPlacePredictionsLoading,
  placePredictions,
  getPlacePredictions,
}) => {
  const dispatch = useDispatch();
  const coordinatesValue =
    useSelector(selectStationCoordinates)
  console.log({coordinatesValue})
  const address = useSelector(selectStationAddress)


  const [open, setOpen] = useState(false);



  const fetchPlaceDetails = useCallback((placeId, updateAddress) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (
        place &&
        status === window.google.maps.places.PlacesServiceStatus.OK
      ) {
        const lat = place?.geometry?.location?.lat() || 0;
        const lng = place?.geometry?.location?.lng() || 0;

        dispatch(
          actions.setFormValues({ key: "coordinates", value: [lat, lng] })
        );
        if (updateAddress) {
 
          dispatch(
            actions.setFormValues({
              key: "address",
              value: place.formatted_address || '',
            })
          );
        }
      }
    });
  }, []);

  const handlePredictionClick = (prediction) => {
    dispatch(
      actions.setFormValues({ key: "address", value: prediction.description })
    );
    setOpen(false);
    fetchPlaceDetails(prediction.place_id, false);
  };

  const handleMapClick = useCallback(
    (event) => {
      if(disabled) {return}
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: event.latLng }, (results, status) => {
        if (status === "OK") {
          if (results && results[0]) {
            const placeId = results[0].place_id;
            fetchPlaceDetails(placeId, true);
          } else {
            console.error("No results found");
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
      dispatch(
        actions.setFormValues({ key: "coordinates", value: [lat, lng] })
      );
    },
    [fetchPlaceDetails, disabled]
  );

  return (
    <div style={{ position: "relative" }}>
      <TextField
        fullWidth
        style={{ color: "black", background: "#fff" }}
        value={address}
        label={"Address"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isPlacePredictionsLoading && <CircularProgress size={20} />}
              <Tooltip
                title={`Lat: ${coordinatesValue[0]}, Lng: ${coordinatesValue[1]}`}
              >
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        onChange={(evt) => {
          const value = evt.target.value || ''
          dispatch(
            actions.setFormValues({ key: "address", value })
          );
          setOpen(true);
          getPlacePredictions({ input: value, region: "IL" });
        }}
        // onBlur={() => {
        //  // setOpen(false);
        // }}
        disabled={disabled}
      />

      {open && (
        <Paper
          style={{
            position: "absolute",
            top: "56px",
            left: 0,
            right: 0,
            zIndex: 1,
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          <List sx={{ cursor: "pointer" }}>
            {placePredictions.map((prediction) => (
              <ListItem
                key={prediction?.place_id || ""}
                onClick={() => {
                  handlePredictionClick(prediction);
                }}
              >
                {prediction?.description}
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      <Map
        handleMapClick={handleMapClick}
        lat={coordinatesValue[0]}
        lng={coordinatesValue[1]}
      />
    </div>
  );
};

const AddressHOC = ({ disabled }) => {
  const [count, setCount] = useState(0);
  const { isPlacePredictionsLoading, placePredictions, getPlacePredictions } =
    useGoogle({
      debounce: 300,
      apiKey: GOOGLE_API_KEY,
      libraries: ["maps", "places"],
    });

  useEffect(() => {
    if (!window?.google?.maps) {
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, []);
  if (!window?.google?.maps) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={30} />{" "}
      </Box>
    );
  }
  return (
    <Address
      disabled={disabled}
      isPlacePredictionsLoading={isPlacePredictionsLoading}
      placePredictions={placePredictions}
      getPlacePredictions={getPlacePredictions}
    />
  );
};

export default React.memo(AddressHOC);
