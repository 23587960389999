import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import ActionButtons from "app/components/ActionButtons";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomer, selectIsLoading, selectIsUnsaved } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { validateCustomer } from "./validator";
import { selectOrganization } from "app/redux/selectors";
import ConfirmDialog from "app/components/ConfirmDialog";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customer = useSelector(selectCustomer);
  const isLoading = useSelector(selectIsLoading);
  const organizationId = useSelector(selectOrganization);
  const isUnsaved = useSelector(selectIsUnsaved);
  
  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(()=>{
    if(!id || mode === MODES.NEW) return;
    dispatch(actions.getCustomer(id))
  },[id, mode, dispatch])

  useEffect(()=>{
    return () => {
      dispatch(actions.resetForm());
    }
  },[dispatch])

  const onSubmit = () => {
    const errors = validateCustomer(customer);
    if(errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(actions.createCustomer({
      customer: {
        organizationId: organizationId?.value ?? "",
        name: customer.name.value,
        companyName: customer.companyName.value,
        taxNumber: customer.taxNumber.value,
        email: customer.email.value,
        address: customer.address.value,
        mobileNumber: customer.mobileNumber.value,
        city: customer.city.value,
      },
      callback: () => navigate('/app/customers')
    }));
  }

  const onUpdate = () => {
    const errors = validateCustomer(customer);
    if(errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(actions.updateCustomer({
      customer: {
        _id: customer._id.value,
        organizationId: organizationId?.value ?? "",
        name: customer.name.value,
        companyName: customer.companyName.value,
        taxNumber: customer.taxNumber.value,
        email: customer.email.value,
        address: customer.address.value,
        city: customer.city.value,
        mobileNumber: customer.mobileNumber.value,
      },
      callback: () => navigate('/app/customers')
    }));
  }

  const handleConfirmBtn = () => {
    if(mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if(mode === MODES.EDIT) {
      onUpdate();
      return;
    }
    navigate(`/app/customers/${id}/edit`);
  };


  const handleCancelBtn = () => {
    if (mode == MODES.EDIT) {
      if(isUnsaved){
        setShowConfirm(true);
        return ;
      }
    }
    navigate("/app/customers");
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    navigate("/app/customers");
  };
  const openAddTicketPageInNewTab = () => {
    window.open("/app/tickets/new", "_blank");
  };

  return (
    <>
      <Grid
        container
        md={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={6} className={styles.breadCrumbSectionParent}>
          <BreadCrumb
            title={generatePageTitle(Modules.CUSTOMERS, mode)}
            onBackClick={handleCancelBtn}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <PrimaryButton
            onClick={openAddTicketPageInNewTab}
            label={t("CUSTOMERS.BUTTONS.ADD_TICKET")}
            sx={{
              textTransform: "uppercase",
              backgroundColor: "#E24399",
              width: "170px",
              fontWeight: 500,
            }}
          />
        </Grid>
      </Grid>
      <Form mode={mode} />
      <div style={{ height: "30px" }} />
      <ActionButtons
        mode={mode}
        handleCancelBtn={handleCancelBtn}
        handleConfirmBtn={handleConfirmBtn}
        isLoading={isLoading}
      />
       <ConfirmDialog
        open={showConfirm}
        handleClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.CUSTOMER"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
    </>
  );
};

export default Details;
