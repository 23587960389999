import React, { useEffect } from "react";
import InputField from "app/components/InputField";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CitySelect from "app/components/CitySelect";
import { useTranslation } from "react-i18next";
import { MODES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomer } from "app/containers/Customers/redux/selector";
import { actions } from "app/containers/Customers/redux/slice";
import Summary from "../Summary";
import { validateAndFormatIsraeliMobileNumber } from "styles/theme/utils";

type Props = { mode: MODES };

const Form = ({ mode }: Props) => {
  const { t } = useTranslation();
  const customer = useSelector(selectCustomer);
  const dispatch = useDispatch();

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setFormValue({ key: name, value }));
  };

  return (
    <>
      {mode === MODES.NEW ? <></> : <Summary />}
      <Grid2
        container
        xs={12}
        sx={{
          padding: "20px",
          backgroundColor: "#F3F6F8",
          borderRadius: "8px",
        }}
      >
        <Grid2 container spacing={3} md={12} sx={{ paddingBottom: "20px" }}>
          <Grid2 xs={4}>
            <InputField
              label={t("CUSTOMERS.FORM.LABELS.COMPANY_NAME")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="companyName"
              fullWidth={true}
              isFieldReq
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              value={customer?.companyName?.value}
              error={
                customer?.companyName?.error
                  ? t(customer?.companyName?.error)
                  : undefined
              }
              onChange={onChange}
            />
          </Grid2>
          <Grid2 xs={4}>
            <InputField
              label={t("CUSTOMERS.FORM.LABELS.EMAIL")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="email"
              fullWidth={true}
              isFieldReq
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              value={customer?.email?.value}
              error={
                customer?.email?.error ? t(customer?.email?.error) : undefined
              }
              onChange={onChange}
            />
          </Grid2>
          <Grid2 xs={4}>
            <InputField
              label={t("CUSTOMERS.FORM.LABELS.TAX_NUMBER")}
              placeholder="Enter"
              name="taxNumber"
              fullWidth={true}
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              value={customer?.taxNumber?.value}
              error={
                customer?.taxNumber?.error
                  ? t(customer?.taxNumber?.error)
                  : undefined
              }
              onChange={onChange}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={3} md={12} sx={{ paddingBottom: "20px" }}>
          <Grid2 xs={6}>
            <InputField
              label={t("CUSTOMERS.FORM.LABELS.CUSTOMER_NAME")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="name"
              fullWidth={true}
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              onChange={onChange}
              value={customer?.name?.value}
              error={
                customer?.name?.error ? t(customer?.name?.error) : undefined
              }
            />
          </Grid2>
          <Grid2 xs={6}>
            <InputField
              label={t("CUSTOMERS.FORM.LABELS.MOBILE_NUMBER")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="mobileNumber"
              fullWidth={true}
              isFieldReq
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              value={
                validateAndFormatIsraeliMobileNumber(
                  customer?.mobileNumber?.value
                ).formattedNumber
              }
              error={
                customer?.mobileNumber?.error
                  ? t(customer?.mobileNumber?.error)
                  : undefined
              }
              onChange={(e)=>{
                const value = e.target.value;
                dispatch(actions.setFormValue({ key: "mobileNumber", value }));
              }}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={3} md={12} sx={{ paddingBottom: "20px" }}>
          <Grid2 xs={6}>
            <InputField
              label={t("CUSTOMERS.FORM.LABELS.ADDRESS")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="address"
              fullWidth={true}
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              value={customer?.address?.value}
              error={
                customer?.address?.error
                  ? t(customer?.address?.error)
                  : undefined
              }
              onChange={onChange}
            />
          </Grid2>
          <Grid2 xs={6}>
            <CitySelect
              isFieldReq={false}
              label={t("COMMON.LABELS.CITY")}
              disabled={mode === MODES.VIEW}
              value={customer?.city?.value }
              error={
                customer?.city?.error ? t(customer?.city?.error) : undefined
              }
              onChange={(v) =>
                dispatch(actions.setFormValue({ key: "city", value: v }))
              }
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
};

export default Form;
