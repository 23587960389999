import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

export default function PopupDialog({
  open,
  handleClose,
  children,
  containerStyle
}:{
  open: boolean;
  handleClose?: () => void;
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
}) {
  const direction = useSelector(selectDirection)
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: "24px",
          ...containerStyle
        }
      }}
      open={open}
      onClose={handleClose}
      dir={direction}
    >
      {children}
    </Dialog>
  );
}