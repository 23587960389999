import React from 'react'
import PopupDialog from '../PopupDialog'
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import OutlineButton from '../Buttons/OutlineButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

export default function ConfirmDialog({
    open,
    handleClose,
    title="Unsaved changes",
    message="You have some unsaved changes on Edit Ticket Details screen. Save it before leaving the screen.",
    messageSecond,
    onConfirm,
    confirmBtnLabel="Save and continue",
    cancelBtnLabel="Continue without saving",
    containerStyle,
    titleStyle,
    messageStyle,
    confirmButtonStyle,
    cancelButtonStyle,
    children,
    innerContainerStyle,
    isLoading
}:{
    open: boolean;
    handleClose: ()=>void;
    title?: string;
    message?: string;
    messageSecond?: string;
    onConfirm?: ()=>void;
    confirmBtnLabel?: string;
    cancelBtnLabel?: string;
    containerStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    messageStyle?: React.CSSProperties;
    confirmButtonStyle?: SxProps<Theme>;
    cancelButtonStyle?: SxProps<Theme>;
    children?: React.ReactNode;
    innerContainerStyle?: SxProps<Theme>;
    isLoading?: boolean;
}) {
    const direction = useSelector(selectDirection);
    return (
        <PopupDialog
            open={open}
            handleClose={!isLoading ? handleClose : ()=>{}} // Disable closing dialog when loading
            containerStyle={containerStyle}
        >
            <Box
                sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    alignItems: "center",
                    paddingX: "50px",
                    paddingY: "30px",
                    ...innerContainerStyle
                }}
            >
                <IconButton
                    sx={{
                        backgroundColor: "#D7D7E0",
                        position: "absolute",
                        top: "22px",
                        right: "22px",
                        width: "32px",
                        height: "32px"
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon 
                        sx={{
                            color: "#ffffff",
                            fontSize: 20
                        }}
                    />
                </IconButton>
                <Typography
                    sx={{
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontSize: "24px",
                        fontWeight: 600,
                        lineHeight: "29px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#207DB9",
                        ...titleStyle
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "5px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#5E6781",
                        ...messageStyle
                    }}
                >
                    {message}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "5px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#5E6781",
                        ...messageStyle
                    }}
                >
                    {messageSecond}
                </Typography>
                
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    {children}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        mt: "10px",
                        width: "100%",
                    }}
                >
                    <OutlineButton 
                        label={cancelBtnLabel}
                        sx={{
                            textTransform: "none",
                            ...cancelButtonStyle
                        }}
                        onClick={handleClose}
                        disabled={isLoading}
                    />
                    <PrimaryButton 
                        label={confirmBtnLabel}
                        sx={{
                            textTransform: "none",
                            ...confirmButtonStyle
                        }}
                        onClick={onConfirm}
                        isLoading={isLoading}
                    />
                </Box>
            </Box>
        </PopupDialog>
    )
}
