import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import SearchInput from "app/components/SearchInput";
import CitySelect from "app/components/CitySelect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Header({ disabled, provisionLabel }: { disabled?: boolean; provisionLabel?: boolean; }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setCitySearchQuery = (city: string) => {
    console.log(" e.target.value", city);
    const params = new URLSearchParams(window.location.search);
    params.set("city", city);
    navigate(`?${params.toString()}`);
  };
  return (
    <>
      <Grid2 xs={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <SearchInput
            disabled={disabled}
            provisionLabel
          />
        </Box>
      </Grid2>
      <Grid2 xs={4}>
        <CitySelect
          label={t("COMMON.LABELS.CITY")}
          onChange={(value) => 
            setCitySearchQuery(value?.value || "")
          }
          backgroundColor="#F3F6F8"
          borderWidth={0}
        />
      </Grid2>
    </>
  );
}
