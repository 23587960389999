import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { selectGame } from 'app/containers/Games/redux/selector'
import { actions } from 'app/containers/Games/redux/slice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirection } from 'styles/theme/slice'

export default function WelcomeForm({
    disabled
}:{
    disabled?: boolean
}) {
    const { t } = useTranslation()
    const game = useSelector(selectGame)
    const dispatch = useDispatch()
  const direction = useSelector(selectDirection);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      dispatch(actions.setFormValues({ key: name, value }));
    }
    return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "32px",
              fontWeight: 700,
              linHeight: "32px",
              letterSpacing: "0em",
              color: "#207DB9",
            }}
          >
            {t("GAMES.FORM.WELCOME_SCREEN")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
            }}
          >
            <FormControlLabel
              sx={{
                margin: 0,
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0em",
                background: "#F3F6F8",
                padding: "8px 24px 8px 8px",
                borderRadius: "8px",
              }}
              disabled={disabled}
              label={
                <Typography
                  sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "14px",
                    letterSpacing: "0em",
                  }}
                >
                  {t("GAMES.FORM.LABELS.SHOW_START_POINT")}
                </Typography>
              }
              control={
                <Checkbox
                  size="small"
                  name="showStartPoint"
                  onChange={onChange}
                  checked={game?.showStartPoint.value}
                  defaultChecked={game?.showStartPoint.value}
                  value={game?.showStartPoint.value}
                  sx={{
                    color: "#207DB9",
                    "&.Mui-checked": {
                      color: "#207DB9",
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              sx={{
                margin: 0,
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0em",
                background: "#F3F6F8",
                padding: "8px 24px 8px 8px",
                borderRadius: "8px",
              }}
              disabled={disabled}
              label={
                <Typography
                  sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "14px",
                    letterSpacing: "0em",
                  }}
                >
                  {t("GAMES.FORM.LABELS.SHOW_END_POINT")}
                </Typography>
              }
              control={
                <Checkbox
                  name="showEndPoint"
                  onChange={onChange}
                  checked={game?.showEndPoint.value}
                  defaultChecked={game?.showEndPoint.value}
                  value={game?.showEndPoint.value}
                  size="small"
                  sx={{
                    color: "#207DB9",
                    "&.Mui-checked": {
                      color: "#207DB9",
                    },
                  }}
                />
              }
            />
          </Box>
        </Box>
    )
}
