import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    loginButtonWrapper: {
      width: "100%",
    },
    signUpWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#333333",
    },
    signUpText: {
      marginLeft: "8px",
      color: "skyblue",
      textDecoration: "underline",
      fontWeight: "bold",
      cursor: "pointer",
    },
    loginText: {
      fontFamily: "Inter",
      fontSize: "48px",
      fontWeight: 400,
      lineHeight: "58px",
      letterSpacing: "0em",
      textAlign: "center",
      color: "#207DB9",
      marginBottom: "16px",
    },
  
    buttonProgress: {
      color: "#fff",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "386px"
    },
    error: {
      width: "100%",
      textAlign: "center",
      justifyContent: "center",
      marginBottom: 10,
    },
    keepSignedin: {
      marginTop: 0,
      "& .MuiFormControlLabel-root": {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    submit: {
      width: "385px",
      height: "56px",
      fontSize: "16px",
      fontFamily: "inherit",
      fontStyle: "bold",
      lineHeight: "30px",
      fontWeight: 700,
      borderRadius: "4px",
      color: "#FFFFFF",
      "&:hover": {
        background: "#17171B",
      },
    },
    languageSwitch: {
      position: "absolute",
      top: "10px",
      right: "10px",
    },
    email: {
      marginTop: 0,
      marginBottom: 23,
      borderRadius: "4px",
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 30px #fff inset !important",
        backgroundColor: "#fff",
        backgroundImage: "none !important",
        color: "#000000 !important",
      },
      "& .MuiFormControl-root": {},
      "& .MuiOutlinedInput-input": {},
      "& .MuiOutlinedInput-root": {
        width: "385px",
        height: "56px",
        borderColor: "coral",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "6px",
      },
    },
    password: {
      margin: 0,
      borderRadius: "4px",
      "& input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 30px #fff inset !important",
        backgroundColor: "#fff",
        backgroundImage: "#fff !important",
        color: "#000000 !important",
        border: "1px solid #E2E7EB",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
      },
      "& .MuiFormControl-root": {},
      "& .MuiOutlinedInput-input": {},
      "& .MuiOutlinedInput-root": {
        width: "385px",
        height: "56px",
      },
    },
    helperText: {
      marginLeft: "0px", // Adjust the margin value as per your requirement
    },
    labelKeepSignedIn: {
      fontSize: 16,
      fontWeight: 400,
      fontFamily: "inherit",
      fontStyle: "normal",
      marginLeft: "5px",
    },
    dontHaveAccountText: {},
    phoneIcon: {
      position: "absolute",
      top: -18,
      right: -45,
    },
    forgotPassword: {
      color: "#387E8D",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 400,
      fontFamily: "inherit",
      fontStyle: "normal",
      textDecoration: "underline",
    },
    textWrapper: {
      display: "flex",
      alignItems: "center",
      margin: "5px auto 24px",
    },
    copyRightText: {
      fontSize: 14,
      color: "#00000062",
      textAlign: "center",
      position: "absolute",
    },
    inputLabel: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "150%",
      color: "#2B2B2B",
      marginBottom: "5px",
    },
  }))