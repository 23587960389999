import {
  Box,
  FormControlLabel,
  Radio,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import InfoIcon from "@mui/icons-material/Info";
import DragNDropDialog from "../DragNDropDialog";
import { useTranslation } from "react-i18next";
import { CreateAssetResponse } from "../DragNDropDialog/types";
import { PictureAsPdfSharp } from "@mui/icons-material";
import { getFileNameFromUrl } from "utils/constants";
import { MediaTypes } from "app/containers/types";

interface MediaUploaderProps {
  disabled?: boolean;
  type?: MediaTypes;
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  containerStyle?: SxProps<Theme>;
  innerContainerStyle?: SxProps<Theme>;
  dashedBorderStyle?: SxProps<Theme>;
  btnLabel?: string;
  btnStyle?: SxProps<Theme>;
  label?: string;
  showBtn?: boolean;
  caption?: string;
  primaryColor?: string;
  secondaryColor?: string;
  labelStyle?: SxProps<Theme>;
  children?: React.ReactNode;
  onSuccess: (data?: CreateAssetResponse) => void;
  src?: string;
  error?: string;
  checked?: boolean;
  disablePicker?: boolean;
  title?: string;
  width?: string;
  height?: string;
  fallbackWidth?: string;
  fallbackHeight?: string;
  tooltip?: string;
}

const DisplayImage = ({
  src,
  width,
  height,
}: {
  src: string;
  width?: string;
  height?: string;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: width || "320px",
        height: height || "519px",
        borderRadius: "4px",
        border: "1px dashed #8890A6",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "34px",
      }}
    >
      <img
        src={src}
        alt="uploaded"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "4px",
        }}
      />
    </Box>
  );
};

const DisplayVideo = ({
  src,
  width,
  height,
}: {
  src: string;
  width?: string;
  height?: string;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: width || "320px",
        height: height || "200px",
        borderRadius: "4px",
        border: "1px dashed #8890A6",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "34px",
      }}
    >
      <video
        src={src}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "4px",
        }}
        controls
      />
    </Box>
  );
};

const DisplayPDF = ({
  src,
  width,
  height,
}: {
  src: string;
  width?: string;
  height?: string;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: width || "320px",
        height: height || "200px",
        borderRadius: "4px",
        border: "1px dashed #8890A6",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PictureAsPdfSharp />
      <Typography>{getFileNameFromUrl(src)}</Typography>
    </Box>
  );
};

interface Props {
  type:MediaTypes;
  caption: string;
  error?: string;
  width?: string;
  height?: string;
}

const DisplayContent = ({ type, caption, error, width, height }: Props) => {
  return (
    <>
      <Box
        sx={{
          width: width || "80%",
          height: height || "100%",
          borderRadius: "4px",
          border: `1px dashed ${error ? "#d32f2f" : "#8890A6"}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {type === "image" ? (
          <BrokenImageIcon sx={{ color: "#D7D7E0", fontSize: "100px" }} />
        ) : (
          <VideocamIcon sx={{ color: "#D7D7E0", fontSize: "100px" }} />
        )}
      </Box>
      {error ? (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#d32f2f",
            textAlign: "center",
            paddingX: "20px",
          }}
        >
          {error}
        </Typography>
      ) : (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            textAlign: "center",
            paddingX: "20px",
          }}
        >
          {caption}
        </Typography>
      )}
    </>
  );
};

export default function MediaUploader({
  disabled = false,
  type = MediaTypes.IMAGE,
  onCheck,
  containerStyle,
  innerContainerStyle,
  btnLabel,
  btnStyle,
  label,
  showBtn = true,
  caption,
  secondaryColor,
  primaryColor,
  labelStyle,
  children,
  src,
  onSuccess,
  error,
  checked,
  disablePicker,
  title,
  width,
  height,
  fallbackWidth,
  fallbackHeight,
  tooltip,
}: MediaUploaderProps) {
  const [showUploadDialog, setShowUploadDialog] = React.useState<{
    open: boolean;
    type: MediaTypes;
  }>({
    open: false,
    type: MediaTypes.IMAGE,
  });
  const { t } = useTranslation();
  return (
    <>
      {label && (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#3C3C43",
            ...labelStyle,
          }}
        >
          {label}
          <Tooltip title={tooltip}>
            <InfoIcon
              sx={{ color: "#3C3C43", fontSize: "16px", marginLeft: "8px" }}
            />
          </Tooltip>
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          height: "100%",
          ...containerStyle,
        }}
      >
        {children ? (
          <>
            <div
              onClick={
                !disabled && !disablePicker
                  ? () => {
                      setShowUploadDialog({
                        open: true,
                        type,
                      });
                    }
                  : undefined
              }
            >
              {children}
            </div>
            {error && (
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 400,
                  letterSpacing: "0em",
                  color: "#d32f2f",
                }}
              >
                {error}
              </Typography>
            )}
          </>
        ) : (
          <Box
            sx={{
              height: "100%",
              padding: "16px",
              borderRadius: "4px",
              border: "1px solid #D7D7E0",
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            {onCheck && (
              <Box
                sx={{
                  marginBottom: "0px",
                  height: "fit-content",
                  width: "100px",
                  background: secondaryColor || "#E9F5FD",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingY: "5px",
                }}
              >
                <FormControlLabel
                  value="1"
                  sx={{
                    padding: "0px",
                    margin: "0px",
                    gap: "10px",
                  }}
                  disabled={disabled}
                  control={
                    <Radio
                      size="small"
                      onChange={onCheck}
                      checked={checked}
                      style={{
                        color: disabled ? "#8890A6" : primaryColor || "#207DB9",
                        padding: 0,
                      }}
                      disabled={disabled}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        color: primaryColor || "#207DB9",
                      }}
                    >
                      {type === "image"
                        ? t("COMMON.LABELS.IMAGE")
                        : t("COMMON.LABELS.VIDEO")}
                    </Typography>
                  }
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
              onClick={
                !disabled && !disablePicker
                  ? () => {
                      setShowUploadDialog({
                        open: true,
                        type,
                      });
                    }
                  : undefined
              }
            >
         
              {src ? (
                <>
                  {type === "image" && (
                    <DisplayImage width={width} height={height} src={src} />
                  )}
                  {type === "video" && (
                    <DisplayVideo width={width} height={height} src={src} />
                  )}
                  {type === "pdf" && <DisplayPDF src={src} />}
                </>
              ) : (
                <DisplayContent
                  type={type}
                  caption={caption as string}
                  error={error}
                  width={width}
                  height={height}
                />
              )}
            </Box>
          </Box>
        )}
        {showBtn && (
          <PrimaryButton
            sx={{
              width: "100%",
              textTransform: "uppercase",
              backgroundColor: "#E24399",
              fontSize: "14px",
              fontFamily: "Inter",
              ...btnStyle,
            }}
            disabled={disabled}
            startIcon={
              type === "image" ? <AddPhotoAlternateIcon /> : <VideoCallIcon />
            }
            label={
              type === "image"
                ? `${btnLabel ?? t("COMMON.LABELS.UPLOAD_IMAGE")}`
                : `${btnLabel ?? t("COMMON.LABELS.UPLOAD_VIDEO")}`
            }
            onClick={
              !disabled && !disablePicker
                ? () => {
                    setShowUploadDialog({
                      open: true,
                      type,
                    });
                  }
                : undefined
            }
          />
        )}
      </Box>
      <DragNDropDialog
        open={showUploadDialog.open}
        handleClose={() =>
          setShowUploadDialog({
            ...showUploadDialog,
            open: false,
          })
        }
        mediaType={showUploadDialog?.type}
        onSuccess={onSuccess}
        src={src}
        title={title}
        width={width}
        height={height}
        caption={caption}
        fallbackWidth={fallbackWidth ?? width ?? "320px"}
        fallbackHeight={fallbackHeight ?? height ?? "200px"}
      />
    </>
  );
}
