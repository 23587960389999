import { Box } from "@mui/material";
import OutlineButton from "app/components/Buttons/OutlineButton";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ActionButtons({
  handleClose,
  onConfirm,
  showCancel = true,
}: {
  readonly handleClose: () => void;
  readonly onConfirm?: () => void;
  readonly showCancel?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        mt: "10px",
        width: "100%",
      }}
    >
      {showCancel && (
        <OutlineButton
          label={t("COMMON.BUTTONS.CANCEL")}
          sx={{
            fontFamily: "Rubik",
            fontSize: "20px",
            fontWeight: 600,
            width: "100%",
            lineHeight: "32px",
            letterSpacing: "0em",
            color: "#26A8E0",
            textTransform: "uppercase",
            height: "60px",
            border: "1px solid #26A8E0",
            ":hover": {
              border: "1px solid #26A8E0",
            },
          }}
          onClick={handleClose}
        />
      )}
      <PrimaryButton
        label={t("COMMON.BUTTONS.SAVE")}
        sx={{
          fontFamily: "Rubik",
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "32px",
          width: "100%",
          letterSpacing: "0em",
          color: "#FFFFFF",
          textTransform: "uppercase",
          height: "60px",
          backgroundColor: "#26A8E0",
          ":hover": {
            backgroundColor: "#26A8E0",
          },
        }}
        onClick={onConfirm}
      />
    </Box>
  );
}
