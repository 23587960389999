import { TAsset } from "utils/constants";
import { LoginResponse } from "./containers/Auth/containers/LoginPage/types";
import { Organization } from "./containers/Organizations/types";
import { FormElement } from "./containers/types";
export type LoginForm = {
  email: FormElement,
  password: FormElement,
}
/* --- STATE --- */

export interface AuthState extends LoginResponse {
  user: User | null;
  isLoading: boolean;
  message: string | null;
  organizations: Organization[];
  organization: {
    label: string,
    value: string,
  } | null;
  isLoadingOrganizations: boolean;
  
}

export enum LoginErrorType {
  EMAIL_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401,
}

export enum RoleTypes {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
}
interface Permission {
  subject: string;
  action: string;
}

export type Option = {
    label: string,
    value: string,
}

export interface User {
  _id: string;
  fullName: string;
  mobileNumber: string;
  noOfQuestions: number;
  noOfGames: number;
  email: string;
  role: string;
  resetToken: string;
  type: RoleTypes;
  permissions: Permission[];
  departmentName: string;
  lastLoginDate?: string;
  organization?: Organization;
  photo?:TAsset
}

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = AuthState;
