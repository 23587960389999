import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import CustomTable, { StyledCell } from "app/components/Table";

import { MenuOptions, TableHeaderDefinition, TableHeaders } from "./headers";
import FloatingButton from "app/components/Buttons/FloatingButton";
import MenuWrapper from "app/components/MenuWrapper";
import ConfirmDialog from "app/components/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoading,
  selectList,
  selectPagination,
} from "../../redux/selector";
import { useTranslation } from "react-i18next";
import { actions } from "../../redux/slice";
import { QUESTION_TYPES, modifyMenuOptions } from "utils/constants";
import { selectOrganization } from "app/redux/selectors";
import { formatTableDateTime, formatUUID } from "styles/theme/utils";
import CustomTableRow from "app/components/TableRow";
import { OrderDirections } from "app/containers/types";
import { Typography } from "@mui/material";


const List = () => {
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = React.useState({
    status: false,
    id: "",
  });
  const lists = useSelector(selectList);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const q = searchParams?.[0]?.get("q");
  const type = searchParams?.[0]?.get("type");
  const user = searchParams?.[0]?.get("user");
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction") as OrderDirections;

  const pagination = useSelector(selectPagination);
  const isLoading = useSelector(selectIsLoading);
  const organization = useSelector(selectOrganization);
  useEffect(() => {
    dispatch(
      actions.getQuestions({
        page: 1,
        perPage: pagination.perPage,
        search: q ? q : undefined,
        type: type ? type : undefined,
        organization: organization ? organization.value : undefined,
        user:user ? user : undefined,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      })
    );
  }, [q,  dispatch, type,user, organization, orderBy, orderDirection]);

  const addNewQuestion = () => {
    navigate("/app/questions/new");
  };

  const onClickAction = (id: string, questionId: string) => {
    switch (id) {
      case "1":
        navigate(`/app/questions/${questionId}/duplicate`);
        break;
      case "2":
        navigate(`/app/questions/${questionId}/edit`);
        break;
      case "3":
        setConfirmDelete({
          status: true,
          id: questionId,
        });
        break;
      default:
        break;
    }
  };

  const deleteItem = () => {
    dispatch(actions.deleteQuestion(confirmDelete.id));
    setConfirmDelete({
      status: false,
      id: "",
    });
  };
  const questionTypes = (value: string) => {
    const val = Object.keys(QUESTION_TYPES).filter((val) => {

      return QUESTION_TYPES[val] === value;
    });
    if (val.length > 0) {
      return val[0];
    }

    return "";
  };
  const getQuestionBodyText = (value: string) => {
    const val = value
      .replace(/<\/[^>]+(>|$)/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "");

    return val.length > 20 ? val.substring(0, 20) + "..." : val;
  };
  const hasMoreItems = pagination.page < pagination.total;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getQuestions({
        page: pagination.page + 1,
        perPage: pagination.perPage,
        search: q ? q : undefined,
        type: type ? type : undefined,
        organization: organization ? organization.value : undefined,
        user:user ? user : undefined,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      }));
    }
  };
  return (
    <>
      <Header />
      <CustomTable
        headers={TableHeaders}
        loading={isLoading}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        dataLength={lists.length}
      >
        {lists.map((row, index) => (
          <CustomTableRow
            onDoubleClick={() => navigate(`/app/questions/${row?._id}/view`)}
            key={index}
            hoverOptionComponent={
              <MenuWrapper
                options={modifyMenuOptions(
                  MenuOptions,
                  row?._id ?? "",
                  onClickAction
                )}
              />
            }
          >
            <StyledCell
              minWidth={TableHeaderDefinition.QUESTION_ID.minWidth}
              maxWidth={TableHeaderDefinition.QUESTION_ID.maxWidth}>
                <Typography
                  sx={{
                    color: "#2F80ED",
                    fontSize: "14px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/app/questions/${row?._id}/view`);
                  }}
                >
                  {formatUUID(row._id)}
                </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.QUESTION_TYPE.minWidth}
              maxWidth={TableHeaderDefinition.QUESTION_TYPE.maxWidth}
            >{t(`QUESTIONS.TYPES.${questionTypes(row.type)}`)}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.QUESTION_BODY.minWidth}
              maxWidth={TableHeaderDefinition.QUESTION_BODY.maxWidth}
            >{getQuestionBodyText(row.body)}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.EDITOR_NAME.minWidth}
              maxWidth={TableHeaderDefinition.EDITOR_NAME.maxWidth}
            >{row.user?.fullName}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CREATED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.CREATED_DATE.maxWidth}
            >{formatTableDateTime(row.createdAt)}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.EDITED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.EDITED_DATE.maxWidth}
            >{formatTableDateTime(row.updatedAt)}</StyledCell>
          </CustomTableRow>
        ))}
      </CustomTable>
      <FloatingButton
        label={t("QUESTIONS.QUESTION")}
        onClick={addNewQuestion}
      />

      <ConfirmDialog
        open={confirmDelete.status}
        handleClose={() =>
          setConfirmDelete({
            status: false,
            id: "",
          })
        }
        onConfirm={deleteItem}
        title={t("COMMON.DELETE.TITLE", { name: t("QUESTIONS.QUESTION") })}
        message={t("COMMON.DELETE.MESSAGE", { name: t("QUESTIONS.QUESTION") })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.DELETE")}
      />
    </>
  );
};

export default List;
