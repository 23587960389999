import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, Query } from "app/containers/types";
import { ContainerState, CustomersState } from "../types";
import { Customer } from "app/containers/Customers/types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = CustomersState;

const formSlice = createSlice({
  name: "customersSelectState",
  initialState,
  reducers: {
    getCustomers(state, action: PayloadAction<Query>) {
      state.isLoading = true;
    },
    setCustomers(state, action: PayloadAction<{customers: Customer[], pagination: Pagination}>) {
      state.customers = action.payload.customers;
      state.pagination = action.payload.pagination;
      state.isLoading = false;
    },
    getCustomersError(state) {
        state.isLoading = false;
    }
  },
});

export const { actions, reducer: customersSelectReducer, name: customersSelectSlice } = formSlice;
