import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  CustomizeQuestion,
  Ticket,
  TicketCustomize,
  TicketsState,
} from "../types";
import set from "lodash/set";
import { FormError, Pagination, Query } from "app/containers/types";
import { SingleMultiSelectAnswer } from "app/containers/Questions/types";
import { ASSET_TYPE, QUESTION_TYPES } from "utils/constants";

const initialState = TicketsState;

export const formSlice = createSlice({
  name: "ticketsState",
  initialState,
  reducers: {
    setFormValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.isUnsaved = true;
    },
    setCustomizeFormValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state.customizeForm,
        `${action.payload.key}.value`,
        action.payload.value
      );
      set(state.customizeForm, `${action.payload.key}.error`, null);
    },
    setCustomizeFormQuestion: (
      state,
      action: PayloadAction<{ index: number; item: CustomizeQuestion }>
    ) => {
      if (action.payload.index == -1) {
        state.customizeForm.questions.push(action.payload.item);
      } else {
        state.customizeForm.questions[action.payload.index] =
          action.payload.item;
      }
    },
    addCustomizeQuestionAnswer: (
      state,
      action: PayloadAction<SingleMultiSelectAnswer>
    ) => {
      state.customizeFormQuestion.answers.push(action.payload);
    },
    addCustomizeQuestion: (state) => {
      state.customizeForm.questions.push(state.customizeFormQuestion);
      state.customizeFormQuestion = {
        _id: "",
        type: QUESTION_TYPES.MULTI_SELECT,
        body: "",
        asset: {
          _id: "",
          type: ASSET_TYPE.IMAGE,
          mimeType: "",
          fileName: "",
          filePath: "",
          url: "",
        },
        answers: [],
      };
    },
    editCustomizeQuestion: (state, action: PayloadAction<number>) => {
      state.customizeForm.questions[action.payload] =
        state.customizeFormQuestion;
      state.customizeFormQuestion = {
        _id: "",
        type: QUESTION_TYPES.MULTI_SELECT,
        body: "",
        asset: {
          _id: "",
          type: ASSET_TYPE.IMAGE,
          mimeType: "",
          fileName: "",
          filePath: "",
          url: "",
        },
        answers: [],
      };
    },
    clearCustomizeQuestionForm: (state) => {
      state.customizeFormQuestion = {
        _id: "",
        type: QUESTION_TYPES.MULTI_SELECT,
        body: "",
        asset: {
          _id: "",
          type: ASSET_TYPE.IMAGE,
          mimeType: "",
          fileName: "",
          filePath: "",
          url: "",
        },
        answers: [],
      };
    },
    setForEditCustomizeQuestionForm: (state, action: PayloadAction<number>) => {
      console.log(
        "state.customizeForm.questions",
        JSON.parse(JSON.stringify(state.customizeForm.questions))
      );
      state.customizeFormQuestion =
        state.customizeForm.questions[action.payload];
    },
    deleteCustomizeFormQuestion: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      state.customizeForm.questions.splice(action.payload.index, 1);
    },
    deleteCustomizeFormQuestionAnswer: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      state.customizeFormQuestion.answers.splice(action.payload.index, 1);
    },
    setCustomizeAssetsValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.customizeForm, `${action.payload.key}`, action.payload.value);
    },
    setCustomizeQuestionValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state.customizeFormQuestion,
        `${action.payload.key}`,
        action.payload.value
      );
    },
    setFormErrors(state, action: PayloadAction<FormError[]>) {
      action.payload.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    setCustomizeQuestionAnswer: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      const element = state.customizeFormQuestion.answers[action.payload.index];

      element[action.payload.key] = action.payload.value;

      state.customizeFormQuestion.answers[action.payload.index] = element;
    },
    createTicket: (
      state,
      action: PayloadAction<{ ticket: Ticket; callback?: () => void }>
    ) => {
      state.isLoading = true;
    },
    createTicketSuccess: (state) => {
      state.isLoading = false;
    },
    createTicketError: (state) => {
      state.isLoading = false;
    },
    getTickets: (state, action: PayloadAction<Query>) => {
      state.isLoading = true;
    },
    getTicketsSuccess: (
      state,
      action: PayloadAction<{ tickets: Ticket[]; pagination: Pagination }>
    ) => {
      state.tickets =
        action.payload.pagination?.page === 1
          ? action.payload.tickets
          : [...state.tickets, ...action.payload.tickets];
      state.pagination = action.payload.pagination;
      state.isLoading = false;
      state.isUnsaved = false;
    },
    getTicketsError: (state) => {
      state.isLoading = false;
    },
    getTicket: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getCustomizeTicket: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getCustomizeTicketSuccess: (state, action: PayloadAction<any>) => {
      console.log("ticket success", action.payload);

      state.customizeForm._id.value = action.payload._id;
      state.customizeForm.asset = action.payload.asset;
      state.customizeForm.gameName.value = action.payload.gameName;
      state.customizeForm.ticket = action.payload.ticket;
      state.customizeForm.questions = action.payload.questions;

      state.isLoading = false;
    },
    getTicketSuccess: (state, action: PayloadAction<Ticket>) => {
      state.form._id.value = action.payload._id;
      state.form.customer = action.payload.customer;
      state.form.customerId.value = {
        label: action.payload.customer?.name,
        value: action.payload.customer?._id,
      };
      state.form.gameId.value = {
        label: action.payload.game?.name,
        value: action.payload.game?._id,
      };
      state.form.game = action.payload.game;
      state.form.noOfDevices.value = action.payload.noOfDevices;
      state.form.startDate.value = action.payload.startDate;
      state.form.createdAt.value = action.payload.createdAt;
      state.form.endDate.value = action.payload.endDate;
      state.form.isDatesApplicable.value = action.payload.isDatesApplicable;
      state.form.isCustomizationAvailable.value =
        action.payload.customizations.isCustomizationAvailable;
      state.form.maxQuestions.value =
        action.payload.customizations.maxQuestions;
      state.form.testPassword.value = action.payload.testPassword;
      state.form.password.value = action.payload.password;
      state.isLoading = false;
    },
    getTicketError: (state) => {
      state.isLoading = false;
    },
    updateTicket: (
      state,
      action: PayloadAction<{ ticket: Ticket; callback?: () => void }>
    ) => {
      state.isLoading = true;
    },
    updateCustomizeTicket: (
      state,
      action: PayloadAction<{ id: string; callback?: () => void }>
    ) => {
      state.isLoading = true;
    },
    updateTicketSuccess: (state, action: PayloadAction<Ticket>) => {
      state.form._id.value = action.payload._id;
      state.form.customerId.value = {
        label: action.payload.customer?.name,
        value: action.payload.customer?._id,
      };
      state.form.gameId.value = {
        label: action.payload.game?.name,
        value: action.payload.game?._id,
      };
      state.form.noOfDevices.value = action.payload.noOfDevices;
      state.form.startDate.value = action.payload.startDate;
      state.form.endDate.value = action.payload.endDate;
      state.form.isDatesApplicable.value = action.payload.isDatesApplicable;
      state.form.isCustomizationAvailable.value =
        action.payload.customizations.isCustomizationAvailable;
      state.form.maxQuestions.value =
        action.payload.customizations.maxQuestions;
      state.form.testPassword.value = action.payload.testPassword;
      state.isLoading = false;
    },
    updateTicketError: (state) => {
      state.isLoading = false;
    },
    deleteTicket: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
       state.tickets= state.tickets.filter((x)=>x._id!=action.payload)
    },
    deleteTicketSuccess: (state) => {
      state.isLoading = false;
     
    },
    deleteTicketError: (state) => {
      state.isLoading = false;
    },
    resetForm: (state) => {
      state.form = initialState.form;
      state.isLoading = false;
    },
    shareResult: (state, action: PayloadAction<{emails: string; id: string; callback: () => void}>) => {
      state.isSharing = true;
    },
    shareResultComplete: (state) => {
      state.isSharing = false;
    },
  },
});

export const {
  reducer: ticketsReducer,
  actions,
  name: ticketsSlice,
} = formSlice;
