import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { Option } from 'app/types';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { selectEditors, selectPagination } from '../redux/selector';
import { selectDirection } from 'styles/theme/slice';

interface SelectInputProps {
    readonly value?: Option;
    readonly onChange?: (newValue?: Option | null) => void;
    readonly isLoading?: boolean;
    readonly disabled?: boolean;
    readonly error?: string;
    readonly backgroundColor?: string;
    readonly borderWidth?: number;
}

export default function SelectInput({
    value,
    onChange,
    isLoading,
    error,
    disabled,
    backgroundColor,
    borderWidth,
}:SelectInputProps) {
    const [open, setOpen] = React.useState(false);
    const pagination = useSelector(selectPagination);
    const editors = useSelector(selectEditors);
    const { t } = useTranslation();
  const direction = useSelector(selectDirection);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(actions.getEditors({
            page: pagination.page,
            perPage: pagination.perPage,
            search: pagination.search,
            isActive: pagination.filters?.isActive,
        }));
    },[dispatch])

    const options = editors?.map((c)=>{
        return {
            label: c.fullName,
            value: c._id ?? "",
        }
    }) || [];

    return (
        <Autocomplete
            sx={{
                width: "100%",
            }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            defaultValue={{
                label: t("COMMON.TEXT.ALL"),
                value: "all",
            }}
            value={value}
            getOptionLabel={(option) => option?.label??""}
            onChange={(e, v)=>onChange?.(v)}
            options={[
                {
                    label: t("COMMON.TEXT.ALL"),
                    value: "all",
                },
                ...options,
            ]}
            loadingText={(
                <Typography
                    sx={{
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "14px",
                        textAlign: "left",
                    }}
                >
                    {t("COMMON.TEXT.LOADING")}
                </Typography>
            )}
            noOptionsText={(
                <Typography
                    sx={{
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "14px",
                        textAlign: "left",
                    }}
                >
                    {t("COMMON.TEXT.NO_OPTIONS")}
                </Typography>
            )}
            renderOption={(props, option) => (
                <Box 
                    component="li" 
                    {...props}
                >
                    <Typography
                        sx={{
                            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            textAlign: "left",
                            color: "#5E6781",
                            py: "4px",
                        }}
                    >
                        {option?.label}
                    </Typography>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={t("COMMON.PLACEHOLDERS.SELECT")}
                    disabled={disabled}
                    sx={{
                        width: "100%",
                        backgroundColor: backgroundColor ?? '#FFFFFF',
                        borderRadius: '4px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: error ? '#d32f2f' : '#86BDE3',
                                borderRadius: '4px',
                                color: '#263238',
                                borderWidth: borderWidth??1,
                            },
                            '&:hover fieldset': {
                                borderColor: error ? '#d32f2f' : '#86BDE3',
                                borderRadius: '4px',
                                color: '#263238',
                                borderWidth: borderWidth??1,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: error ? '#d32f2f' : '#86BDE3',
                                borderRadius: '4px',
                                color: '#263238',
                                borderWidth: borderWidth??1,
                            },
                        }, 
                        '& .MuiFormHelperText-root': {
                            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                            fontSize: "12px",
                            fontWeight: 500,
                            lineHeight: "18px",
                            letterSpacing: "0em",
                            margin: 0,
                            color: '#d32f2f',
                        },
                        input: {
                            '&::placeholder': {
                                color: '#727F89',
                                opacity: '100%',
                                fontWeight: '400',
                                fontSize: '14px',
                            },
                            fontWeight: '400',
                            fontSize: '14px',
                            color: '#263238',
                            backgroundColor: backgroundColor ?? '#FFFFFF',
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={16} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    helperText={error}
                />
            )}
        />
    )
}
