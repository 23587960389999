import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import InfoIcon from '@mui/icons-material/Info';
import MessageCard from '../MessageCard';
import MediaUploader from 'app/components/MediaUploader';
import { useTranslation } from 'react-i18next';
import { selectGame } from 'app/containers/Games/redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/containers/Games/redux/slice';
import { MediaTypes } from 'app/containers/types';
import { selectDirection } from 'styles/theme/slice';

export default function EndGame({
    disabled
}:{
    disabled?: boolean
}) {
    const { t } = useTranslation()
  const direction = useSelector(selectDirection);
    const game = useSelector(selectGame)
    const dispatch = useDispatch()

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actions.setFormValues({ key: e.target.name, value: e.target.value }))
    }
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Typography
                sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "14px",
                    fontWeight: 600,
                    letterSpacing: "0em",
                    color: "#207DB9",
                    textTransform: "uppercase",
                    textAlign: "center",
                }}
            >
                {t("GAMES.FORM.END_GAME")}
                <Tooltip title={t("GAMES.FORM.LABELS.END_GAME_TOOLTIP")}>
                    <InfoIcon 
                        sx={{
                            fontSize: "18px",
                            marginLeft: "6px",
                            color: "#3C3C43"
                        }}
                    />
                </Tooltip>
            </Typography>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "12px",
                }}
            >
                <MessageCard 
                    type="secondary"
                    value={game.endGameText.value}
                    onChange={onChange}
                    error={game.endGameText.error ? t(game.endGameText.error) : ""}
                    name="endGameText"
                />
                <MediaUploader 
                    onCheck={(e) => dispatch(actions.setFormValues({ key: "endGameAssetType", value: MediaTypes.IMAGE }))}
                    showBtn={false}
                    primaryColor='#207DB9'
                    secondaryColor='#EDF4F8'
                    caption={t("GAMES.FORM.LABELS.GAME_IMAGE_CAPTION")}
                    title={t("GAMES.FORM.LABELS.END_GAME_IMAGE")}
                    src={game.endGameAssetType.value === MediaTypes.IMAGE ? game.endGameAssetUrl.value : ""}
                    error={game.endGameAssetUrl.error ? t(game.endGameAssetUrl.error) : ""}
                    checked={game.endGameAssetType.value === MediaTypes.IMAGE}
                    disablePicker={game.endGameAssetType.value !== MediaTypes.IMAGE}
                    onSuccess={(img)=>{
                        dispatch(actions.setFormValues({ key: "endGameAssetUrl", value: img?.url }))
                        dispatch(actions.setFormValues({ key: "endGameAssetId", value: img?._id }))
                    }}
                    disabled={disabled}
                    height="213px"
                    fallbackHeight="185px"
                    fallbackWidth="354px"
                />
                <MediaUploader 
                    onCheck={(e) => dispatch(actions.setFormValues({ key: "endGameAssetType", value: MediaTypes.VIDEO }))}
                    showBtn={false}
                    type={MediaTypes.VIDEO}
                    primaryColor='#207DB9'
                    secondaryColor='#EDF4F8'
                    caption={t("GAMES.FORM.LABELS.GAME_VIDEO_CAPTION")}
                    title={t("GAMES.FORM.LABELS.END_GAME_VIDEO")}
                    disabled={disabled}
                    checked={game.endGameAssetType.value === MediaTypes.VIDEO}
                    disablePicker={game.endGameAssetType.value !== MediaTypes.VIDEO}
                    src={game.endGameAssetType.value === MediaTypes.VIDEO ? game.endGameAssetUrl.value : ""}
                    error={game.endGameAssetUrl.error ? t(game.endGameAssetUrl.error) : ""}
                    onSuccess={(img)=>{
                        dispatch(actions.setFormValues({ key: "endGameAssetUrl", value: img?.url }))
                        dispatch(actions.setFormValues({ key: "endGameAssetId", value: img?._id }))
                    }}
                    height="213px"
                />
            </Box>
        </Box>
    )
}
