/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { AuthPage } from "./containers/Auth";
// import { userRepoSaga } from "./containers/Auth/redux/saga";
// import { reducer, sliceKey } from "./containers/Auth/redux/slice";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import CreatePassword from "./containers/Auth/containers/CreatePassword";
import ForgotPassword from "./containers/Auth/containers/ForgotPassword";
import LoginForm from "./containers/Auth/containers/LoginPage/components/Form";
import ResetPassword from "./containers/Auth/containers/ResetPassword";
import PrivateRoute from "../utils/PrivateRoute";
import { selectDirection, themeActions } from "../styles/theme/slice";
import { UnsupportedScreen } from "./containers/UnsupportedScreen";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import ScrollToTop from "app/components/ScrollToTop";
import Customers from "./containers/Customers";
import Organizations from "./containers/Organizations";
import Tickets from "./containers/Tickets";
import Games from "./containers/Games";
import Stations from "./containers/Stations";
import Editors from "./containers/Editors";
import Results from "./containers/Results";
import Pages from "./containers/pages";
import Questions from "./containers/Questions";
import { authReducer, authSlice } from "./redux/slice";
import { authSaga } from "./redux/saga";
import NotFound from "./containers/NotFound";
import Layout from "./components/Layout";
import Customization from "./containers/Tickets/components/Customization";
import ResultsDetails from "./containers/Results/components/Details";

const MIN_WIDTH = 1024;

export function App() {
  useInjectReducer({ key: authSlice, reducer: authReducer });
  useInjectSaga({ key: authSlice, saga: authSaga });

  const { width } = useWindowDimensions();

  const mobileScreenPath = '/tickets/customization'
  const pathname = window.location.pathname;

  const isMobileScreen = pathname === mobileScreenPath;
  const unsupportedScreen = (width && width <= MIN_WIDTH) && !isMobileScreen;
  const direction = useSelector(selectDirection);
  const dispatch = useDispatch();

  const selectedLanguage = useMemo(() => {
    return {
      language: localStorage.getItem("i18nextLng"),
      direction,
    };
  }, [direction]);
  useEffect(() => {
    if (selectedLanguage) {
      if (
        selectedLanguage.language === "hw" &&
        selectedLanguage.direction !== "rtl"
      ) {
        toggleDirection('rtl');
      }
      if (
        selectedLanguage.language === "en" &&
        selectedLanguage.direction !== "ltr"
      ) {
        toggleDirection('ltr');
      }
    }
  }, [selectedLanguage]);

  const toggleDirection = (direction: "ltr" | "rtl") => {
    dispatch(themeActions.toggleDirection(direction));
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Helmet titleTemplate="%s - Twigo" defaultTitle="Twigo">
        <meta name="description" content="A Twigo application" />
      </Helmet>
      <div dir={direction}>
        <Routes>
          <Route path="/pages/*" element={<Pages />} />
        </Routes>
       {unsupportedScreen ? (
          <UnsupportedScreen minWidth={MIN_WIDTH} />
        ) : ( 
          <Routes>
          <Route path="/auth/*" element={<AuthPage />}>
            <Route index path="login" element={<LoginForm className="" />} />
            <Route path="recovery/*">
              <Route index path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="create-password" element={<CreatePassword />} />
            </Route>
          </Route>

          <Route
            path="dashboard"
            element={<PrivateRoute>{/* Private route  */}</PrivateRoute>}
          >
            {/* Login */}
          </Route>
          <Route path="/" element={<Navigate to="/auth/login" />} />
          <Route path="tickets/customization/:id" element={<Customization />} />
          <Route path="results/:id" element={<ResultsDetails />} />
          <Route
            path="/app"
            element={<Layout />}
          >
            <Route path="customers/*" element={<Customers />} />
            <Route path="questions/*" element={<Questions />} />
            <Route path="organizations/*" element={<Organizations />} />
            <Route path="tickets/*" element={<Tickets />} />
            <Route path="games/*" element={<Games />} />
            <Route path="stations/*" element={<Stations />} />
            <Route path="editors/*" element={<Editors />} />
            <Route path="results/*" element={<Results />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}
