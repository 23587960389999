import { Add } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import OutlineButton from "app/components/Buttons/OutlineButton";
import React, { useEffect } from "react";
import Header from "./components/Header";
import TableHeader from "./components/TableHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingStations,
  selectSelectedStations,
  selectStations,
  selectStationsPagination,
} from "app/containers/Games/redux/selector";
import { actions } from "app/containers/Games/redux/slice";
import StationTile from "./components/StationTIle";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";
import { selectOrganization } from "app/redux/selectors";
import TableLoader from "app/components/TableLoader";
import { selectDirection } from "styles/theme/slice";

export default function AllStations({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const stations = useSelector(selectStations);
  const selectedStations = useSelector(selectSelectedStations);
  const pagination = useSelector(selectStationsPagination);
  const isLoading = useSelector(selectIsLoadingStations);
  const organization = useSelector(selectOrganization);
  const dispatch = useDispatch();
  
  const openAddStationPageInNewTab = () => {
    window.open("/app/stations/new?mode=add", "_blank");
  };
  const searchParams  = useSearchParams();
  const q = searchParams?.[0]?.get("q");
  const city = searchParams?.[0]?.get("city");

  useEffect(() => {
    dispatch(
      actions.getStations({
        page: 1,
        perPage: pagination.perPage,
        search: q || undefined,
        city: city || undefined,
        organization: organization?.value,
      })
    );
  }, [dispatch, q, city, organization?.value]);
  const hasMoreItems = pagination.page < pagination.total;

  const getNextStationPage = () => {
    if (!isLoading) {
      dispatch(actions.getStations({
        page: pagination.page + 1,
        perPage: pagination.perPage,
        search: q || undefined,
        city: city || undefined,
        organization: organization?.value,
      }));
    }
  };
  const memoizedStations = React.useMemo(() => {
    return stations.filter(
      (q) => !selectedStations.some((sq) => sq.station._id === q._id)
    );
  }, [selectedStations, stations]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "100%",
            color: "#5E6781",
          }}
        >
          {t("GAMES.FORM.ALL_STATIONS")}
        </Typography>
        <OutlineButton
          disabled={disabled}
          onClick={openAddStationPageInNewTab}
          label={t("STATIONS.STATION")}
          startIcon={<Add />}
          sx={{
            border: "1px solid #E24399",
            color: "#E24399",
            textTransform: "uppercase",
            "&:hover": {
              color: "#E24399",
              border: "1px solid #E24399",
            },
          }}
        />
      </Box>
      <Grid2 container spacing={2}>
        <Header disabled={disabled} />
        <Grid2 xs={12}>
          <TableHeader />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              maxHeight: 300,
              padding: '4px',
            }}
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={memoizedStations?.length ?? 0}
              loader={<TableLoader />}
              hasMore={hasMoreItems}
              next={getNextStationPage}
              style={{ width: "100% !important" }}
              height={300}
              scrollableTarget="scrollableDiv"
            >
              <Box>
                {memoizedStations?.length > 0 ? (
                  memoizedStations.map((s, i) => (
                    <StationTile
                      disabled={disabled}
                      key={s?._id}
                      station={s}
                      handleBackClick={() =>
                        dispatch(
                          actions.addToSelectedStations({
                            id: "",
                            station: {
                              _id: s._id || "",
                              name: s.name,
                            },
                            number: 1,
                          })
                        )
                      }
                    />
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "100%",
                      color: "#5E6781",
                      fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                      textAlign: "center",
                      my: "40px",
                    }}
                  >
                    {t("STATIONS.FORM.LABELS.NO_STATIONS")}
                  </Typography>
                )}
              </Box>
            </InfiniteScroll>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
}
