import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./components/Header";
import Table from "./components/Table";
import GroupPhotos from "./components/GroupPhotos";
import Footer from "./components/Footer";
import { useParams } from "react-router-dom";
import { actions } from "../../redux/slice";
import { useDispatch } from "react-redux";
import { resultsReducer, resultsSlice } from '../../redux/slice';
import { resultsSaga } from '../../redux/saga';
import { useInjectReducer, useInjectSaga } from "redux-injectors";

export default function ResultsDetails() {
  useInjectReducer({ key: resultsSlice, reducer: resultsReducer });
  useInjectSaga({ key: resultsSlice, saga: resultsSaga });
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;
      dispatch(actions.getTicketResult(id));
  }, [id]);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        background: "#F3F6F8",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Header />
        <Table />
        <GroupPhotos />
        <Footer />
      </Box>
    </Box>
  );
}
