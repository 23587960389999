import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress, SxProps, Theme } from '@mui/material';
import React from "react";

const CustomButton = ({
  label,
  onClick,
  disabled,
  isLoading,
  color,
  variant,
  sx,
  startIcon,
  endIcon,
}: {
  label: string;
  onClick?: (e) => void;
  disabled?: boolean;
  isLoading?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  sx?: SxProps<Theme>;
}) => {
  return (
    <LoadingButton
      startIcon={startIcon}
      endIcon={endIcon}
      loading={isLoading}
      color={color}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      loadingIndicator={<CircularProgress color="inherit" size={16} />}
      loadingPosition="start"
      sx={{
        gap: "10px",
        whiteSpace: "nowrap",
        ...sx
      }}
    >
      {label}
    </LoadingButton>
  );
};

export default CustomButton;
