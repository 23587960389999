import React from "react";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import SearchInput from "app/components/SearchInput";
import QuestionTypesDropdown from "app/components/QuestionTypesDropdown";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EditorsSelect from "app/components/EditorSelect";
import { LEFT_SPACING } from "utils/constants";

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setQuestionSearchQuery = (search: string) => {
    const params = new URLSearchParams(window.location.search);
    if (search.toLowerCase() == "all") {
      params.set("type", "");
    } else {
      params.set("type", search);
    }
    navigate(`?${params.toString()}`);
  };
  const setEditorSearchQuery = (search: string) => {
    const params = new URLSearchParams(window.location.search);
    if (search.toLowerCase() == "all") {
      params.set("user", "");
    } else {
      params.set("user", search);
    }
    navigate(`?${params.toString()}`);
  };

  const searchParams = new URLSearchParams(window.location.search);
  const type = searchParams?.get("type");
  const user = searchParams?.get("user");

  return (
    <Grid2
      container
      spacing={2}
      md={12}
      sx={{ marginTop: "-32px" }}
      paddingLeft={LEFT_SPACING}
    >
      <Grid2 xs={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <SearchInput provisionLabel />
        </Box>
      </Grid2>
      <Grid2 xs={2}>
        <QuestionTypesDropdown onChange={(v) => setQuestionSearchQuery(v)} />
      </Grid2>
      <Grid2 xs={6} md={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <EditorsSelect
            label={t("COMMON.LABELS.EDITOR_NAME")}
            isFieldReq={false}
            backgroundColor="#F3F6F8"
            borderWidth={0}
            onChange={(e) => setEditorSearchQuery(e?.value || "")}
          />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Header;
