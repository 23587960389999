import React from "react";
import { TableHeaderColumnType } from "app/components/Table/types";
import edit from "assets/ic_edit.svg";
import trash from "assets/ic_trash.svg";
import { ListItem } from "app/containers/types";

export const TableHeaderDefinition = {
  EDITOR_ID: {
    id: "_id",
    label: "EDITOR_ID",
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable: false,
  },
  CREATED_DATE: {
    id: "createdAt",
    label: "CREATED_DATE",
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable: true,
  },
  LAST_LOGGED_IN: {
    id: "lastLogin",
    label: "LAST_LOGGED_IN",
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable: true,
  },
  EDITOR_NAME: {
    id: "fullName",
    label: "EDITOR_NAME",
    format: (value) => value,
    maxWidth: 300,
    minWidth: 300,
    sortable: true,
  },
  EMAIL: {
    id: "email",
    label: "EMAIL",
    format: (value) => value,
    maxWidth: 300,
    minWidth: 300,
    sortable: true,
  },
  PHONE_NUMBER: {
    id: "mobileNumber",
    label: "PHONE_NUMBER",
    format: (value) => value,
    maxWidth: 300,
    minWidth: 300,
    sortable: true,
  },
};

export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);

  export const MenuOptions: ListItem[] = [
    {
      id: "1",
      name: "EDIT",
      onClick: () => console.log("Download Folder"),
      icon: <img src={edit} alt="folder" />,
    },
    {
      id: "2",
      name: "DELETE",
      onClick: () => console.log("Download Folder"),
      icon: <img src={trash} alt="controller" />,
    },
  ];
