import { call, delay, put, takeLatest } from "redux-saga/effects";
import { API_URL, translate } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import { ResultListResponse, ResultPageResponseResponse } from "../types";
import { removeBlankProperties } from "utils/helpers";
import { Query } from "app/containers/types";
import toastService from "utils/toast";

export function* getResultRequest({ payload }: { payload: Query }) {
  yield delay(500);
  try {
    const query = new URLSearchParams(
      removeBlankProperties(payload)
    ).toString();
    const {data,status,err}: Res<ResultListResponse>  = yield call(
      request,
      `${API_URL}/v1/admin/result/list?${query}`
    );
    if (err || !data) {
      throw err;
    }
    yield put(
      actions.getResultsSuccess({
        data: data?.data,
        pagination: data?._metadata?.pagination,
      })
    );
  } catch (error: any) {
    yield put(actions.getResultError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* getTicketResultRequest({ payload }: { payload: string }) {
  yield delay(500);
  try {
   
    const {data,status,err}: Res<ResultPageResponseResponse> = yield call(
      request,
      `${API_URL}/v1/service/result/ticket/${payload}`
    );
    yield put(
      actions.getTicketResultsSuccess({
        data: data?.data,
      })
    );
  } catch (error: any) {
    yield put(actions.getResultError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}
export function* resultsSaga() {
  yield takeLatest(actions.getResults, getResultRequest);
  yield takeLatest(actions.getTicketResult, getTicketResultRequest);
}
