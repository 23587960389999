import React, { useEffect } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import List from './components/List';
import Details from './components/Details';
import { MODES } from 'utils/constants';
import { useSelector } from 'react-redux';
import { selectIsLoading, selectUser } from 'app/redux/selectors';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { organizationReducer, organizationSlice } from './redux/slice';
import { organizationSaga } from './redux/saga';
import { RoleTypes } from 'app/types';

const Organizations = () => {
    useInjectReducer({ key: organizationSlice, reducer: organizationReducer });
    useInjectSaga({ key: organizationSlice, saga: organizationSaga });
    const role = useSelector(selectUser)?.type;
    const isLoading = useSelector(selectIsLoading);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!isLoading && role !== RoleTypes.ADMIN) {
            navigate("/app/tickets", { replace: true });
        }
    }, [role, isLoading]);

    if (role !== RoleTypes.ADMIN && !isLoading) return null;
    return (
        <Routes>
            <Route path="/" element={<List />} />
            <Route path=":id/view" element={<Details mode={MODES.VIEW} />} />
            <Route path="new" element={<Details mode={MODES.NEW}  />} />
            <Route path=":id/edit" element={<Details mode={MODES.EDIT} />} /> 
            <Route path=":id/duplicate" element={<Details mode={MODES.DUPLICATE} />} /> 
        </Routes>
    );
};

export default Organizations;
