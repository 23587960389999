import { MenuItem } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DropDown } from "app/components/DropDown";
import { useStyles } from "./styles";
import React from "react";
import QuestionTypesDropdown from "app/components/QuestionTypesDropdown";
import { useTranslation } from "react-i18next";
import EditorsSelect from "app/components/EditorSelect";
import { useNavigate } from "react-router-dom";

export default function Header({ disabled }: { disabled?: boolean }) {
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setTypeSearchQuery = (type: string) => {
    console.log(" e.target.value", type);
    const params = new URLSearchParams(window.location.search);
    params.set("type", type);
    navigate(`?${params.toString()}`);
  };

  const setUserSearchQuery = (user: string) => {
    console.log(" e.target.value", user);
    const params = new URLSearchParams(window.location.search);
    params.set("user", user);
    navigate(`?${params.toString()}`);
  };
  return (
    <>
      <Grid2 xs={4}>
        <QuestionTypesDropdown
          disabled={disabled}
          onChange={(value)=>setTypeSearchQuery(value)}
        />
      </Grid2>
      <Grid2 xs={4}>
        <EditorsSelect
          label={t("STATIONS.FORM.LABELS.CREATOR_NAME")}
          isFieldReq={false}
          backgroundColor="#F3F6F8"
          borderWidth={0}
          disabled={disabled}
          onChange={(value) => setUserSearchQuery(value?.value || '')}
        />
      </Grid2>
    </>
  );
}
