import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import PersonIcon from "@mui/icons-material/Person";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LanguageIcon from "@mui/icons-material/Language";
import ConfirmDialog from "../ConfirmDialog";
import Profile from "../Profile";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/redux/slice";
import { LANGUAGES } from "utils/constants";
import { useTranslation } from "react-i18next";
import { selectDirection, themeActions } from "styles/theme/slice";
import i18next from "i18next";
import { selectUser } from "app/redux/selectors";

export default function AppSettings() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState(null);
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const [confirmLanguage, setConfirmLanguage] = React.useState({
    open: false,
    language: "",
  });
  const [confirmProfile, setConfirmProfile] = React.useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLanguageAnchorEl(null);
  };

  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const onChangeLanguage = () => {
    i18next.changeLanguage(confirmLanguage?.language);
    localStorage.setItem("i18nextLng", confirmLanguage?.language);
    dispatch(
      themeActions.toggleDirection(
        confirmLanguage?.language === "hw" ? "rtl" : "ltr"
      )
    );
    setConfirmLanguage({
      open: false,
      language: "",
    });
  };

  const getCapitalizedLanguageName = (language) => {
    const lang = LANGUAGES.find((lang) => lang.value === language);
    return lang?.label;
  };

  return (
    <>
      <Button onClick={handleMenu}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {user?.photo?.url ? (
            <img
              style={{
                borderRadius: "50px",
                width: "40px",
                height: "40px",
              }}
              src={user?.photo?.url}
            ></img>
          ) : (
            <PersonIcon
              sx={{
                width: "32px",
                height: "32px",
                backgroundColor: "#FFFFFF",
                borderRadius: "50%",
              }}
            />
          )}

          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: "16px",
              lineHeight: "16px",
              textTransform: "capitalize",
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            }}
          >
            {user?.fullName}
          </Typography>

          {Boolean(anchorEl) ? (
            <KeyboardArrowUpIcon sx={{ color: "#FFFFFF" }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: "#FFFFFF" }} />
          )}
        </Box>
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            marginTop: "45px",
            width: "200px",
          },
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          dense: true,
          sx: {
            paddingY: "15px",
            "& .MuiListItemText-root": {
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "14px !important",
              fontWeight: 500,
              lineHeight: "14px",
              letterSpacing: "0em",
              color: "#3C3C43",
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setConfirmProfile(true);
          }}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t(`SETTINGS.PROFILE`)}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setConfirmLogout(true);
          }}
        >
          <ListItemIcon>
            <PowerSettingsNewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t(`SETTINGS.LOGOUT`)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLanguageClick}>
          <ListItemIcon>
            <LanguageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t(`SETTINGS.LANGUAGE`)}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            <KeyboardArrowRightIcon fontSize="small" />
          </Typography>
        </MenuItem>
        <Menu
          id="language-menu"
          anchorEl={languageAnchorEl}
          keepMounted
          open={Boolean(languageAnchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: direction === "ltr" ? "left" : "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              marginLeft: direction === "ltr" ? "-140px" : "140px",
              width: "140px",
            },
          }}
          MenuListProps={{
            dense: true,
          }}
        >
          {LANGUAGES.map((language) => (
            <MenuItem
              onClick={() => {
                handleClose();
                setConfirmLanguage({
                  open: true,
                  language: language.value,
                });
              }}
              key={language?.value}
            >
              {t(`LANGUAGES.${language.label}`)}
            </MenuItem>
          ))}
        </Menu>
      </Menu>
      <ConfirmDialog
        title={t("LOGOUT.TITLE")}
        message={t("LOGOUT.CAPTION")}
        open={confirmLogout}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.LOGOUT")}
        handleClose={() => setConfirmLogout(false)}
        onConfirm={() => {
          setConfirmLogout(false);
          dispatch(actions.logout());
        }}
      />
      <ConfirmDialog
        title={t("LANGUAGES.POP_UP.TITLE")}
        message={t("LANGUAGES.POP_UP.CAPTION", {
          name: getCapitalizedLanguageName(confirmLanguage?.language),
        })}
        open={confirmLanguage.open}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.CHANGE")}
        handleClose={() =>
          setConfirmLanguage({
            open: false,
            language: "",
          })
        }
        onConfirm={onChangeLanguage}
      />
      <Profile
        open={confirmProfile}
        handleClose={() => setConfirmProfile(false)}
      />
    </>
  );
}
