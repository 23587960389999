import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  FreeTextAnswer,
  MixedLetterAnswer,
  Question,
  QuestionsState,
} from "../types";
import set from "lodash/set";
import { FormError, Pagination, Query } from "app/containers/types";
import toastService from "utils/toast";
import { translate } from "utils/constants";

const initialState = QuestionsState;

export const formSlice = createSlice({
  name: "questionsState",
  initialState,
  reducers: {
    getQuestions: (state, action: PayloadAction<Query>) => {
      state.loading = true;
    },
    getQuestionsSuccess: (
      state,
      action: PayloadAction<{ data: Question[]; pagination: Pagination }>
    ) => {
      state.list =
        action.payload.pagination.page === 1
          ? action.payload.data
          : [...state.list, ...action.payload.data];
      state.pagination = action.payload.pagination;
      state.loading = false;
      state.isUnsaved = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAssetsValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}`, action.payload.value);

      state.error = "";
    },
    setAnswer: (state, action: PayloadAction<any[]>) => {
      state.answer = action.payload;

      state.error = "";
    },
    setSingleUpdateAnswer: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      for (let index = 0; index < state.answer.length; index++) {
        const element = state.answer[index];
        if (index == action.payload.index) {
          element[action.payload.key] = action.payload.value;
        } else {
          element[action.payload.key] = false;
        }
        state.answer[index] = element;
      }
      const item = state.answer[action.payload.index];
      item[action.payload.key] = action.payload.value;
      state.answer[action.payload.index] = item;
      state.error = "";
    },

    setUpdateAnswer: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      const item = state.answer[action.payload.index];
      item[action.payload.key] = action.payload.value;
      state.answer[action.payload.index] = item;
      state.error = "";
    },

    setUpdateMixedLetterAnswer: (
      state,
      action: PayloadAction<{
        index: number;
        innerIndex: number;
        key: string;
        value: any;
      }>
    ) => {
      const item = state.answer[action.payload.index] as FreeTextAnswer;
      const innerItem = item.letters[action.payload.innerIndex];
      innerItem[action.payload.key] = action.payload.value;
      item.letters[action.payload.innerIndex] = innerItem;
      state.answer[action.payload.index] = item;
      state.error = "";
    },
    setFormValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.error = "";
      if (action.payload.key !== "organizationId") {
        state.isUnsaved = true;
      }
    },
    setFormClearId: (state) => {
      state.form._id = "";
      state.error = "";
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setFormErrors(state, action: PayloadAction<FormError[]>) {
      action.payload.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
        toastService.error(translate(error.error));
      });
    },
    resetForm: (state) => {
      state.loading = false;
      state.form = initialState.form;
      state.answer = initialState.answer;
    },
    createQuestion: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.loading = true;
    },
    createQuestionSuccess: (state) => {
      state.loading = false;
    },
    createQuestionError: (state) => {
      state.loading = false;
    },
    updateQuestion: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.loading = true;
    },
    updateQuestionSuccess: (state) => {
      state.loading = false;
    },
    updateQuestionError: (state) => {
      state.loading = false;
    },
    deleteQuestion: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.list= state.list.filter((x)=>x._id!=action.payload)
    },
    deleteQuestionSuccess: (state) => {
      state.loading = false;
      
    },
    deleteQuestionError: (state) => {
      state.loading = false;
    },
    getQuestionError: (state) => {
      state.loading = false;
    },
    getQuestionSuccess: (state, action: PayloadAction<Question>) => {
      state.form._id = action.payload._id;
      state.form.body.value = action.payload.body;
      state.form.rightAnswerComment.value = action.payload.rightAnswerComment;
      state.form.score.value = action.payload.score;
      state.form.link.value = action.payload.link;
      state.form.linkLabel.value = action.payload.linkLabel;
      state.form.showLink.value = action.payload.showLink;
      state.form.organizationId.value = action.payload.organizationId;
      state.form.type.value = action.payload.type;
      state.form.asset = action.payload.asset;
      state.answer = action.payload.answers;
      state.isUnsaved = false;
      state.loading = false;
    },

    getQuestion: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
  },
});

export const {
  reducer: questionsReducer,
  actions,
  name: questionsSlice,
} = formSlice;
