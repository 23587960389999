import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    borderRadius: "4px",
    border: "1px solid #F2F2F2",
    overflow: "hidden",
    width: "100%",
  },
  container: {
    minHeight: "150px",
    borderRadius: "7px 7px 0px 0px",
    paddingRight: 5,
    border: "none",
    overflow: "hidden",
  },
  tableSort: {
    // backgroundColor: '#919',
    "& svg": {
      // backgroundColor: '#911',
      color: "#1D1F20 !important",
    },
  },
  headerRow: {
    "& th:first-child": {
      borderTopLeftRadius: 6,
    },
    "& th:last-child": {
      borderTopLeftRadius: 6,
    },
  },
  dataTableDiv: {
    overflowY: "hidden",
    background: "#FFF",
    width: "100% !important",
    display: "flex",
    marginTop: 16,
    paddingBottom: 35,
  },
  tableBody: {
    backgroundColor: "#fff",
    border: "none",
    "& .MuiTableCell-root": {
      border: "none",
      backgroundColor: "transparent",
      "& .css-xemq8y-MuiTableRow-root:nth-of-type(odd)": {
        backgroundColor: "transparent",
      },
    },
  },
  headerCheckbox: {
    padding: 0,
    width: 18,
    height: 18,
    marginRight: 6,
  },
  customCheckbox: {
    padding: 0,
    width: 18,
    height: 18,
  },
});
