import { Box } from '@mui/material'
import { selectCustomizeTicket } from 'app/containers/Tickets/redux/selector';
import React from 'react'
import { useSelector } from 'react-redux';
import Logo from "assets/logo.png";

export default function Image() {
    const ticket = useSelector(selectCustomizeTicket);
    return (
        <>
            {
                ticket.ticket?.game?.startGameAsset?.url ? 
                <Box
                    sx={{
                        height: "250px",
                        width: "100%",
                        marginBottom: "16px"
                    }}
                >
                    <img 
                        alt="customization"
                        src={ticket.ticket?.game?.startGameAsset?.url}
                        width={"100%"}
                        height={"100%"}
                    />
                </Box> : 
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "16px",
                        marginTop: "100px"
                    }}
                >
                    <img 
                        src={Logo} 
                        alt="logo"
                        width="145px" 
                        height="50px" 
                    />
                </Box>
            }
        </>
    )
}
