import { FormElement, Metadata, OrderDirections, Pagination } from "app/containers/types";

export type CustomerForm = {
  _id: FormElement;
  organizationId: FormElement;
  name: FormElement;
  companyName: FormElement;
  taxNumber: FormElement;
  email: FormElement;
  address: FormElement;
  mobileNumber: FormElement;
  type: FormElement;
  numberOfPurchasedGames: FormElement;
  noOfPlayedGames: FormElement;
  createdAt: FormElement;
  city: FormElement;
};

export type CustomersState = {
  customers: Customer[];
  pagination: Pagination;
  isLoading: boolean;
  form: CustomerForm;
  isUnsaved:boolean
};

export const CustomersState: CustomersState = {
  customers: [],
  isLoading: false,
  form: {
    _id: {
      value: "",
      error: "",
    },
    organizationId: {
      value: "",
      error: "",
    },
    name: {
      value: "",
      error: "",
    },
    companyName: {
      value: "",
      error: "",
    },
    taxNumber: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    mobileNumber: {
      value: "",
      error: "",
    },
    type: {
      value: "",
      error: ""
    },
    numberOfPurchasedGames: {
      value: "",
      error: ""
    },
    noOfPlayedGames: {
      value: "",
      error: ""
    },
    createdAt: {
      value: "",
      error: ""
    },
    city: {
      value: "",
      error: ""
    }
  },
  pagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 20,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  isUnsaved:false
};

export interface Customer {
  _id?: string;
  createdAt?: string;
  lastPurchaseDate?: string;
  updatedAt?: string;
  organizationId: string;
  name: string;
  companyName: string;
  taxNumber: string;
  email: string;
  address: { street: string; city: string;};
  mobileNumber: string;
  type?: string;
  numberOfPurchasedGames?: number;
  noOfPlayedGames?: number;
  city?: any;
}

export interface CustomerResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: Customer[];
}

export type ContainerState = CustomersState;
