/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { API_URL, translate } from "utils/constants";
import { Res, request } from "utils/request";
import { actions, logoutSuccess } from "./slice";
import { User } from "app/types";
import { LoginResponse } from "app/containers/Auth/containers/LoginPage/types";
import toastService from "utils/toast";
import { removeBlankProperties } from "utils/helpers";
import { OrganizationListResponse } from "app/containers/Organizations/types";
import { Query } from "app/containers/types";
import { actions as authAction } from 'app/redux/slice';

export function* logoutRequest() {
  try {
    // const response = yield request(`${API_URL}/auth/logout`, options);
    // if (response) {
    //   yield localStorage.setItem("sessionToken", "");
    // }
    yield localStorage.clear();
  } catch (err: any) {
  }


  yield put(logoutSuccess());
}

export function* getUserRequest() {
  try {
    const requestURL = `${API_URL}/v1/auth/user/me`;
    const options = {
      method: "GET",
    };
    const {data, err, status}: Res<{ data: User }> = yield call(request, requestURL, options);
    if(status === 401){
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken??""));
      return;
    }
    if(err || !data){
      throw err;
    }
    yield put(actions.setUser(data?.data));
  } catch (err: any) {
    toastService.error(translate("COMMON.ERRORS.MESSAGE"));
  }
}

export function* refreshTokenRequest(action) {
  try {
    localStorage.setItem("accessToken", action.payload);
    const requestURL = `${API_URL}/v1/auth/user/refresh`;
    const options = {
      method: "POST",
    };
    const {data, err}: Res<{ data: LoginResponse }> = yield call(request, requestURL, options);
    if(err || !data){
      throw err;
    }
    yield localStorage.setItem("accessToken", data?.data?.accessToken);
    yield localStorage.setItem("refreshToken", data?.data?.refreshToken);
    yield put(actions.setCredentials(data?.data));
    yield put(actions.getUser());
    window.location.reload();
  } catch (err: any) {
    toastService.error(translate("AUTH.ERRORS.SESSION_EXPIRED"));
    yield put(actions.logout());
  }
}

export function* getOrganizationsRequest({payload}: { payload: Query }) { 
    try {
        const query = new URLSearchParams(removeBlankProperties(payload)).toString();
        const {data, err, status}: Res<OrganizationListResponse> = yield call(request, `${API_URL}/v1/admin/organization/list?${query}`);
        if(status === 401){
          const refreshToken = localStorage.getItem("refreshToken");
          yield put(authAction.refreshToken(refreshToken??""));
          return;
        }
        if(err || !data){
            throw err;
        }
        yield put(actions.setOrganizations(data?.data));
    } catch (error:any) {
        yield put(actions.getOrganizationError());
        let errorMessage = error?.message;
        try {
          errorMessage = JSON.parse(errorMessage);
        } catch (e) {
        }
        toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    }
}

export function* setOrganizationRequest({payload}: { payload: { label: string; value: string } }) {
    try {
        yield localStorage.setItem("organization", JSON.stringify(payload));
    } catch (error:any) {
        toastService.error(translate("COMMON.ERRORS.MESSAGE"));
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* authSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.logout, logoutRequest);
  yield takeLatest(actions.getUser, getUserRequest);
  yield takeLatest(actions.refreshToken, refreshTokenRequest);
  yield takeLatest(actions.getOrganizations, getOrganizationsRequest);
  yield takeLatest(actions.setOrganization, setOrganizationRequest);
}
