import React from "react";
import { DropDown } from "../DropDown";
import { MenuItem, Typography } from "@mui/material";
import { QUESTION_TYPES } from "utils/constants";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { selectDirection } from "styles/theme/slice";
import { useSelector } from "react-redux";

export default function QuestionTypesDropdown({
  onChange,
  value,
  disabled,
  isMandatory,
  renderValue,
}: {
  readonly onChange?: (v: QUESTION_TYPES) => void;
  readonly value?: QUESTION_TYPES;
  readonly disabled?: boolean;
  readonly isMandatory?: boolean;
  readonly renderValue?: (value: string) => JSX.Element;
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const questionTypes = Object.keys(QUESTION_TYPES).map((key) => ({
    id: key,
    value: QUESTION_TYPES[key],
    name: t(`QUESTIONS.TYPES.${key}`),
  }));

  return (
    <DropDown
      isDisable={disabled}
      menuItems={[{id: 0, value: 'All', name: t('COMMON.TEXT.ALL')},...questionTypes]?.map((item) => (
        <MenuItem value={item.value} key={item.id}>
          <Typography
            sx={{
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "14px",
              letterSpacing: "0em",
              color: "#5E6781",
              paddingY: "8px",
            }}
          >
            {item.name}
          </Typography>
        </MenuItem>
      ))}
      inputProps={{
        defaultValue: { value: "All", name: "All", id: 0 },
      }}
      renderValue={renderValue}
      onChange={(e) => onChange?.(e.target.value as QUESTION_TYPES)}
      label={t("COMMON.LABELS.QUESTION_TYPE")}
      placeholder="Select"
      name="status"
      value={value}
      fullWidth={true}
      className={styles.dropDownWithoutBorder}
      isMandatory={isMandatory}
      defaultValue={t('COMMON.TEXT.ALL')}
    />
  );
}
