import React from "react";
import InputField from "app/components/InputField";
import { useTranslation } from "react-i18next";
import { MODES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectEditor } from "app/containers/Editors/redux/selector";
import { actions } from "app/containers/Editors/redux/slice";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { validateAndFormatIsraeliMobileNumber } from "styles/theme/utils";
import Summary from "../Summary";

type Props = { mode: MODES };

const Form = ({ mode }: Props) => {
  const { t } = useTranslation()
  const editor = useSelector(selectEditor);
  const dispatch = useDispatch();

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setFormValues({ key: name, value }));
  }
  
  return (
    <>
      {mode === MODES.NEW ? <></> : (
        <Summary />
      )}
      <Grid2 
        spacing={3} 
        container 
        sx={{ 
          padding: "20px", 
          margin: "5px",
          backgroundColor: "#F3F6F8", 
          borderRadius: "4px" ,
          marginBottom: "20px"
        }}
      >
        <Grid2 xs={6}>
          <InputField
            label={t("EDITORS.FORM.LABELS.EDITOR_NAME")}
            placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
            name="fullName"
            fullWidth={true}
            isDisable={mode === MODES.VIEW}
            lightPlaceholder
            isFieldReq
            value={editor?.fullName?.value}
            error={editor?.fullName?.error ? t(editor?.fullName?.error) : ""}
            onChange={onChange}
          />
        </Grid2>
        <Grid2 xs={6}>
          <InputField
            label={t("EDITORS.FORM.LABELS.DEPARTMENT")}
            placeholder={t("COMMON.PLACEHOLDERS.SELECT")}
            name="departmentName"
            fullWidth={true}
            isDisable={mode === MODES.VIEW}
            lightPlaceholder
            onChange={onChange}
            value={editor?.departmentName?.value}
            error={editor?.departmentName?.error ? t(editor?.departmentName?.error) : ""}
          />
        </Grid2>
        <Grid2 xs={6}>
          <InputField
            label={t("EDITORS.FORM.LABELS.PHONE_NUMBER")}
            placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
            name="mobileNumber"
            fullWidth={true}
            isDisable={mode === MODES.VIEW}
            lightPlaceholder
            isFieldReq
            value={
              validateAndFormatIsraeliMobileNumber(
                editor?.mobileNumber?.value
              ).formattedNumber
            }
            error={editor?.mobileNumber?.error ? t(editor?.mobileNumber?.error) : ""}
            onChange={(e)=>{
              const value = e.target.value;
              dispatch(actions.setFormValues({ key: "mobileNumber", value }));
            }}
          />
        </Grid2>
        <Grid2 xs={6}>
          <InputField
            label={t("EDITORS.FORM.LABELS.EMAIL")}
            placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
            name="email"
            fullWidth={true}
            isDisable={mode === MODES.VIEW}
            lightPlaceholder
            isFieldReq
            value={editor?.email?.value}
            error={editor?.email?.error ? t(editor?.email?.error) : ""}
            onChange={onChange}
          />
        </Grid2>
      </Grid2>
    </>
  );
};

export default Form;
