import React from 'react'
import CustomButton from '../CustomButton'
import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

export default function PrimaryButton({
    disabled,
    onClick,
    sx,
    label="Save",
    startIcon,
    isLoading,
}:{
    readonly disabled?: boolean;
    readonly onClick?: (e) => void;
    readonly sx?: SxProps<Theme>;
    readonly label?: string;
    readonly startIcon?: React.ReactNode;
    readonly isLoading?: boolean;
}) {
  const direction = useSelector(selectDirection);
    return (
        <CustomButton
          isLoading={isLoading}
          sx={{
            background: "#17171B",
            color: "#fff",
            "&:hover": {
              // @ts-ignore
              background: sx?.backgroundColor ?? sx?.background ?? "#17171B",
              opacity: 0.8,
            },
            textTransform: "none",
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontWeight: 600,
            fontSize: "14px",
            width: "200px",
            height: "44px",
            borderRadius: "8px",
            ...sx
          }}
          startIcon={startIcon}
          variant="contained"
          onClick={onClick}
          disabled={disabled}
          label={label}
        />
    )
}
