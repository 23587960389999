import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import CountInput from "app/components/CountInput";
import InputField from "app/components/InputField";
import { actions } from "app/containers/Questions/redux/slice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MODES } from "utils/constants";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { selectForm } from "app/containers/Questions/redux/selector";
import { selectDirection } from "styles/theme/slice";

export default function UpperDeck({ mode }: { mode: MODES }) {
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);

  const dispatch = useDispatch();

  const form = useSelector(selectForm);

  const onChange = (name, value) => {
    dispatch(actions.setFormValues({ key: name, value }));
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          data-color-mode="light"
        >
          <Typography
            sx={{
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0em",
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {t("QUESTIONS.FORM.LABELS.QUESTION_BODY")}
            <span style={{ color: "#ff0000" }}> *</span>
          </Typography>
          <MarkdownEditor
            height="410px"
            width="100%"
            theme={"light"}
            editable={mode !== MODES.VIEW}
            onChange={(value, viewUpdate) => onChange("body", value)}
            toolbars={["bold", "italic", "underline", "link", "quote"]}
            value={form.body.value}
          />
        </Box>
      </Grid2>
      <Grid2 xs={6}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              height: "154px",
            }}
          >
            <Box
              sx={{
                width: "80%",
              }}
            >
              <InputField
                label={t("QUESTIONS.FORM.LABELS.RIGHT_ANSWER_COMMENT")}
                placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
                fullWidth={true}
                isDisable={mode === MODES.VIEW}
                lightPlaceholder
                multiline={true}
                rows={3}
                onChange={(e) => {
                  onChange("rightAnswerComment", e.target.value);
                }}
                value={form.rightAnswerComment.value}
              />
            </Box>
            <Box
              sx={{
                width: "20%",
              }}
            >
              <CountInput
                label={t("QUESTIONS.FORM.LABELS.QUESTION_SCORE")}
                placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
                isDisable={mode === MODES.VIEW}
                onChange={(e) => {
                  onChange("score", e);
                }}
                value={
                  form.score.value == ""
                    ? 0
                    : isNaN(parseInt(form.score.value))
                    ? 0
                    : parseInt(form.score.value)
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                width: "70%",
              }}
            >
              <InputField
                label={t("QUESTIONS.FORM.LABELS.LINK_LABEL")}
                placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
                fullWidth={true}
                isDisable={mode === MODES.VIEW ||  (form.showLink.value == "false" ||
                  form.showLink.value == false)}
                lightPlaceholder
                onChange={(e) => {
                  onChange("linkLabel", e.target.value);
                }}
                value={form.linkLabel.value}
              />
            </Box>
            <Box
              sx={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  pr: "16px",
                }}
              >
                <FormControlLabel
                  sx={{
                    margin: 0,
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "left",
                  }}
                  disabled={mode === MODES.VIEW}
                  label={
                    <Typography
                      sx={{
                        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "14px",
                        letterSpacing: "0em",
                        display: "-webkit-box",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {t("QUESTIONS.FORM.LABELS.SHOW_LINK")}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      size="small"
                      sx={{
                        color: "#207DB9",
                        "&.Mui-checked": {
                          color: "#207DB9",
                        },
                      }}
                      defaultChecked={
                        form.showLink.value == "true" ||
                        form.showLink.value == true
                      }
                      checked={
                        form.showLink.value == "true" ||
                        form.showLink.value == true
                      }
                      onChange={(e) => {
                        console.log("console.log");

                        onChange(
                          "showLink",
                          form.showLink.value == "true" ? "false" : "true"
                        );
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              height: "214px",
              marginTop: "24px",
              width: "100%",
            }}
          >
            <InputField
              label={t("QUESTIONS.FORM.LABELS.LINK")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="questionName"
              fullWidth={true}
              lightPlaceholder
              multiline={true}
              isDisable={mode === MODES.VIEW ||  (form.showLink.value == "false" ||
                form.showLink.value == false)}
              rows={6}
              mainStyle={{
                width: "100%",
              }}
              onChange={(e) => {
                onChange("link", e.target.value);
              }}
              value={form.link.value}
            />
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  );
}
