import Grid2 from "@mui/material/Unstable_Grid2";
import MediaUploader from "app/components/MediaUploader";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ASSET_TYPE, MODES } from "utils/constants";
import { actions } from "app/containers/Questions/redux/slice";
import { selectForm } from "app/containers/Questions/redux/selector";
import { MediaTypes } from "app/containers/types";
import { useTranslation } from 'react-i18next';
export default function LowerDeck({ mode }: { mode: MODES }) {
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const { t } = useTranslation();
  return (
    <Grid2
      container
      spacing={2}
      sx={{
        marginTop: "20px",
      }}
    >
      <Grid2 xs={6}>
        <MediaUploader
          onCheck={() => {
            if(form.asset?.type === ASSET_TYPE.IMAGE) return;
            dispatch(actions.setAssetsValues({ key: "asset", value: {
              type: ASSET_TYPE.IMAGE,
              _id: form.asset?._id,
              mimeType: form.asset?.mimeType,
              fileName: form.asset?.fileName,
              filePath: form.asset?.filePath,
            } }));
          }}
          checked={form.asset?.type == ASSET_TYPE.IMAGE}
          disabled={mode === MODES.VIEW}
          disablePicker={form.asset?.type == ASSET_TYPE.VIDEO}
          type={MediaTypes.IMAGE}
          src={form.asset?.type == ASSET_TYPE.IMAGE ? form.asset.url : ""}
          onSuccess={(img) => {
            dispatch(
              actions.setAssetsValues({
                key: "asset",
                value: {
                  _id: img?._id,
                  type: ASSET_TYPE.IMAGE,
                  mimeType: img?.type,
                  fileName: img?.fileName,
                  filePath: img?.filePath,
                  url: img?.url,
                },
              })
            );
          }}
          title={t("COMMON.LABELS.IMAGE")}
          caption={t("STATIONS.FORM.LABELS.STATION_IMAGE_CAPTION")}
          height='200px'
          fallbackWidth="320px"
        />
      </Grid2>
      <Grid2 xs={6}>
        <MediaUploader
          onCheck={() => {
            if(form.asset?.type === ASSET_TYPE.VIDEO) return;
            dispatch(actions.setAssetsValues({ key: "asset", value: {
              type: ASSET_TYPE.VIDEO,
              _id: form.asset?._id,
              mimeType: form.asset?.mimeType,
              fileName: form.asset?.fileName,
              filePath: form.asset?.filePath,
            } }));
          }}
          disablePicker={form.asset?.type == ASSET_TYPE.IMAGE}
          checked={form.asset?.type == ASSET_TYPE.VIDEO}
          disabled={mode === MODES.VIEW}
          type={MediaTypes.VIDEO}
          src={form.asset?.type == ASSET_TYPE.VIDEO ? form.asset.url : ""}
          onSuccess={(img) => {
            dispatch(
              actions.setAssetsValues({
                key: "asset",
                value: {
                  _id: img?._id,
                  type: ASSET_TYPE.VIDEO,
                  mimeType: img?.type,
                  fileName: img?.fileName,
                  filePath: img?.filePath,
                  url: img?.url,
                },
              })
            );
          }}
          title={t("COMMON.LABELS.VIDEO")}
          caption={t("COMMON.LABELS.VIDEO_CAPTION")}
          height='200px'
          fallbackWidth="320px"
        />
      </Grid2>
    </Grid2>
  );
}
