import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import { ListItem } from "app/containers/types";
import { Styles } from "./styles";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDirection } from "styles/theme/slice";

interface Props {
  readonly list: ListItem[];
  readonly hovered: boolean;
  readonly onCheckChange?: (val) => void;
  readonly checkedLabel?: string;
  readonly checked?: boolean;
}

export default function DropdownOptions({
  list,
  hovered,
  onCheckChange,
  checkedLabel,
  checked,
}: Props) {
  const styles = Styles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();
  const direction = useSelector(selectDirection);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={clsx(styles.root, 'table-hover-menu-icon')}>
      <IconButton
        sx={{
          width: "20px",
          height: "20px",
          padding: "0px 2.5px 0px 2.49px",
          borderRadius: "4px",
          border: "0.5px solid #26A8E0",
        }}
        onClick={handleClick}
      >
        <MoreHorizTwoToneIcon
          sx={{
            fontSize: "14px",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleClose}
        
        sx={{
          "& .MuiPaper-root": {
            width: "211px",
            borderRadius: "4px",
            border: "1px solid #26A8E0",
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          dense: true,
          sx: {
            "& .MuiListItemText-root": {
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "14px !important",
              fontWeight: 500,
              lineHeight: "14px",
              letterSpacing: "0em",
              color: "#3C3C43",
            },
          },
        }}
      >
        {list.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              if (!item.isCheckBox) {
                item?.onClick?.();
                handleClose();
              }
            }}
          >
            {item.isCheckBox ? (
              <Switch
                defaultChecked={checked}
                checked={checked}
                onChange={(e) => {
                  if (onCheckChange) {
                    onCheckChange(checked?false:true);
                    handleClose();
                  }
                }}
              />
            ) : (
              <ListItemIcon>{item.icon}</ListItemIcon>
            )}

            <Typography
              sx={{
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "14px",
                letterSpacing: "0em",
              }}
            >
              {item.isCheckBox ? t(`MENU.${checkedLabel}`) : t(`MENU.${item.name}`)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
  
    </div>
  );
}
