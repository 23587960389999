import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL, translate } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import { removeBlankProperties } from "utils/helpers";
import toastService from "utils/toast";
import {
  CorelationAnswer,
  FreeTextAnswer,
  MixedLetterAnswer,
  Question,
  QuestionListResponse,
  QuestionsForm,
  SingleMultiSelectAnswer,
} from "../types";
import { Query } from "app/containers/types";
import { selectAnswer, selectForm, selectPagination } from "./selector";
import { actions as authAction } from "app/redux/slice";
import { selectOrganization } from "app/redux/selectors";

export function* getQuestionRequest({ payload }: { payload: string }) {
  yield delay(500);
  try {
    const id = payload;
    const { data, err, status }: Res<{ data: Question }> = yield call(
      request,
      `${API_URL}/v1/admin/question/get/${id}`
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err || !data) {
      throw err;
    }
    yield put(actions.getQuestionSuccess(data?.data));
  } catch (error: any) {
    yield put(actions.getQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* getQuestionsRequest({ payload }: { payload: Query }) {
  yield delay(500);
  try {
    const query = new URLSearchParams(
      removeBlankProperties(payload)
    ).toString();
    const { data, status, err }: Res<QuestionListResponse> = yield call(
      request,
      `${API_URL}/v1/admin/question/list?${query}`
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err || !data) {
      throw err;
    }
    yield put(
      actions.getQuestionsSuccess({
        data: data?.data,
        pagination: data?._metadata.pagination,
      })
    );
  } catch (error: any) {
    yield put(actions.getQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* createQuestionRequest({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  yield delay(500);
  try {
    const form: QuestionsForm = yield select(selectForm);
    const answer: Array<
      | SingleMultiSelectAnswer
      | CorelationAnswer
      | MixedLetterAnswer
      | FreeTextAnswer
    > = yield select(selectAnswer);
    if (form.type.value === "single_select") {
      // @ts-ignore
      const correctAnswers = (answer as SingleMultiSelectAnswer[]).filter(
        (a) => a?.isCorrect
      );
      if (correctAnswers.length !== 1) {
        throw new Error(
          translate("QUESTIONS.ERRORS.SELECT_ONE_CORRECT_ANSWER")
        );
      }
      const correctAnswersTitle = (answer as SingleMultiSelectAnswer[]).filter(
        (a) => a?.title.length > 0
      );

      if (correctAnswersTitle.length < 2) {
        throw new Error(translate("QUESTIONS.ERRORS.SELECT_TWO_TITLE"));
      }
    }

    if (form.type.value === "multi_select") {
      // @ts-ignore
      const correctAnswers = answer.filter((a) => a?.isCorrect);
      if (correctAnswers.length === 0) {
        throw new Error(
          translate("QUESTIONS.ERRORS.SELECT_ATLEAST_TWO_CORRECT_ANSWERS")
        );
      }

      const correctAnswersTitle = (answer as SingleMultiSelectAnswer[]).filter(
        (a) => a?.title.length > 0
      );
      if (correctAnswersTitle.length < 2) {
        throw new Error(translate("QUESTIONS.ERRORS.SELECT_TWO_TITLE"));
      }
    }

    if (form.type.value === "mixed_letter") {
      // @ts-ignore
      const words = answer.map((a) => a?.word);
      if (words.includes("")) {
        throw new Error(translate("QUESTIONS.ERRORS.FILL_ALL_WORDS"));
      }
    }

    if (form.type.value === "correlation") {
      // for each items check if left and right are not empty
      for (let index = 0; index < answer.length; index++) {
        const element = answer[index];
        if (index == 0 || index == 1) {
          // @ts-ignore
          if (element?.left === "" || element?.right === "") {
            throw new Error(translate("QUESTIONS.ERRORS.FILL_ALL_PAIRS"));
          }
        } else {
          // @ts-ignore
          if (element?.left !== "" && element?.right === "") {
            throw new Error(translate("QUESTIONS.ERRORS.FILL_PAIRS"));
          }
        }
      }
    }

    if (form.type.value === "free_text") {
      for (let index = 0; index < answer.length; index++) {
        const element = answer[index];

        let isVisibleLetterFound = false; // Flag to track if a visible letter is found
        let count = 0;
        // @ts-ignore
        for (const letter of element?.letters ?? []) {
          // Check if option1 or option2 are empty
          if (letter?.option1) {
            count = count + 1;
          }

          // Check for at least one visible letter
          if (letter?.isVisible) {
            isVisibleLetterFound = true;
          }
        }

        // If no visible letter found after checking all letters, throw error
        if (index == 0 && !isVisibleLetterFound) {
          throw new Error(
            translate("QUESTIONS.ERRORS.MAKE_ATLEAST_ONE_LETTER_VISIBLE")
          );
        }
        if (index == 0 && count < 2) {
          throw new Error(translate("QUESTIONS.ERRORS.FILL_ALL_LETTERS"));
        }
      }
    }

    const data = {
      type: form.type.value,
      rightAnswerComment: form.rightAnswerComment.value,
      body: form.body.value,
      link: form.link.value,
      linkLabel: form.linkLabel.value,
      organizationId: form.organizationId.value,
      score: form.score.value,
      showLink: JSON.parse(form.showLink.value),
      asset: form.asset,
      answer: answer,
    };

    const { status, err } = yield call(
      request,
      `${API_URL}/v1/admin/question/create`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err) {
      throw err;
    }
    yield put(actions.createQuestionSuccess());

    toastService.success(translate("QUESTIONS.SUCCESSFULLY_CREATED"));
    yield put(actions.resetForm());
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.createQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* updateQuestionRequest({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  yield delay(500);
  try {
    const form: QuestionsForm = yield select(selectForm);

    const answer: Array<
      | SingleMultiSelectAnswer
      | CorelationAnswer
      | MixedLetterAnswer
      | FreeTextAnswer
    > = yield select(selectAnswer);
    if (form.type.value === "single_select") {
      // @ts-ignore
      const correctAnswers = (answer as SingleMultiSelectAnswer[]).filter(
        (a) => a?.isCorrect
      );
      if (correctAnswers.length !== 1) {
        throw new Error(
          translate("QUESTIONS.ERRORS.SELECT_ONE_CORRECT_ANSWER")
        );
      }
      const correctAnswersTitle = (answer as SingleMultiSelectAnswer[]).filter(
        (a) => a?.title.length > 0
      );

      if (correctAnswersTitle.length < 2) {
        throw new Error(translate("QUESTIONS.ERRORS.SELECT_TWO_TITLE"));
      }
    }

    if (form.type.value === "multi_select") {
      // @ts-ignore
      const correctAnswers = answer.filter((a) => a?.isCorrect);
      if (correctAnswers.length === 0) {
        throw new Error(
          translate("QUESTIONS.ERRORS.SELECT_ATLEAST_TWO_CORRECT_ANSWERS")
        );
      }

      const correctAnswersTitle = (answer as SingleMultiSelectAnswer[]).filter(
        (a) => a?.title.length > 0
      );
      if (correctAnswersTitle.length < 2) {
        throw new Error(translate("QUESTIONS.ERRORS.SELECT_TWO_TITLE"));
      }
    }

    if (form.type.value === "mixed_letter") {
      // @ts-ignore
      const words = answer.map((a) => a?.word);
      if (words.includes("")) {
        throw new Error(translate("QUESTIONS.ERRORS.FILL_ALL_WORDS"));
      }
    }

    if (form.type.value === "correlation") {
      // for each items check if left and right are not empty
      for (let index = 0; index < answer.length; index++) {
        const element = answer[index];
        if (index == 0 || index == 1) {
          // @ts-ignore
          if (element?.left === "" || element?.right === "") {
            throw new Error(translate("QUESTIONS.ERRORS.FILL_ALL_PAIRS"));
          }
        } else {
          // @ts-ignore
          if (element?.left !== "" && element?.right === "") {
            throw new Error(translate("QUESTIONS.ERRORS.FILL_PAIRS"));
          }
        }
      }
    }

    if (form.type.value === "free_text") {
      for (let index = 0; index < answer.length; index++) {
        const element = answer[index];

        let isVisibleLetterFound = false; // Flag to track if a visible letter is found
        let count = 0;
        // @ts-ignore
        for (const letter of element?.letters ?? []) {
          // Check if option1 or option2 are empty
          if (letter?.option1) {
            count = count + 1;
          }

          // Check for at least one visible letter
          if (letter?.isVisible) {
            isVisibleLetterFound = true;
          }
        }

        // If no visible letter found after checking all letters, throw error
        if (index == 0 && !isVisibleLetterFound) {
          throw new Error(
            translate("QUESTIONS.ERRORS.MAKE_ATLEAST_ONE_LETTER_VISIBLE")
          );
        }
        if (index == 0 && count < 2) {
          throw new Error(translate("QUESTIONS.ERRORS.FILL_ALL_LETTERS"));
        }
      }
    }
    const data = {
      type: form.type.value,
      rightAnswerComment: form.rightAnswerComment.value,
      body: form.body.value,
      link: form.link.value,
      linkLabel: form.linkLabel.value,
      organizationId: form.organizationId.value,
      score: form.score.value,
      showLink: JSON.parse(form.showLink.value),
      asset: form.asset,
      answer: answer,
    };
    const { status, err } = yield call(
      request,
      `${API_URL}/v1/admin/question/update/${form._id}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
      }
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err) {
      throw err;
    }
    yield put(actions.updateQuestionSuccess());
    toastService.success(translate("QUESTIONS.SUCCESSFULLY_UPDATED"));
    yield put(actions.resetForm());
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.updateQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* deleteQuestionRequest({ payload }: { payload: string }) {
  yield delay(500);
  try {
    const organization = yield select(selectOrganization);
    const { status, err } = yield call(
      request,
      `${API_URL}/v1/admin/question/delete/${payload}`,
      {
        method: "DELETE",
      }
    );
    if (status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");
      yield put(authAction.refreshToken(refreshToken ?? ""));
      return;
    }
    if (err) {
      throw err;
    }
    yield put(actions.deleteQuestionSuccess());
    const pagination = yield select(selectPagination);
    toastService.success(translate("QUESTIONS.SUCCESSFULLY_DELETED"));
    yield put(
      actions.getQuestions({
        perPage: pagination.perPage,
        page: pagination.page,
        search: pagination.search,
        organization: organization ? organization.value : undefined,
        isActive: pagination.filters?.isActive,
        orderBy: pagination.orderBy,
        orderDirection: pagination.orderDirection,
      })
    );
  } catch (error: any) {
    yield put(actions.deleteQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}
export function* questionsSaga() {
  yield takeLatest(actions.getQuestion, getQuestionRequest);
  yield takeLatest(actions.getQuestions, getQuestionsRequest);
  yield takeLatest(actions.createQuestion, createQuestionRequest);
  yield takeLatest(actions.updateQuestion, updateQuestionRequest);
  yield takeLatest(actions.deleteQuestion, deleteQuestionRequest);
}
