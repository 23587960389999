import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { Editor, EditorsState } from "../types";
import set from "lodash/set";
import { FormError, Pagination, Query } from "app/containers/types";

const initialState = EditorsState;

export const formSlice = createSlice({
  name: "editorsState",
  initialState,
  reducers: {
    setFormValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.isUnsaved = true;
    },
    getEditors: (state, action: PayloadAction<Query>) => {
      state.loading = true;
    },
    getEditorsSuccess: (
      state,
      action: PayloadAction<{ editors: Editor[]; pagination: Pagination }>
    ) => {
      if (action.payload.pagination.page == 1) {
        state.editors = action.payload.editors;
      } else {
        state.editors = [...state.editors, ...action.payload.editors];
      }
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    getEditorsError: (state) => {
      state.loading = false;
    },
    setFormErrors(state, action: PayloadAction<FormError[]>) {
      action.payload.forEach(({ name, error }) => {
        set(state.form, `${name}.error`, error);
      });
    },
    getEditor(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    getEditorSuccess(state, action: PayloadAction<Editor>) {
      state.form.email.value = action.payload.email;
      state.form.fullName.value = action.payload.fullName;
      state.form.mobileNumber.value = action.payload.mobileNumber;
      state.form.organizationId.value = action.payload.organizationId;
      state.form.departmentName.value = action.payload.departmentName;
      state.form._id.value = action.payload._id;
      state.form.noOfQuestions.value = action.payload.noOfQuestions;
      state.form.noOfGames.value = action.payload.noOfGames;
      state.form.lastLogin.value = action.payload?.lastLogin;
      state.loading = false;
    },
    getEditorError(state) {
      state.loading = false;
    },
    createEditor: (
      state,
      action: PayloadAction<{ editor: Editor; callback?: () => void }>
    ) => {
      state.loading = true;
    },
    createEditorSuccess: (state) => {
      state.loading = false;
    },
    createEditorError: (state) => {
      state.loading = false;
    },
    updateEditor: (
      state,
      action: PayloadAction<{ editor: Editor; callback?: () => void }>
    ) => {
      state.loading = true;
    },
    updateEditorSuccess: (state, action: PayloadAction<Editor>) => {
      state.form.email.value = action.payload.email;
      state.form.fullName.value = action.payload.fullName;
      state.form.mobileNumber.value = action.payload.mobileNumber;
      state.form.organizationId.value = action.payload.organizationId;
      state.form.departmentName.value = action.payload.departmentName;
      state.form._id.value = action.payload._id;
      state.form.noOfQuestions.value = action.payload.noOfQuestions;
      state.form.noOfGames.value = action.payload.noOfGames;
      state.form.lastLogin.value = action.payload?.lastLogin;
      state.loading = false;
    },
    updateEditorError: (state) => {
      state.loading = false;
    },
    deleteEditor: (state, action: PayloadAction<string>) => {
      state.loading = true;
       state.editors= state.editors.filter((x)=>x._id!=action.payload);
    },
    deleteEditorSuccess: (state) => {
      state.loading = false;
     
    },
    deleteEditorError: (state) => {
      state.loading = false;
    },
    resetForm: (state) => {
      state.loading = false;
      state.form = initialState.form;
    },
  },
});

export const {
  reducer: editorsReducer,
  actions,
  name: editorsSlice,
} = formSlice;
