import React, { useEffect } from "react";
import CustomTable from "app/components/Table";
import { TableHeaderColumnType } from "app/components/Table/types";
import { Radio, TableRow } from "@mui/material";
import Uploader from "../Uploader";
import EditableColumn from "../FreeText/components/EditableColumn";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { selectAnswer } from "app/containers/Questions/redux/selector";
import { ASSET_TYPE, MODES } from "utils/constants";
import { actions } from "app/containers/Questions/redux/slice";
import { SingleMultiSelectAnswer } from "app/containers/Questions/types";
import CustomTableRow from "app/components/TableRow";
interface StyledCellProps {
  minWidth?: string | number;
  maxWidth?: string | number;
}

const StyledTableCell = styled("div")(
  ({ minWidth, maxWidth }: StyledCellProps) => ({
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "14px 16px",
    "&:last-of-type": {
      borderRight: "none",
    },
    backgroundColor: "transparent",
    textAlign: "unset",
    whiteSpace: "nowrap",
    height: 50,
    minWidth: minWidth || "auto",
    maxWidth: maxWidth || "none",
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3C3C43",
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F3F6F8",
  },
  "& td, & th": {
    border: 0,
  },
}));

const Headers: TableHeaderColumnType[] = [
  {
    id: "#",
    label: "HASH",
    align: "left",
    minWidth: 80,
    sortable: true,
  },
  {
    id: "rightAnswer",
    label: "RIGHT_ANSWER",
    align: "left",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "difficulty",
    label: "ANSWER_TEXT",
    align: "left",
    minWidth: 500,
    sortable: true,
  },
  {
    id: "action",
    label: "ACTIONS",
    align: "left",
    minWidth: 500,
    sortable: true,
  },
];

const Data: SingleMultiSelectAnswer[] = [
  {
    _id: "",
    isCorrect: false,
    isDelete: false,
    title: "",
    order: 0,
    asset: {
      _id: "",
      fileName: "",
      filePath: "",
      mimeType: "",
      type: ASSET_TYPE.IMAGE,
    },
  },
  {
    _id: "",
    isCorrect: false,
    isDelete: false,
    title: "",
    order: 0,
    asset: {
      _id: "",
      fileName: "",
      filePath: "",
      mimeType: "",
      type: ASSET_TYPE.IMAGE,
    },
  },
  {
    _id: "",
    isCorrect: false,
    isDelete: false,
    title: "",
    order: 0,
    asset: {
      _id: "",
      fileName: "",
      filePath: "",
      mimeType: "",
      type: ASSET_TYPE.IMAGE,
    },
  },
  {
    _id: "",
    isCorrect: false,
    isDelete: false,
    title: "",
    order: 0,
    asset: {
      _id: "",
      fileName: "",
      filePath: "",
      mimeType: "",
      type: ASSET_TYPE.IMAGE,
    },
  },
  {
    _id: "",
    isCorrect: false,
    isDelete: false,
    title: "",
    order: 0,
    asset: {
      _id: "",
      fileName: "",
      filePath: "",
      mimeType: "",
      type: ASSET_TYPE.IMAGE,
    },
  },
  {
    _id: "",
    isCorrect: false,
    isDelete: false,
    title: "",
    order: 0,
    asset: {
      _id: "",
      fileName: "",
      filePath: "",
      mimeType: "",
      type: ASSET_TYPE.IMAGE,
    },
  },
  {
    _id: "",
    isCorrect: false,
    isDelete: false,
    title: "",
    order: 0,
    asset: {
      _id: "",
      fileName: "",
      filePath: "",
      mimeType: "",
      type: ASSET_TYPE.IMAGE,
    },
  },
];

const SingleSelect = ({
  disabled,
  mode,
  onDelete,
}: {
  disabled?: boolean;
  mode: MODES;
  onDelete: (id: number) => void;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (mode == MODES.NEW) {
      dispatch(actions.setAnswer(Data));
    }
    return () => {};
  }, [mode]);

  const answers = useSelector(selectAnswer) as SingleMultiSelectAnswer[];

  return (
    <CustomTable headers={Headers} containerStyle={{ marginBottom: "50px" }}>
      {/* <StyledTableRow>
            <StyledTableCell colSpan={Headers?.length} align="center">No Data</StyledTableCell>
          </StyledTableRow> */}
      {answers?.map((item, index) => (
        <CustomTableRow key={index}>
          <StyledTableCell maxWidth={80} minWidth={80}>
            {index + 1}
          </StyledTableCell>
          <StyledTableCell maxWidth={180} minWidth={180}>
            <Radio
              disabled={disabled}
              checked={item.isCorrect}
              onChange={() => {
                dispatch(
                  actions.setSingleUpdateAnswer({
                    index: index,
                    key: "isCorrect",
                    value: !item.isCorrect,
                  })
                );
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  color: "#207DB9",
                },
                "&.Mui-disabled .MuiSvgIcon-root": {
                  color: "#8890A6",
                },
              }}
            />
          </StyledTableCell>
          <StyledTableCell maxWidth={500} minWidth={500}>
            <EditableColumn
              disabled={disabled}
              value={item.title}
              isOdd={index % 2 === 0}
              innerContainerStyle={{
                maxWidth: "100%",
              }}
              inputStyle={{
                background: "transparent",
              }}
              onSave={(val) => {
                dispatch(
                  actions.setUpdateAnswer({
                    index: index,
                    key: "title",
                    value: val,
                  })
                );
              }}
            />
          </StyledTableCell>
          <StyledTableCell maxWidth={500} minWidth={500}>
            <Uploader
              onUpload={(img) => {
                dispatch(
                  actions.setUpdateAnswer({
                    index: index,
                    key: "asset",
                    value: {
                      _id: img?._id,
                      type: ASSET_TYPE.IMAGE,
                      mimeType: img?.mimeType,
                      fileName: img?.fileName,
                      filePath: img?.filePath,
                      url: img?.url,
                    },
                  })
                );
              }}
              isDelete={
                item.asset?.fileName ? item.asset?.fileName.length > 0 : false
              }
              src={item.asset?.url}
              onDelete={() => {
                onDelete(index);
              }}
              isOdd={!(index % 2 === 0)}
            />
          </StyledTableCell>
        </CustomTableRow>
      ))}
    </CustomTable>
  );
};

export default SingleSelect;
