import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { Game, GameSettings, InitialGamesState } from "../types";
import set from "lodash/set";
import { FormError, Pagination, Query } from "app/containers/types";
import { Station } from "app/containers/Stations/types";


const initialState = InitialGamesState;

export const formSlice = createSlice({
  name: "gamesState",
  initialState,
  reducers: {
    setFormValues: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.isUnsaved = true;
    },
    setFormErrors(
      state,
      action: PayloadAction<FormError[]>
    ) {
      action.payload.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    resetForm: (state) => {
      state.form = initialState.form;
      state.selectedStations = [];
    },
    getGames: (state, action: PayloadAction<Query>) => {
      state.isLoading = true;
    },
    getGamesSuccess: (state, action: PayloadAction<{games: Game[], pagination: Pagination}>) => {
      state.games = action.payload.pagination.page === 1 ? action.payload.games : [...state.games, ...action.payload.games];
      state.pagination = action.payload.pagination;
      state.isLoading = false;
    },
    getGamesError: (state) => {
      state.isLoading = false;
    },
    getGame: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getGameSuccess: (state, action: PayloadAction<Game>) => {
      state.form._id.value = action.payload._id;
      state.form.name.value = action.payload.name;  
      state.form.backgroundId.value = action.payload.background?._id;
      state.form.gameBannerId.value = action.payload.gameBanner?._id;
      state.form.backgroundUrl.value = `${action.payload.background?.url}`;
      state.form.gameBannerUrl.value = `${action.payload.gameBanner?.url}`;
      state.form.groupInstructionText.value = action.payload.groupInstructionText;
      state.form.groupInstructionPDF.value = action.payload.groupInstructionPDF;
      state.form.groupInstructionPDFId.value = action.payload.groupInstructionPDF?._id;
      state.form.startGameText.value = action.payload.startGameText;
      state.form.endGameText.value = action.payload.endGameText;
      state.form.showEndPoint.value = action.payload.welcomeScreenSettings.showEndPoint;
      state.form.showStartPoint.value = action.payload.welcomeScreenSettings.showStartPoint;
      state.form.course.value = action.payload.courseSettings.course;
      state.form.endIsIdentical.value = action.payload.courseSettings.endIsIdentical;
      state.form.startIsIdentical.value = action.payload.courseSettings.startIsIdentical;
      state.form.endGameAssetId.value = action.payload.endGameAsset?._id;
      state.form.startGameAssetId.value = action.payload.startGameAsset?._id;
      state.form.startGameAssetUrl.value = action.payload.startGameAsset?.url;
      state.form.startGameAssetType.value = action.payload.startGameAsset?.type;
      state.form.endGameAssetType.value = action.payload.endGameAsset?.type;
      state.form.endGameAssetUrl.value = action.payload.endGameAsset?.url;
      state.selectedStations = action.payload.gameStations.map((gS, i) => ({
        id: gS._id,
        number: gS.group,
        station: {
          _id: gS.station._id,
          name: gS.station.name,
        }
      }));
      state.form.canSkip.value = action.payload.gameSettings?.canSkip;
      state.form.canSkipAfter.value = action.payload.gameSettings?.canSkipAfter;
      state.form.numberOfMistakesTillReveal.value = action.payload.gameSettings?.numberOfMistakesTillReveal;
      state.form.delayAfterMistake.value = action.payload.gameSettings?.delayAfterMistake;
      state.form.numberOfMistakesTillDelay.value = action.payload.gameSettings?.numberOfMistakesTillDelay;
      state.isLoading = false;
    },
    getGameError: (state) => {
      state.isLoading = false;
    },
    createGame: (state, action: PayloadAction<{game: Game, callback?: ()=>void }>) => {
      state.isLoading = true;
    },
    createGameSuccess: (state) => {
      state.isLoading = false;
    },
    createGameError: (state) => {
      state.isLoading = false;
    },
    updateGame: (state, action: PayloadAction<{game: Game, callback?: ()=>void }>) => {
      state.isLoading = true;
    },
    updateGameSuccess: (state, action: PayloadAction<Game>) => {
      state.form._id.value = action.payload._id;
      state.form.name.value = action.payload.name;  
      state.form.backgroundId.value = action.payload.background?._id;
      state.form.gameBannerId.value = action.payload.gameBanner?._id;
      state.form.backgroundUrl.value = action.payload.background?.url;
      state.form.gameBannerUrl.value = action.payload.gameBanner?.url;
      state.form.groupInstructionText.value = action.payload.groupInstructionText;
      state.form.groupInstructionPDF.value = action.payload.groupInstructionPDF?.url;
      state.form.groupInstructionPDFId.value = action.payload.groupInstructionPDF?._id;
      state.form.startGameText.value = action.payload?.startGameText;
      state.form.endGameText.value = action.payload?.endGameText;
      state.form.showEndPoint.value = action.payload.welcomeScreenSettings?.showEndPoint;
      state.form.showStartPoint.value = action.payload.welcomeScreenSettings?.showStartPoint;
      state.form.course.value = action.payload.courseSettings.course;
      state.form.endIsIdentical.value = action.payload.courseSettings.endIsIdentical;
      state.form.startIsIdentical.value = action.payload.courseSettings.startIsIdentical;
      state.form.endGameAssetId.value = action.payload.endGameAsset?._id;
      state.form.startGameAssetId.value = action.payload.startGameAsset?._id;
      state.form.startGameAssetUrl.value = action.payload.startGameAsset?.url;
      state.form.startGameAssetType.value = action.payload.startGameAsset?.type;
      state.form.endGameAssetType.value = action.payload.endGameAsset?.type;
      state.form.endGameAssetUrl.value = action.payload.endGameAsset?.url;
      state.selectedStations = action.payload.gameStations.map((gS, i) => ({
        id: gS._id,
        number: gS.group,
        station: {
          _id: gS.station._id,
          name: gS.station.name,
        }
      }));
      state.isLoading = false;
    },
    updateGameError: (state) => {
      state.isLoading = false;
    },
    getStations(state, action: PayloadAction<Query>){
      state.isLoadingStations = true
    },
    saveGameSettings(state, action: PayloadAction<{gameId: string, settings: GameSettings, callback: ()=>void}>){
      state.isLoading = true;
    },
    saveGameSettingsComplete(state){
      state.isLoading = false;
    },
    getStationsSuccess(state, action: PayloadAction<{stations: Station[], pagination: Pagination}>) {
      state.stations = action.payload.pagination.page === 1 ? action.payload.stations : [...state.stations, ...action.payload.stations];
      state.stationsPagination = action.payload.pagination;
      state.isLoadingStations = false
    },
    getStationsError(state){
      state.isLoadingStations = false;
    },
    deleteGame: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
       state.games= state.games.filter((x)=>x._id!=action.payload)
    },
    deleteGameSuccess: (state) => {
      state.isLoading = false;
     
    },
    deleteGameError: (state) => {
      state.isLoading = false;
    },
    addToSelectedStations(state, action: PayloadAction<{id:string; number:number;station: {_id: string, name: string}}>){
      state.selectedStations.push(action.payload);
    },
    removeFromSelectedStations(state, action: PayloadAction<string>){
      state.selectedStations = state.selectedStations.filter(q => q?.station._id !== action.payload);
    },
    setSelectedStations(state, action: PayloadAction<{id:string; number:number;station: {_id: string, name: string}}[]>){
      state.selectedStations = action.payload;
    },
    setUpdateGroupNameSelectedStations(state, action: PayloadAction<{index:number,value:number}>){
      state.selectedStations[action.payload.index].number=action.payload.value
    },
  },
});

export const { reducer: gamesReducer, actions, name: gamesSlice } = formSlice;
