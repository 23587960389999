import { FormError } from "app/containers/types";
import { StationForm, StationTypes } from "../../types";

export const validateStation = (form: StationForm): FormError[] => {
  let errors: FormError[] = [];

  if (!form.name.value) {
    errors.push({
      name: "name",
      error: "STATIONS.ERRORS.NAME_IS_REQUIRED",
    });
  }
  if (form.type.value === StationTypes.POINT) {
    if (!form.city.value) {
      errors.push({
        name: "city",
        error: "STATIONS.ERRORS.CITY_IS_REQUIRED",
      });
    }
  }

  // if (!form.goToMessage1.value) {
  //   errors.push({
  //     name: "goToMessage1",
  //     error: "STATIONS.ERRORS.GO_TO_MESSAGE_IS_REQUIRED",
  //   });
  // }

  // if (!form.goToMessage2.value) {
  //   errors.push({
  //     name: "goToMessage2",
  //     error: "STATIONS.ERRORS.GO_TO_MESSAGE_IS_REQUIRED",
  //   });
  // }

  // if (!form.feedBackOnSuccessfulArrival.value) {
  //   errors.push({
  //     name: "feedBackOnSuccessfulArrival",
  //     error: "STATIONS.ERRORS.FEEDBACK_ON_SUCCESSFUL_ARRIVAL_IS_REQUIRED",
  //   });
  // }

  // if (!form.stationInstruction.value) {
  //   errors.push({
  //     name: "stationInstruction",
  //     error: "STATIONS.ERRORS.STATION_INSTRUCTION_IS_REQUIRED",
  //   });
  // }

  if (form.type.value === StationTypes.POINT) {
    if (!form.address.value) {
      errors.push({
        name: "address",
        error: "STATIONS.ERRORS.ADDRESS_IS_REQUIRED",
      });
    }
    if (!form.coordinates.value) {
      errors.push({
        name: "coordinates",
        error: "STATIONS.ERRORS.COORDINATES_IS_REQUIRED",
      });
    }
    if (!form.radius.value) {
      errors.push({
        name: "radius",
        error: "STATIONS.ERRORS.RADIUS_IS_REQUIRED",
      });
    }
  }

  if (form.type.value === StationTypes.QR) {
    if (!form.qrText.value) {
      errors.push({
        name: "qrText",
        error: "STATIONS.ERRORS.QR_TEXT_IS_REQUIRED",
      });
    }
  }

  if (form.type.value === StationTypes.CODE) {
    if (!form.code.value) {
      errors.push({
        name: "code",
        error: "STATIONS.ERRORS.CODE_IS_REQUIRED",
      });
    }
  }

  // if(!form.stationImageId.value) {
  //   errors.push({
  //     name: "stationImageUrl",
  //     error: "STATIONS.ERRORS.STATION_IMAGE_IS_REQUIRED",
  //   });
  // }

  // if(!form.stationImageUrl.value) {
  //   errors.push({
  //     name: "stationImageUrl",
  //     error: "STATIONS.ERRORS.STATION_IMAGE_IS_REQUIRED",
  //   });
  // }

  return errors;
};
