import { Grid } from "@mui/material";
import React from "react";
import InputField from "app/components/InputField";
import { useTranslation } from "react-i18next";
import { MODES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Organizations/redux/slice";
import {
  selectFormValues,
  selectIsLoading,
} from "app/containers/Organizations/redux/selector";
import { validateAndFormatIsraeliMobileNumber } from "styles/theme/utils";

type Props = { mode: MODES };

const Form = ({ mode }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(selectFormValues);
  const isLoading = useSelector(selectIsLoading);

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setFormValues({ key: name, value }));
  };

  return (
    <Grid item xs={12}>
      <Grid
        sx={{ padding: "20px", backgroundColor: "#F3F6F8", height: "580px" }}
      >
        <Grid item container spacing={3} md={12} sx={{ paddingBottom: "20px" }}>
          <Grid item xs={6}>
            <InputField
              label={t("ORGANIZATIONS.FORM.LABELS.ORGANIZATION_NAME")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="name"
              fullWidth={true}
              isDisable={mode === MODES.VIEW || isLoading}
              lightPlaceholder
              isFieldReq
              onChange={onChange}
              value={organization.name?.value}
              error={
                organization.name?.error ? t(`${organization.name?.error}`) : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label={t("ORGANIZATIONS.FORM.LABELS.CONTACT_NAME")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="contactName"
              fullWidth={true}
              isDisable={mode === MODES.VIEW || isLoading}
              lightPlaceholder
              onChange={onChange}
              value={organization.contactName?.value}
              error={
                organization.contactName?.error
                  ? t(`${organization.contactName?.error}`)
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3} md={12} sx={{ paddingBottom: "20px" }}>
          <Grid item xs={6}>
            <InputField
              type={"number"}
              inputProps={{ maxLength: 6 }}
              label={t("ORGANIZATIONS.FORM.LABELS.TAX_NUMBER")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="taxNumber"
              fullWidth={true}
              isDisable={mode === MODES.VIEW || isLoading}
              lightPlaceholder
              onChange={onChange}
              value={organization.taxNumber?.value}
              error={
                organization.taxNumber?.error
                  ? t(`${organization.taxNumber?.error}`)
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label={t("ORGANIZATIONS.FORM.LABELS.EMAIL")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="email"
              fullWidth={true}
              isDisable={mode === MODES.VIEW || isLoading}
              lightPlaceholder
              isFieldReq
              onChange={onChange}
              value={organization.email?.value}
              error={
                organization.email?.error
                  ? t(`${organization.email?.error}`)
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3} md={12} sx={{ paddingBottom: "20px" }}>
          <Grid item xs={6}>
            <InputField
              label={t("ORGANIZATIONS.FORM.LABELS.ADDRESS")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="address"
              fullWidth={true}
              isDisable={mode === MODES.VIEW || isLoading}
              lightPlaceholder
              onChange={onChange}
              value={organization.address?.value}
              error={
                organization.address?.error
                  ? t(`${organization.address?.error}`)
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputField
              label={t("ORGANIZATIONS.FORM.LABELS.MOBILE_NUMBER")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="mobileNumber"
              fullWidth={true}
              isDisable={mode === MODES.VIEW || isLoading}
              lightPlaceholder
              onChange={(e)=>{
                const value = e.target.value
                dispatch(actions.setFormValues({ key: "mobileNumber", value }));
              }}
              value={
                validateAndFormatIsraeliMobileNumber(
                  organization.mobileNumber?.value
                ).formattedNumber
              }
              error={
                organization.mobileNumber?.error
                  ? t(`${organization.mobileNumber?.error}`)
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Grid item md={12}>
          <InputField
            label={t("ORGANIZATIONS.FORM.LABELS.NOTES")}
            placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
            name="note"
            fullWidth={true}
            isDisable={mode === MODES.VIEW || isLoading}
            multiline={true}
            rows={6}
            lightPlaceholder
            onChange={onChange}
            value={organization.note?.value}
            error={
              organization.note?.error ? t(`${organization.note?.error}`) : ""
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Form;
