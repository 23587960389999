import React from 'react';

import { Box, FilledInputProps, InputProps, MenuItem, OutlinedInputProps, TextField, Tooltip, Typography } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import { selectDirection } from 'styles/theme/slice';
import { useSelector } from 'react-redux';

type Props = {
  onChange?: any;
  isEdit?: boolean | undefined;
  defaultValue?: string | null;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | undefined;
  lightPlaceholder?: boolean;
  isDisable?: boolean;
  isFieldReq?: boolean;
  mt?: string;
  mb?: string;
  minWidth?: string;
  backgroundColor?: string;
  value?: any;
  name?: any;
  editable?: any;
  inputProps?: any;
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps> | undefined
  onBlur?: any;
  error?: string;
  mainStyle?: any;
  type?: string;
  sx?: any;
  multiline?: boolean;
  rows?: number;
  labelFontWeight?: number | string;
  labelFontSize?: number | string;
  maxLength?: number;
  className?: any;
  onKeyDown?: any;
  borderRadius?: string;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  borderColor?: string;
  autoFocus?: boolean;
  options?: {
    name: string;
    id: string;
  }[]
  inputRef?: any;
  tooltip?: string;
};

const InputField = ({
  label,
  placeholder,
  fullWidth,
  size,
  inputRef,
  lightPlaceholder,
  isDisable,
  isFieldReq,
  mt,
  mb,
  minWidth,
  backgroundColor,
  onChange,
  value,
  name,
  editable,
  inputProps,
  onBlur,
  error,
  mainStyle,
  type,
  sx,
  multiline,
  rows,
  maxLength,
  className,
  onKeyDown,
  borderRadius,
  defaultValue,
  inputStyle,
  labelStyle,
  borderColor="#86BDE3",
  autoFocus=false,
  options=[],
  tooltip,
}: Props) => {
  const direction = useSelector(selectDirection);
  return (
    <Box sx={{ marginBottom: "10px"}} style={mainStyle}>
      {label && (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}>        
          <Typography
            sx={{
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0em",
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              ...labelStyle
            }}
          >
            {label}
            {isFieldReq && <span style={{ color: '#ff0000' }}> *</span>}
          </Typography>
          {
            tooltip && (
              <Tooltip title={tooltip}>
                <InfoIcon
                  sx={{
                    fontSize: "18px",
                    marginLeft: "6px",
                    color: "#3C3C43",
                  }}
                />
              </Tooltip>
            )
          }
        </Box>
      )}
      <TextField
        autoFocus={autoFocus}
        type={type}
        disabled={isDisable}
        inputProps={{ maxLength: maxLength, ...inputProps }}
        size={size}
        fullWidth={fullWidth}
        onChange={onChange}
        onBlur={onBlur}
        error={!!error}
        helperText={error}
        multiline={multiline}
        defaultValue={defaultValue}
        rows={rows}
        ref={inputRef}
        select={options?.length > 0}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: error ? '#d32f2f' : borderColor,
              borderRadius: borderRadius || '4px',
            },
            '&:hover fieldset': {
              borderColor: error ? '#d32f2f' : borderColor,
              borderRadius: borderRadius || '4px',
            },
            '&.Mui-focused fieldset': {
              borderColor: error ? '#d32f2f' : borderColor,
              borderRadius: borderRadius || '4px',
            },
          }, 
          "& .MuiSelect-select": {
            borderColor: error ? '#d32f2f' : borderColor,
            backgroundColor: backgroundColor ?? '#ffffff',
          },
          // style helper text
          '& .MuiFormHelperText-root': {
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px",
            letterSpacing: "0em",
            margin: 0,
            color: '#d32f2f',
            direction: direction,
            textAlign: direction === "ltr" ? "left" : "right",
          },
          input: {
            '&::placeholder': {
              color: lightPlaceholder ? '#727F89' : '#1D1F20',
              opacity: '100%',
              fontWeight: '400',
              fontSize: '14px',
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            },
            fontWeight: '400',
            fontSize: '14px',
            borderColor: error ? '#d32f2f' : borderColor,
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            backgroundColor: backgroundColor ?? '#ffffff',
            borderRadius: borderRadius ?? '4px',
            '&:hover': {
              borderColor: error ? '#d32f2f' : borderColor,
            },
            '&:focus': {
              borderColor: error ? '#d32f2f' : borderColor,
            },
            ...inputStyle
          },
          ...multiline ? {
            '& .MuiInputBase-multiline': {
              padding: "0px 0px!important",
            },
            textArea: {
              padding: "16.5px 14px",
              backgroundColor: '#FFFFFF',
              fontWeight: '400',
              fontSize: '14px',
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              borderRadius: borderRadius || "4px",
              '&::placeholder': {
                color: lightPlaceholder ? '#727F89' : '#1D1F20',
                opacity: '100%',
                fontWeight: '400',
                fontSize: '14px',
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              },
            }
          } : {},
          ...sx,
        }}
        className={className}
        placeholder={placeholder}
        value={value}
        name={name}
        onKeyDown={onKeyDown}
      >
        {
          options?.length > 0 && options?.map((option) => (
            <MenuItem 
              key={option.id}
              value={option.name}
            >
              <Typography
                  sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "14px !important",
                  }}
              >
                {option.name}
              </Typography>
            </MenuItem>
          ))
        }
      </TextField>
    </Box>
  );
};
export default InputField;
