import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import CustomTable, { StyledCell } from "app/components/Table";

import FloatingButton from "app/components/Buttons/FloatingButton";
import { MenuOptions, TableHeaderDefinition, TableHeaders } from "./headers";
import MenuWrapper from "app/components/MenuWrapper";
import ConfirmDialog from "app/components/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoading,
  selectOrganizations,
  selectPagination,
} from "../../redux/selector";
import { actions } from "../../redux/slice";
import { modifyMenuOptions } from "utils/constants";
import { formatUUID } from "styles/theme/utils";
import CustomTableRow from "app/components/TableRow";
import { Typography } from "@mui/material";
import { OrderDirections } from "app/containers/types";

const List = () => {
  const [confirmDelete, setConfirmDelete] = React.useState({
    status: false,
    id: "",
  });
  const organizations = useSelector(selectOrganizations);
  const pagination = useSelector(selectPagination);
  const isLoading = useSelector(selectIsLoading);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const q = searchParams?.[0]?.get("q");
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction");

  useEffect(() => {
    dispatch(
      actions.getOrganizations({
        page: 1,
        search: q ? q : undefined,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      })
    );
  }, [q, dispatch, orderBy, orderDirection]);

  const addNewUser = () => {
    navigate("/app/organizations/new");
  };

  const onClickAction = (id: string, organizationId: string) => {
    switch (id) {
      case "1":
        navigate(`/app/organizations/${organizationId}/edit`);
        break;
      case "3":
        setConfirmDelete({
          status: true,
          id: organizationId,
        });
        break;
      default:
        break;
    }
  };

  const deleteOrganization = () => {
    dispatch(actions.deleteOrganization(confirmDelete.id));
    setConfirmDelete({
      status: false,
      id: "",
    });
  };

  const hasMoreItems = pagination.page < pagination.total;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getOrganizations({
        page: pagination.page + 1,
        search: q ? q : undefined,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      }));
    }
  };

  return (
    <>
      <Header />
      <CustomTable
        headers={TableHeaders}
        loading={isLoading}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        dataLength={organizations.length}
      >
        {organizations.map((org) => (
          <CustomTableRow
            onDoubleClick={() =>
              navigate(`/app/organizations/${org?._id}/view`)
            }
            hoverOptionComponent={
              <MenuWrapper
                options={modifyMenuOptions(
                  MenuOptions,
                  org?._id ?? "",
                  onClickAction,
                  org?.name ?? ""
                )}
                checkedLabel={`${org.isActive ? "ENABLE" : "DISABLE"}`}
                checked={org.isActive}
                onCheckChange={(e) => {
                  console.log("e", e, org.isActive);
                  dispatch(
                    actions.updateStatusOrganization({
                      id: org._id || "",
                      status: !org.isActive,
                    })
                  );
                }}
              />
            }
            key={org?._id}
          >
            <StyledCell
                minWidth={TableHeaderDefinition.ORGANIZATION_ID.minWidth}
                maxWidth={TableHeaderDefinition.ORGANIZATION_ID.maxWidth}>
                <Typography
                  sx={{
                    color: "#2F80ED",
                    fontSize: "14px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/app/organizations/${org?._id}/view`);
                  }}
                >
                  {formatUUID(org?._id)}
                </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.ORGANIZATION_NAME.minWidth}
              maxWidth={TableHeaderDefinition.ORGANIZATION_NAME.maxWidth}
            >{org.name}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CONTACT_NAME.minWidth}
              maxWidth={TableHeaderDefinition.CONTACT_NAME.maxWidth}
            >{org.contactName || "-"}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.ADDRESS.minWidth}
              maxWidth={TableHeaderDefinition.ADDRESS.maxWidth}
            >{org.address}</StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.TAX_NUMBER.minWidth}
              maxWidth={TableHeaderDefinition.TAX_NUMBER.maxWidth}
            >{org.taxNumber}</StyledCell>
            
          </CustomTableRow>
        ))}
      </CustomTable>
      <FloatingButton
        onClick={addNewUser}
        label={t("ORGANIZATIONS.ORGANIZATION")}
        containerStyle={{
          width: "200px",
        }}
      />

      <ConfirmDialog
        open={confirmDelete.status}
        handleClose={() =>
          setConfirmDelete({
            status: false,
            id: "",
          })
        }
        onConfirm={deleteOrganization}
        title={t("COMMON.DELETE.TITLE", {
          name: t("ORGANIZATIONS.ORGANIZATION"),
        })}
        message={t("COMMON.DELETE.MESSAGE", {
          name: t("ORGANIZATIONS.ORGANIZATION"),
        })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.DELETE")}
        isLoading={isLoading}
      />
    </>
  );
};

export default List;
