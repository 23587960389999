import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import ActionButtons from "app/components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slice";
import {
  selectEditor,
  selectIsLoading,
  selectIsUnsaved,
} from "../../redux/selector";
import { validateEditor } from "./validator";
import { selectOrganization } from "app/redux/selectors";
import ConfirmDialog from "app/components/ConfirmDialog";
import { useTranslation } from "react-i18next";
import Option from "app/components/ConfirmDialog/Component/Option";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useSelector(selectEditor);
  const organizationId = useSelector(selectOrganization);
  const isLoading = useSelector(selectIsLoading);
  const isUnsaved = useSelector(selectIsUnsaved);

  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [saveOption, setSaveOption] = useState(1);
  console.log("organizationId", organizationId);

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    dispatch(actions.getEditor(id));
  }, [id, mode, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm());
    };
  }, [dispatch]);

  const onSubmit = () => {
    const errors = validateEditor(editor);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.createEditor({
        editor: {
          email: editor.email.value,
          fullName: editor.fullName.value,
          mobileNumber: editor.mobileNumber.value.replace("-", ""),
          departmentName: editor.departmentName.value,
          organizationId: organizationId?.value,
          _id: editor._id.value,
        },
        callback: () => navigate("/app/editors"),
      })
    );
  };

  const onUpdate = () => {
    const errors = validateEditor(editor);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.updateEditor({
        editor: {
          email: editor.email.value,
          fullName: editor.fullName.value,
          mobileNumber: editor.mobileNumber.value.replace("-", ""),
          departmentName: editor.departmentName.value,
          _id: editor._id.value,
        },
        callback: () => navigate("/app/editors"),
      })
    );
  };

  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
    navigate(`/app/editors/${id}/edit`);
  };

  const handleCancelBtn = () => {
    if (mode == MODES.EDIT) {
      if (isUnsaved) {
        setShowConfirm(true);
        return;
      }
    }
    navigate("/app/editors");
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    navigate("/app/editors");
  };
  const handelSaveConfirm = () => {
    if (saveOption == 0) {
      onUpdate();
    } else {
      onSubmit();
    }
    setSaveOption(0);
  };

  return (
    <>
      <div className={styles.breadCrumbSectionParent}>
        <BreadCrumb
          onBackClick={handleCancelBtn}
          title={generatePageTitle(Modules.EDITORS, mode)}
        />
      </div>
      {/**Editor Details Form */}
      <Form mode={mode} />
      <ActionButtons
        handleCancelBtn={handleCancelBtn}
        handleConfirmBtn={handleConfirmBtn}
        mode={mode}
        isLoading={isLoading}
      />
      <ConfirmDialog
        open={showConfirm}
        handleClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.EDITOR"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
      <ConfirmDialog
        open={showSaveConfirm}
        handleClose={() => {
          setShowSaveConfirm(false);
          setSaveOption(0);
        }}
        onConfirm={handelSaveConfirm}
        title={t("COMMON.SAVED_CHANGES.TITLE", { name: t("EDITORS.EDITOR") })}
        message={t("COMMON.SAVED_CHANGES.MESSAGE", {
          name: t("EDITORS.EDITOR"),
        })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.SAVE")}
      >
        <Option
          disabled={false}
          value={saveOption}
          onChange={(e) => {
            console.log(e);

            setSaveOption(e);
          }}
        />
      </ConfirmDialog>
    </>
  );
};

export default Details;
