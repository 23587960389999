import { Box, Typography } from "@mui/material";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import InputField from "app/components/InputField";
import React from "react";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { selectConfirmPassword, selectLoading, selectPassword } from "../../redux/selectors";
import { actions } from "../../redux/slice";
import { validate } from "../../validator";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import toastService from "utils/toast";
import { selectDirection } from "styles/theme/slice";
export const CreatePasswordForm = ({
    onSuccess
}:{
    onSuccess: () => void;
}) => {
    const styles = useStyles();
    const isLoading = useSelector(selectLoading);
    const password = useSelector(selectPassword);
    const confirmPassword = useSelector(selectConfirmPassword);
    const dispatch = useDispatch();
    const { t } = useTranslation();
  const direction = useSelector(selectDirection);
    const searchParams = useSearchParams();
    const email = searchParams?.[0]?.get("email");
    const hash = searchParams?.[0]?.get("hash");

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            actions.setFormValues({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!email || !hash) {
            toastService.error(t("AUTH.ERRORS.CREATE_PASSWORD.ERROR"));
            return;
        };
        const errors = validate({
            password: password,
            confirmPassword: confirmPassword,
        });
        if (errors?.length > 0) {
            dispatch(actions.setFormErrors(errors));
            return;
        }
        dispatch(actions.create({
            creds: {
                email: email??"",
                password: password?.value,
                hash: hash??"",
            },
            callback: onSuccess,
        }));
    }

    return (
    <form className={styles.form} noValidate>
        <Box width="100%" mt={1}>
            <Typography className={styles.loginText}>
                {t("AUTH.CREATE_PASSWORD.TITLE")}
            </Typography>
            <Typography
                sx={{
                    fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                    fontSize: "18px",
                fontWeight: 500,
                lineHeight: "27px",
                letterSpacing: "0em",
                textAlign: "center",
                marginBottom: "32px",
                color: "#8890A6",
                }}
            >
                {t("AUTH.CREATE_PASSWORD.CAPTION")}
            </Typography>
        </Box>
        <InputField
            label={t("AUTH.FORM.EMAIL")}
            onChange={onChange}
            fullWidth
            name="email"
            mainStyle={{ paddingBottom: "24px", width: "100%" }}
            labelStyle={{ fontWeight: 600 }}
            value={email}
            isDisable
        />
        <InputField
            label={t("AUTH.FORM.PASSWORD")}
            onChange={onChange}
            fullWidth
            name="password"
            mainStyle={{ paddingBottom: "24px", width: "100%" }}
            labelStyle={{ fontWeight: 600 }}
            value={password?.value}
            tooltip={t("AUTH.FORM.PASSWORD_TOOLTIP")}
            error={password?.error ? t(password.error) : ""}
            isDisable={isLoading}
            type="password"
        />
        <InputField
            label={t("AUTH.FORM.CONFIRM_PASSWORD")}
            onChange={onChange}
            fullWidth
            name="confirmPassword"
            mainStyle={{ paddingBottom: "24px", width: "100%" }}
            tooltip={t("AUTH.FORM.PASSWORD_TOOLTIP")}
            labelStyle={{ fontWeight: 600 }}
            value={confirmPassword?.value}
            error={confirmPassword?.error ? t(confirmPassword.error) : ""}
            isDisable={isLoading}
            type="password"
        />

        <Box
            className={styles.loginButtonWrapper}
            sx={{
                my: 3,
            }}
        >
            <PrimaryButton
                label={t("AUTH.BUTTONS.LOGIN")}
                sx={{
                    width: "100%",
                    height: "48px",
                    textTransform: "none",
                }}
                onClick={onSubmit}
                isLoading={isLoading}
            />
        </Box>
    </form>
    )
}