import { TableHeaderColumnType } from "app/components/Table/types";

export const TableHeaderDefinition = {
  CUSTOMER_ID: {
    id: "_id",
    label: 'CUSTOMER_ID',
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,sortable:false
  },
  REGISTRATION_DATE: {
    id: "createdAt",
    label: 'REGISTRATION_DATE',
    format: (value) => value,
    maxWidth: 250,
    minWidth: 250,
    sortable:true
  },
  LAST_PURCHASE_DATE: {
    id: "updatedAt",
    label: 'LAST_PURCHASE_DATE',
    format: (value) => value,
    maxWidth: 250,
    minWidth: 250,
    sortable:true
  },
  CUSTOMER_NAME: {
    id: "name",
    label: 'CUSTOMER_NAME',
    format: (value) => value,
    maxWidth: 250,
    minWidth: 250,
    sortable:true
  },
  MOBILE_NUMBER: {
    id: "mobileNumber",
    label: 'MOBILE_NUMBER',
    format: (value) => value,
    maxWidth: 250,
    minWidth: 250,
    sortable:true
  },
  EMAIL: {
    id: "email",
    label: 'EMAIL',
    format: (value) => value,
    maxWidth: 250,
    minWidth: 250,
    sortable:true
  },
  NUMBER_OF_PURCHASED_GAMES: {
    id: "numberOfPurchasedGames",
    label: 'NUMBER_OF_PURCHASED_GAMES',
    format: (value) => value,
    maxWidth: 230,
    minWidth: 230,
    sortable:true
  },
  NUMBER_OF_PLAYED_GAMES: {
    id: "noOfPlayedGames",
    label: 'NUMBER_OF_PLAYED_GAMES',
    format: (value) => value,
    maxWidth: 230,
    minWidth: 230,
    sortable:true
  }
};

export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);