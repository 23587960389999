import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { selectTicket } from "app/containers/Tickets/redux/selector";
import { actions } from "app/containers/Tickets/redux/slice";
import { useTranslation } from "react-i18next";
import { selectDirection } from "styles/theme/slice";
export default function Counter({
  maxCount = 10,
  disabled,
}: {
  maxCount?: number;
  disabled?: boolean;
}) {
  const { noOfDevices } = useSelector(selectTicket);
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);

  const onIncrease = () => {
    if (disabled) return;
    if (noOfDevices?.value < maxCount) {
      dispatch(
        actions.setFormValues({
          key: "noOfDevices",
          value: noOfDevices?.value + 1,
        })
      );
    }
  };

  const onDecrease = () => {
    if (disabled) return;
    if (noOfDevices?.value > 0) {
      dispatch(
        actions.setFormValues({
          key: "noOfDevices",
          value: noOfDevices?.value - 1,
        })
      );
    }
  };
  if (noOfDevices?.value == 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        maxWidth: "76px",
        height: "36px",
        padding: "6px 12px 6px 12px",
        borderRadius: "4px",
        backgroundColor: "#F3F6F8",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {" "}
      <>
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "left",
            opacity: disabled ? 0.5 : 1,
          }}
        >
          {noOfDevices?.value}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2px",
          }}
        >
          <IconButton  sx={{ width: '20px', height: '20px', mt: '20px'}} size='small' onClick={onIncrease}>
            <KeyboardArrowUpIcon
              sx={{
                fontSize: "18px",
                opacity: disabled ? 0.5 : 1,
              }}
            />
          </IconButton>
          <IconButton sx={{width: '20px', height: '20px',mb: '20px'}} size='small' onClick={onDecrease}>
            <KeyboardArrowDownIcon
              sx={{
                fontSize: "18px",
                opacity: disabled ? 0.5 : 1,
              }}
            />
          </IconButton>
        </Box>
      </>
    </Box>
  );
}
