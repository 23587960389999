import React from "react";
import { TableHeaderColumnType } from "app/components/Table/types";
import edit from "assets/ic_edit.svg";
import duplicate from "assets/duplicate.svg";
import trash from "assets/ic_trash.svg";
import { ListItem } from "app/containers/types";

export const TableHeaderDefinition = {
  STATION_ID: {
    id: "_id",
    label: "STATION_ID",
    minWidth: 130,
    maxWidth: 130,
    sortable: false,
  },
  STATION_NAME: {
    id: "name",
    label: "STATION_NAME",
    minWidth: 300,
    maxWidth: 300,
    sortable: true,
  },
  STATION_CITY: {
    id: "city.name",
    label: "STATION_CITY",
    minWidth: 300,
    maxWidth: 300,
    sortable: true,
  },
  STATION_TYPE: {
    id: "location.type",
    label: "STATION_TYPE",
    minWidth: 200,
    maxWidth: 200,
    sortable: true,
  },
  NUMBER_OF_GAMES: {
    id: "numberOfQuestions",
    label: "NUMBER_OF_QUESTIONS",
    minWidth: 170,
    maxWidth: 200,
    sortable: true,
  },
  CREATOR_NAME: {
    id: "creator.fullName",
    label: "CREATOR_NAME",
    minWidth: 300,
    maxWidth: 300,
    sortable: true,
  },
};

export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);

  export const MenuOptions: ListItem[] = [
    {
      id: "1",
      name: "DUPLICATE",
      onClick: () => console.log("Download File"),
      icon: <img src={duplicate} alt="file" />,
    },
    {
      id: "2",
      name: "EDIT",
      onClick: () => console.log("Download Folder"),
      icon: <img src={edit} alt="folder" />,
    },
    {
      id: "3",
      name: "DELETE",
      onClick: () => console.log("Download Folder"),
      icon: <img src={trash} alt="controller" />,
    },
  ];
