import React, { useEffect } from "react";
import { Box } from "@mui/material";
import FreeTextCard from "./components/FreeTextCard";
import { MODES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectAnswer } from "app/containers/Questions/redux/selector";
import { FreeTextAnswer } from "app/containers/Questions/types";
import { actions } from "app/containers/Questions/redux/slice";

const Data: FreeTextAnswer[] = [
  {
    _id: "",
    letters: [
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
    ],
  },
  {
    _id: "",
    letters: [
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
      {
        _id: "",
        isVisible: false,
        isDelete: false,
        option1: "",
        option2: "",
      },
    ],
  },
];

const FreeText = ({ disabled, mode }: { disabled?: boolean; mode: MODES }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (mode == MODES.NEW) {
      dispatch(actions.setAnswer(Data));
    }
    return () => {};
  }, [mode]);

  const answers = useSelector(selectAnswer) as FreeTextAnswer[];
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "40px",
      }}
    >
      {answers.map((item, index) => {        
        return <FreeTextCard key={index} dataIndex={index} data={item.letters} disabled={disabled} />;
      })}
    </Box>
  );
};

export default FreeText;
