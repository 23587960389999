import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./components/Form";
import QuestionTypes from "./components/QuestionTypes";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import BreadCrumb from "app/components/BreadCrumb";
import ActionButtons from "app/components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectForm,
  selectIsLoading,
  selectIsUnsaved,
} from "../../redux/selector";
import { actions } from "../../redux/slice";
import { validateQuestion } from "./validator";
import { selectOrganization } from "app/redux/selectors";
import ConfirmDialog from "app/components/ConfirmDialog";
import { useTranslation } from "react-i18next";
import Option from "app/components/ConfirmDialog/Component/Option";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const organization = useSelector(selectOrganization);
  const isUnsaved = useSelector(selectIsUnsaved);
  const loading = useSelector(selectIsLoading);

  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [saveOption, setSaveOption] = useState(0);
  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    if (mode == MODES.DUPLICATE) {
      dispatch(actions.setFormClearId());
    }
    dispatch(actions.getQuestion(id));
  }, [mode, id, dispatch]);

  useEffect(() => {
    dispatch(
      actions.setFormValues({
        key: "organizationId",
        value: organization?.value,
      })
    );
    return () => {};
  }, [organization]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm());
    };
  }, []);

  const onSubmit = () => {
    const errors = validateQuestion(form);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.createQuestion({
        callback: () => navigate("/app/questions"),
      })
    );
  };

  const onUpdate = () => {
    const errors = validateQuestion(form);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.updateQuestion({
        callback: () => navigate("/app/questions"),
      })
    );
  };
  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      if (isUnsaved) {
        setShowSaveConfirm(true);
      } else {
        onUpdate();
      }

      return;
    }
    navigate(`/app/questions/${id}/edit`);
  };

  const handleCancelBtn = () => {
    if (mode == MODES.EDIT) {
      if (isUnsaved) {
        setShowConfirm(true);
        return;
      }
    } else if (mode == MODES.DUPLICATE) {
      setShowConfirm(true);
      return;
    }
    navigate("/app/questions");
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    navigate("/app/questions");
  };
  const handelSaveConfirm = () => {
    if (saveOption == 0) {
      onUpdate();
    } else {
      onSubmit();
    }
    setShowSaveConfirm(false);
    setSaveOption(0);
  };
  return (
    <>
      <BreadCrumb
        title={generatePageTitle(Modules.QUESTIONS, mode)}
        onBackClick={handleCancelBtn}
      />

      {/**Question Details Form */}
      <Form mode={mode} />
      <div style={{ height: "32px" }} />
      {/**Questions Table */}
      <QuestionTypes mode={mode} />
      <ActionButtons
        mode={mode}
        handleCancelBtn={handleCancelBtn}
        handleConfirmBtn={handleConfirmBtn}
        isLoading={loading}
      />
      <ConfirmDialog
        open={showConfirm}
        handleClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.QUESTION"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
      <ConfirmDialog
        open={showSaveConfirm}
        handleClose={() => {
          setShowSaveConfirm(false);
          setSaveOption(0);
        }}
        onConfirm={handelSaveConfirm}
        title={t("COMMON.SAVED_CHANGES.TITLE", { name: t("EDITORS.EDITOR") })}
        message={t("COMMON.SAVED_CHANGES.MESSAGE", {
          name: t("EDITORS.EDITOR"),
        })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.SAVE")}
      >
        <Option
          disabled={false}
          value={saveOption}
          onChange={(e) => {
            setSaveOption(e);
          }}
        />
      </ConfirmDialog>
    </>
  );
};

export default Details;
