import { Box } from '@mui/material'
import InputField from 'app/components/InputField'
import { selectCustomizeTicketQuestion } from 'app/containers/Tickets/redux/selector';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "app/containers/Tickets/redux/slice";
import { useTranslation } from 'react-i18next';
export default function QuestionBody() {
    const question = useSelector(selectCustomizeTicketQuestion);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                marginY: "16px",
            }}
        >
            <InputField 
                label={t("TICKETS.CUSTOMIZED_TICKETS.QUESTION_BODY")}
                value={question?.body}
                sx={{
                    width: "100%",
                }}
                onChange={(e)=>{
                    dispatch(
                        actions.setCustomizeQuestionValues({
                          key: "body",
                          value: e.target.value,
                        })
                      )
                }}
                borderRadius="8px"
                borderColor="#FFFFFF"
                backgroundColor='#F3F6F8'
                labelStyle={{
                    fontFamily: "Rubik",
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    color: "#8890A6"
                }}          
            />
        </Box>
    )
}
