import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL, translate } from 'utils/constants';
import { Res, request } from 'utils/request';
import { actions } from './slice';
import { Game, GameResponse, GameSettings } from '../types';
import { removeBlankProperties } from 'utils/helpers';
import { showServerError } from 'styles/theme/utils';
import { Query } from 'app/containers/types';
import toastService from 'utils/toast';
import { selectPagination } from './selector';
import { StationsResponse } from 'app/containers/Stations/types';
import { actions as authAction } from 'app/redux/slice';
import { selectOrganization } from 'app/redux/selectors';

export function* getGameRequest({payload}: { payload: string }) { 
  yield delay(500);
  try {
      const id = payload;
      const {data, err, status}: Res<{ data: Game}> = yield call(request, `${API_URL}/v1/admin/game/get/${id}`);
      if(status === 401){
        const refreshToken = localStorage.getItem("refreshToken");
        yield put(authAction.refreshToken(refreshToken??""));
        return;
      }
        if(err || !data){
            throw err;
        }
      yield put(actions.getGameSuccess(data?.data));
  } catch (error:any) {
      yield put(actions.getGameError());
      showServerError(error)
  }
}

export function* getGamesRequest({payload}: { payload: Query }) { 
  yield delay(500);
  try {
      const query = new URLSearchParams(removeBlankProperties(payload)).toString();
      const {data, status, err}: Res<GameResponse> = yield call(request, `${API_URL}/v1/admin/game/list?${query}`);
      if(status === 401){
        const refreshToken = localStorage.getItem("refreshToken");
        yield put(authAction.refreshToken(refreshToken??""));
        return;
      }
        if(err || !data){
            throw err;
        }
      yield put(actions.getGamesSuccess({
          games: data?.data,
          pagination: data?._metadata.pagination
      }));
  } catch (error:any) {
      yield put(actions.getStationsError());
      showServerError(error)
  }
}

export function* createGameRequest({payload}: { payload: {game: Game; callback?: ()=>void;}}) {
  yield delay(500);
  try {
      const {err, status}: Res<{}> = yield call(request, `${API_URL}/v1/admin/game/create`, {
          method: 'POST',
          body: JSON.stringify({
            name: payload?.game?.name,
            organizationId: payload?.game?.organization,
            backgroundId: payload?.game?.background,
            gameBannerId: payload?.game?.gameBanner,
            groupInstructionText: payload?.game?.groupInstructionText,
            groupInstructionPDF: payload?.game?.groupInstructionPDF,
            startGameText: payload?.game?.startGameText,
            startGameAssetId: payload?.game?.startGameAsset,
            endGameText: payload?.game?.endGameText,
            endGameAssetId: payload?.game?.endGameAsset,
            welcomeScreenSettings: {
                showStartPoint: payload?.game?.welcomeScreenSettings.showStartPoint,
                showEndPoint: payload?.game?.welcomeScreenSettings.showEndPoint
            },
            courseSettings: {
                course: payload?.game?.courseSettings.course,
                startIsIdentical: payload?.game?.courseSettings.startIsIdentical,
                endIsIdentical: payload?.game?.courseSettings.endIsIdentical
            },
            gameStations: payload?.game?.gameStations,
            gameSettings: payload?.game?.gameSettings,
            isActive: payload?.game?.isActive,
          })
      });
      if(status === 401){
        const refreshToken = localStorage.getItem("refreshToken");
        yield put(authAction.refreshToken(refreshToken??""));
        return;
      }
        if(err){
            throw err;
        }   
      yield put(actions.createGameSuccess());
      toastService.success(translate("GAMES.SUCCESSFULLY_CREATED"));
      payload?.callback?.();
  } catch (error:any) {
      yield put(actions.createGameError());
      showServerError(error)
  }
}

export function* updateGameRequest({payload}: { payload: {game: Game; callback?: ()=>void;}}) {
  yield delay(500);
  try {
      const {data, status, err}: Res<{ data: Game }> = yield call(request, `${API_URL}/v1/admin/game/update/${payload.game?._id}`, {
          method: 'PUT',
          body: JSON.stringify({
            name: payload?.game?.name,
            organizationId: payload?.game?.organization,
            backgroundId: payload?.game?.background,
            gameBannerId: payload?.game?.gameBanner,
            groupInstructionText: payload?.game?.groupInstructionText,
            groupInstructionPDF: payload?.game?.groupInstructionPDF,
            startGameText: payload?.game?.startGameText,
            startGameAssetId: payload?.game?.startGameAsset,
            endGameText: payload?.game?.endGameText,
            endGameAssetId: payload?.game?.endGameAsset,
            welcomeScreenSettings: {
                showStartPoint: payload?.game?.welcomeScreenSettings.showStartPoint,
                showEndPoint: payload?.game?.welcomeScreenSettings.showEndPoint
            },
            courseSettings: {
                course: payload?.game?.courseSettings.course,
                startIsIdentical: payload?.game?.courseSettings.startIsIdentical,
                endIsIdentical: payload?.game?.courseSettings.endIsIdentical
            },
            gameStations: payload?.game?.gameStations,
            gameSettings: payload?.game?.gameSettings,
            isActive: payload?.game?.isActive,
          })
      });
      if(status === 401){
        const refreshToken = localStorage.getItem("refreshToken");
        yield put(authAction.refreshToken(refreshToken??""));
        return;
      }
        if(err || !data){
            throw err;
        }
      yield put(actions.getGame(data?.data?._id as string));
      toastService.success(translate("GAMES.SUCCESSFULLY_UPDATED"));
      payload?.callback?.();
  } catch (error:any) {
      yield put(actions.updateGameError());
      showServerError(error)
  }
}
export function* deleteGameRequest({payload}: { payload: string }) {
  yield delay(500);
  try {
      const {status, err}: Res<{}> = yield call(request, `${API_URL}/v1/admin/game/delete/${payload}`, {
          method: 'DELETE',
      });
      if(status === 401){
        const refreshToken = localStorage.getItem("refreshToken");
        yield put(authAction.refreshToken(refreshToken??""));
        return;
      }
        if(err){
            throw err;
        }
      yield put(actions.deleteGameSuccess());
      const pagination = yield select(selectPagination);
      const organization = yield select(selectOrganization);
      toastService.success(translate("GAMES.SUCCESSFULLY_DELETED"));
      yield put(actions.getGames({
          perPage: pagination.perPage,
          page: pagination.page,
          search: pagination.search,
          isActive: pagination.filters?.isActive,
          orderBy: pagination.orderBy,
          orderDirection: pagination.orderDirection,
          organization: organization?.value
      }));
  } catch (error:any) {
      yield put(actions.deleteGameError());
      showServerError(error)
  }
}

export function* getStationsRequest({payload}:{payload: Query}){ 
  yield delay(500);
  try {
      const query = new URLSearchParams(removeBlankProperties(payload)).toString();
      const {data, err, status}: Res<StationsResponse> = yield call(request, `${API_URL}/v1/admin/station/list?${query}`);
      if(status === 401){
        const refreshToken = localStorage.getItem("refreshToken");
        yield put(authAction.refreshToken(refreshToken??""));
        return;
      }
        if(err || !data){
            throw err;
        }
      yield put(actions.getStationsSuccess({
          stations: data?.data,
          pagination: data?._metadata.pagination
      }));
  } catch (error:any) {
      yield put(actions.getStationsError());        
      showServerError(error)
  }
}

export function* saveGameSettingsRequest({payload}: { payload: {gameId: string; settings: GameSettings; callback?: ()=>void;}}) {
  yield delay(500);
  try {
      const {data, status, err}: Res<{ data: Game }> = yield call(request, `${API_URL}/v1/admin/game/update-setting/${payload.gameId}`, {
        method: 'PUT',
        body: JSON.stringify({
          gameSettings: payload.settings,
        })
      });
      if(status === 401){
        const refreshToken = localStorage.getItem("refreshToken");
        yield put(authAction.refreshToken(refreshToken??""));
        return;
      }
      if(err || !data){
        throw err;
      }
      toastService.success(translate("GAMES.SUCCESSFULLY_UPDATED"));
      payload?.callback?.();
  } catch (error:any) {
    yield put(actions.updateGameError());
    showServerError(error)
  }
  yield put(actions.saveGameSettingsComplete());
}

export function* gamesSaga() {
  yield takeLatest(actions.getGame, getGameRequest);
  yield takeLatest(actions.getGames, getGamesRequest);
  yield takeLatest(actions.createGame, createGameRequest);
  yield takeLatest(actions.updateGame, updateGameRequest);
  yield takeLatest(actions.deleteGame, deleteGameRequest);
  yield takeLatest(actions.getStations, getStationsRequest);
  yield takeLatest(actions.saveGameSettings, saveGameSettingsRequest);
}
