import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import ActionButtons from "app/components/ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/slice";
import {
  selectGame,
  selectIsLoading,
  selectIsUnsaved,
  selectSelectedStations,
} from "../../redux/selector";
import toastService from "utils/toast";
import { useTranslation } from "react-i18next";
import { validateGame } from "./validator";
import { selectOrganization } from "app/redux/selectors";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import { Box } from "@mui/material";
import Settings from "./components/Settings";
import SettingsIcon from "@mui/icons-material/Settings";
import ConfirmDialog from "app/components/ConfirmDialog";
import Option from "app/components/ConfirmDialog/Component/Option";

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isUnsaved = useSelector(selectIsUnsaved);
  const { id } = useParams();
  const isLoading = useSelector(selectIsLoading);
  const selectedStations = useSelector(selectSelectedStations);
  const game = useSelector(selectGame);
  const organization = useSelector(selectOrganization);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [saveOption, setSaveOption] = useState(0);
  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    dispatch(actions.getGame(id));
  }, [mode, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm());
    };
  }, [dispatch]);

  const styles = useStyles();
  const navigate = useNavigate();

  const handleCancelBtn = () => {
    if (mode == MODES.EDIT) {
      if (isUnsaved) {
        setShowConfirm(true);
        return;
      }
    } else if (mode == MODES.DUPLICATE) {
      setShowConfirm(true);
      return;
    }
    navigate("/app/games");
  };

  const onSubmit = () => {
    const errors = validateGame(game);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      toastService.error(t("GAMES.ERRORS.FORM_HAS_ERRORS"));
      return;
    }
    if (selectedStations?.length < 1) {
      toastService.error(t("GAMES.ERRORS.PLEASE_SELECT_AT_LEAST_ONE_STATION"));
      return;
    }
    dispatch(
      actions.createGame({
        game: {
          name: game.name.value,
          background: game.backgroundId.value,
          gameBanner: game.gameBannerId.value,
          groupInstructionText: game.groupInstructionText.value,
          groupInstructionPDF: game.groupInstructionPDFId.value,
          startGameText: game.startGameText.value,
          endGameText: game.endGameText.value,
          endGameAsset: game.endGameAssetId.value,
          startGameAsset: game.startGameAssetId.value,
          welcomeScreenSettings: {
            showStartPoint: game.showStartPoint.value === "true" ? true : false,
            showEndPoint: game.showEndPoint.value === "true" ? true : false,
          },
          courseSettings: {
            course: game.course.value,
            startIsIdentical: game.startIsIdentical.value,
            endIsIdentical: game.endIsIdentical.value,
          },
          gameStations: selectedStations?.map((gS, i) => ({
            _id: gS.id,
            station: gS.station._id,
            group: gS.number,
            sortingOrder: i,
          })),
          gameSettings: {
            canSkip: game?.canSkip?.value,
            canSkipAfter: game?.canSkipAfter?.value,
            numberOfMistakesTillReveal: game?.numberOfMistakesTillReveal?.value,
            delayAfterMistake: game?.delayAfterMistake?.value,
            numberOfMistakesTillDelay: game?.numberOfMistakesTillDelay?.value,
          },
          isActive: game.isActive.value,
          organization: organization?.value ?? "",
        },
        callback: () => navigate("/app/games"),
      })
    );
  };

  const onUpdate = () => {
    const errors = validateGame(game);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      toastService.error(t("GAMES.ERRORS.FORM_HAS_ERRORS"));
      return;
    }
    if (selectedStations?.length < 1) {
      toastService.error(t("GAMES.ERRORS.PLEASE_SELECT_AT_LEAST_ONE_STATION"));
      return;
    }
    dispatch(
      actions.updateGame({
        game: {
          _id: game._id.value,
          name: game.name.value,
          background: game.backgroundId.value,
          gameBanner: game.gameBannerId.value,
          groupInstructionText: game.groupInstructionText.value,
          groupInstructionPDF: game.groupInstructionPDFId.value,
          startGameText: game.startGameText.value,
          endGameText: game.endGameText.value,
          endGameAsset: game.endGameAssetId.value,
          startGameAsset: game.startGameAssetId.value,
          welcomeScreenSettings: {
            showStartPoint: game.showStartPoint.value === "true" ? true : false,
            showEndPoint: game.showEndPoint.value === "true" ? true : false,
          },
          courseSettings: {
            course: game.course.value,
            startIsIdentical: game.startIsIdentical.value,
            endIsIdentical: game.endIsIdentical.value,
          },
          gameStations: selectedStations?.map((gS, i) => ({
            _id: gS.id,
            station: gS.station._id,
            group: gS.number,
            sortingOrder: i,
          })),
          gameSettings: {
            canSkip: game?.canSkip?.value,
            canSkipAfter: game?.canSkipAfter?.value,
            numberOfMistakesTillReveal: game?.numberOfMistakesTillReveal?.value,
            delayAfterMistake: game?.delayAfterMistake?.value,
            numberOfMistakesTillDelay: game?.numberOfMistakesTillDelay?.value,
          },
          isActive: game.isActive.value,
          organization: organization?.value ?? "",
        },
        callback: () => navigate("/app/games"),
      })
    );
  };

  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      if (isUnsaved) {
        setShowSaveConfirm(true);
      } else {
        onUpdate();
      }
      return;
    }
    navigate(`/app/games/${id}/edit`);
  };
  const handelSaveConfirm = () => {
    if (saveOption == 0) {
      onUpdate();
    } else {
      onSubmit();
    }
    setShowSaveConfirm(false);
    setSaveOption(0);
  };
  const handelConfirm = () => {
    setShowConfirm(false);
    navigate("/app/games");
  };
  return (
    <>
      <Box className={styles.breadCrumbSectionParent}>
        <BreadCrumb
          title={generatePageTitle(Modules.GAMES, mode)}
          onBackClick={handleCancelBtn}
        />

        {mode !== MODES.NEW && (
          <PrimaryButton
            label={t("GAMES.SETTINGS.LABEL")}
            onClick={() => setOpenSettings(true)}
            sx={{
              width: "119px",
            }}
            startIcon={<SettingsIcon />}
          />
        )}
      </Box>
      {/**Game Details Form */}
      <Form mode={mode} />
      <div style={{ height: "20px" }} />
      <ActionButtons
        handleCancelBtn={handleCancelBtn}
        handleConfirmBtn={handleConfirmBtn}
        mode={mode}
        isLoading={isLoading}
      />
      <Settings
        open={openSettings}
        handleClose={() => setOpenSettings(false)}
      />
      <ConfirmDialog
        open={showConfirm}
        handleClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.GAME"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
      <ConfirmDialog
        open={showSaveConfirm}
        handleClose={() => {
          setShowSaveConfirm(false);
          setSaveOption(0);
        }}
        onConfirm={handelSaveConfirm}
        title={t("COMMON.SAVED_CHANGES.TITLE", { name: t("EDITORS.EDITOR") })}
        message={t("COMMON.SAVED_CHANGES.MESSAGE", {
          name: t("EDITORS.EDITOR"),
        })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.SAVE")}
      >
        <Option
          disabled={false}
          value={saveOption}
          onChange={(e) => {
            setSaveOption(e);
          }}
        />
      </ConfirmDialog>
    </>
  );
};

export default Details;
