import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Organization } from "app/containers/Organizations/types";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingOrganizations,
  selectOrganization,
  selectOrganizations,
  selectUser,
} from "app/redux/selectors";
import { actions } from "app/redux/slice";
import { Option, RoleTypes } from "app/types";
import { selectDirection } from "styles/theme/slice";

export default function OrganizationSelect() {
  const [open, setOpen] = React.useState(false);
  const organizations = useSelector(selectOrganizations);
  const isLoading = useSelector(selectIsLoadingOrganizations);
  const organization = useSelector(selectOrganization);
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!organization && organizations?.length > 0) {
      dispatch(
        actions.setOrganization({
          label: organizations[0].name,
          value: organizations[0]._id as string,
        })
      );
    }
  }, [organizations, organization, dispatch]);

  useEffect(() => {
    dispatch(actions.getOrganizations({}));
  }, []);
  useEffect(() => {
   
    if (user && user.type == RoleTypes.EDITOR && organizations.length > 0) {
      const org = organizations.filter(
        (x) => x._id == user.organization?._id
      )[0];
      if (org) {
        dispatch(
            actions.setOrganization({
              label: org.name,
              value: org._id as string,
            })
          );
      }
    }

    return () => {};
  }, [user, organizations]);

  return (
    <Autocomplete
      sx={{
        width: "223px",
        fontFamily: direction === "ltr" ? "Inter" : "Rubik",
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue) => {
        dispatch(actions.setOrganization(newValue as Option));
      }}
      disabled={user?.type !== RoleTypes.ADMIN}
      value={organization}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={
        organizations?.map((organization: Organization) => ({
          label: organization.name,
          value: organization._id,
        })) || []
      }
      loading={isLoading}
      loadingText={
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          {t("COMMON.TEXT.LOADING")}
        </Typography>
      }
      noOptionsText={
        <Typography
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          {t("COMMON.TEXT.NO_OPTIONS")}
        </Typography>
      }
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography
            sx={{
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "16px",
              textAlign: "left",
              color: "#5E6781",
              py: "4px",
            }}
          >
            {option.label}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            fontSize: "14px !important",
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              margin: 0,
              background: "#FFFFFF",
              height: "36px",
              "& fieldset": {
                border: "none", // Remove the border
                fontSize: "14px !important",
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
              },
              padding: "0px 10px",
            },
            "& .MuiSelect-select": {
              fontSize: "14px !important",
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            },
            input: {
              "&::placeholder": {
                fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                fontSize: "14px !important",
              },
              fontSize: "14px !important",
              padding: "0px",
              fontFamily: direction === "ltr" ? "Inter" : "Rubik",
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
