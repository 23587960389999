import { Checkbox, TableSortLabel, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ClassNameMap } from "@mui/styles/withStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OrderByTypes, TableHeaderColumnType } from "../Table/types";
import { useSelector } from "react-redux";
import { selectDirection } from "styles/theme/slice";
import { StyledCell } from "../Table";
import { LEFT_SPACING } from "utils/constants";

interface TableHeaderProps {
  columnValues: TableHeaderColumnType[];
  classes?: ClassNameMap;
  orderBy?: {
    name: string;
    direction: OrderByTypes;
  };
  onRequestSort?: (value: { name: string; direction: OrderByTypes }) => void;
  showSelect?: boolean;
  selectAllHandler?: () => void;
  isAllSelected?: boolean;
  alignShowSelect?: "left" | "center" | "right";
  isColoredHeader?: boolean;
}

export const StyledHeader = styled("div")({
  paddingLeft: LEFT_SPACING,
  display: "flex",
  width: "100%",
  borderBottom: "1px solid #C4C4C4",
  position: "sticky",
  top: 0,
  zIndex: 5,
  backgroundColor: "#FFFFFF",
});

export const TableHeader = ({
  columnValues,
  classes,
  orderBy,
  showSelect,
  selectAllHandler,
  isAllSelected,
  isColoredHeader,
  alignShowSelect = "left",
}: TableHeaderProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const pathname = window.location.pathname;

  const direction = useSelector(selectDirection);

  const createSortHandler = (direction: OrderByTypes, property: string) => {
    searchParams.set("orderBy", property);
    searchParams.set("direction", direction);
    navigate(`${pathname}?${searchParams.toString()}`);
  };

  const generateBorderRadius = (index: number) => {
    if (index === 0 && !showSelect && theme.direction === "ltr") {
      return {
        borderTopLeftRadius: "4px",
      };
    }
    if (index === columnValues.length - 1 && theme.direction === "ltr") {
      return {
        borderTopRightRadius: "4px",
      };
    }
    if (
      index === columnValues.length - 1 &&
      !showSelect &&
      theme.direction === "rtl"
    ) {
      return {
        borderTopLeftRadius: "4px",
      };
    }
    if (
      index === columnValues.length - 1 &&
      showSelect &&
      theme.direction === "rtl"
    ) {
      return {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px !important",
      };
    }
    if (index === 0 && !showSelect && theme.direction === "rtl") {
      return {
        borderTopRightRadius: "4px",
      };
    }
    return {};
  };

  return (
    <StyledHeader className={isColoredHeader ? "colored" : ""}>
      {showSelect && (
        <StyledCell
          style={{
            minWidth: 97,
            borderTopLeftRadius: direction === "ltr" ? "4px" : 0,
            borderTopRightRadius: direction === "rtl" ? "4px" : 0,
          }}
        >
          <Checkbox
            sx={{
              padding: 0,
              color: "#5E6781",
              "&.Mui-checked": {
                color: "#5E6781",
              },
              "& .MuiSvgIcon-root": {
                borderRadius: "4px",
              },
            }}
            onChange={selectAllHandler}
            checked={isAllSelected}
          />
        </StyledCell>
      )}
      {columnValues.map((headCell, index) => (
        <StyledCell
          key={headCell.id + "_" + index}
          style={{
            minWidth: headCell.minWidth,
            maxWidth: headCell.maxWidth ? headCell.maxWidth : "auto",
            ...(headCell.centeredHeader && {
              textAlign: "center",
            }),
            color: "#5E6781",
            direction: theme.direction,
            paddingLeft: headCell.paddingLeft ? headCell.paddingLeft : 16,
            ...generateBorderRadius(index),
          }}
          onClick={() => {
            if (!headCell?.id || !headCell.sortable) return;
            createSortHandler(
              searchParams.get("direction") === "ASC" ? "DESC" : "ASC",
              headCell?.id
            );
          }}
        >
          {headCell.sortable ? (
            <TableSortLabel
              active={false}
              sx={{
                color: isColoredHeader
                  ? "#FFFFFF !important"
                  : "#5E6781 !important",
                  fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                  fontSize: "14px",
                lineHeight: "18px",
                letterSpacing: "0em",
                textAlign: "left",
                "& .MuiTableSortLabel-icon": {
                  color: "#5E6781 !important",
                  fontWeight: "bold",
                },
              }}
              direction={
                searchParams.get("direction")?.toLowerCase() as "asc" | "desc"
              }
              hideSortIcon={false}
            >
              {t(`TABLES.${headCell.label}`)}
            </TableSortLabel>
          ) : (
            <Typography
              sx={{
                color: isColoredHeader
                  ? "#FFFFFF !important"
                  : "#5E6781 !important",
                  fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                  fontSize: "14px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: "0em",
                textAlign: "left",
                "& .MuiTableSortLabel-icon": {
                  color: "#5E6781 !important",
                  fontWeight: "bold",
                },
              }}
            >
              {" "}
              {t(`TABLES.${headCell.label}`)}
            </Typography>
          )}
        </StyledCell>
      ))}
    </StyledHeader>
  );
};
