import { ThemeKeyType } from "./types";
import toastService from "utils/toast";
import { translate } from "utils/constants";
import dayjs from "dayjs";
import parsePhoneNumber from "libphonenumber-js";
import { DateTime } from "luxon";
/* istanbul ignore next line */
export const isSystemDark = window?.matchMedia
  ? window.matchMedia("(prefers-color-scheme: dark)")?.matches
  : undefined;

export function saveTheme(theme: ThemeKeyType) {
  window.localStorage && localStorage.setItem("selectedTheme", theme);
}

/* istanbul ignore next line */
export function getThemeFromStorage(): ThemeKeyType | null {
  return window.localStorage
    ? (localStorage.getItem("selectedTheme") as ThemeKeyType) || null
    : null;
}

export function scrollBottom(element) {
  element && element.scroll({ top: element.scrollHeight, behavior: "smooth" });
}

export function safeParseJSON(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
}
export function validateAndFormatIsraeliMobileNumber(number = "") {
  // Remove any non-numeric characters from the number
  if (!number) {
    number = "";
  }

  if (number.startsWith("+")) {
    var numericNumber = number.replace(/\+\D/g, "").replace("-","");
    console.log("numericNumber", numericNumber);
    // Check if the number starts with "05" and has 9 digits
    if (/^\+972\d{9}$/.test(numericNumber)) {
      // Format the number as "+972-500000023"
      var formattedNumber = numericNumber.replace(/(\+\d{3})(\d{9})/, "$1-$2");
      console.log("formattedNumber1", formattedNumber);
      return {
        isValid: true,
        formattedNumber: formattedNumber,
        numericNumber,
      };
    } else {
      return {
        isValid: false,
        formattedNumber: numericNumber,
        numericNumber,
      };
    }
  } else if (number.startsWith("9")) {
    var numericNumber = number.replace(/\D/g, "").replace("-","");;
    console.log("numericNumber", numericNumber);
    // Check if the number starts with "05" and has 9 digits
    if (/^972\d{9}$/.test(numericNumber)) {
      // Format the number as "+972-500000023"
      var formattedNumber = numericNumber.replace(/(\d{3})(\d{9})/, "$1-$2");
      console.log("formattedNumber1", formattedNumber);
      return {
        isValid: true,
        formattedNumber: formattedNumber,
        numericNumber,
      };
    } else {
      return {
        isValid: false,
        formattedNumber: numericNumber,
        numericNumber,
      };
    }
  } else if (number.startsWith("0")) {
    var numericNumber = number.replace(/\D/g, "").replace("-","");;
    console.log("numericNumber", numericNumber);

    // Check if the number starts with "05" and has 9 digits
    if (/^05\d{8}$/.test(numericNumber)) {
      // Format the number as "050-0000023"
      var formattedNumber = numericNumber.replace(/(\d{3})(\d{7})/, "$1-$2");
      console.log("formattedNumber", formattedNumber);
      return {
        isValid: true,
        formattedNumber: formattedNumber,
        numericNumber,
      };
    } else {
      return {
        isValid: false,
        formattedNumber: numericNumber,
        numericNumber,
      };
    }
  } else {
    const phoneNumber = parsePhoneNumber(number, "IL");
    console.log("phoneNumber?.isValid()", phoneNumber?.isValid());
    return {
      isValid: phoneNumber?.isValid(),
      formattedNumber: number,
      number,
    };
  }
}
export function prepareErrorMessage(error) {
  if (error?.errors?.length) {
    const e = error?.errors?.[0];
    if (e?.message) {
      return `${error.message}: ${e.message}`;
    }
  }
  return `${error.message}`;
}

export function showServerError(error) {
  const errorMessage = safeParseJSON(error?.message);
  toastService.error(
    prepareErrorMessage(errorMessage) || translate("COMMON.ERRORS.MESSAGE")
  );
}

export function formatUUID(uuid) {
  return uuid.split("-")?.[0] || "-";
}

export function formatTableDateTime(dt) {
  if (!dt) {
    return "-";
  }
  return dayjs(dt).format("DD/MM/YYYY, HH:mm");
}

export function formatTableTime(dt) {
  if (!dt) {
    return "-";
  }
  return dayjs(dt).format("HH:mm");
}

export function formatTableDate(dt) {
  if (!dt) {
    return "-";
  }
  return dayjs(dt).format("DD/MM/YYYY");
}

export function formatHumanizedDuration(startDate, endDate ) {
  // Parse the startDate and endDate
  const start = DateTime.fromISO(startDate);
  const end = endDate ? DateTime.fromISO(endDate) : DateTime.now();

  // Calculate the duration between the two dates
  const diff = end.diff(start);

  // Extract days, hours, and minutes from the duration
  const days = diff.as('days');
  const hours = diff.as('hours') % 24;
  const minutes = diff.as('minutes') % 60;

  // Construct the human-readable format
  let result = '';
  if (Math.floor(days) > 0) result += `${Math.floor(days)} day${Math.floor(days) > 1 ? 's' : ''} `;
  if (Math.floor(hours) > 0 || Math.floor(days) > 0) result += `${Math.floor(hours)}h `;
  if (Math.floor(minutes) > 0 || Math.floor(hours) > 0 || Math.floor(days) > 0) result += `${Math.floor(minutes)}min`;

  return result.trim();
}
