import {
  FormElement,
  Metadata,
  OrderDirections,
  Pagination,
} from "app/containers/types";
import { City } from "app/components/CitySelect/types";
import { User } from "app/types";
import { randomString } from "utils/helpers";

export type StationForm = {
  id: FormElement;
  name: FormElement;
  city: FormElement;
  goToMessage1: FormElement;
  goToMessage2: FormElement;
  feedBackOnSuccessfulArrival: FormElement;
  stationInstruction: FormElement;
  type: FormElement;
  address: FormElement;
  code: FormElement;
  radius: FormElement;
  coordinates: FormElement;
  stationImageId: FormElement;
  stationImageUrl: FormElement;
  isAddressVisible: FormElement;
  qrCode: FormElement;
  qrText: FormElement;
};

export type StationsState = {
  form: StationForm;
  isLoading: boolean;
  stations: Station[];
  hasMore: boolean;
  questions: Question[];
  selectedQuestions: string[];
  stationsPagination: Pagination;
  questionsPagination: Pagination;
  isLoadingQuestions: boolean;
  isUnsaved: boolean;
};

export enum StationTypes {
  QR = "QR",
  POINT = "Point",
  CODE = "Code",
}

export const StationsState: StationsState = {
  form: {
    id: {
      value: "",
      error: "",
    },
    name: {
      value: "",
      error: "",
    },
    city: {
      value: "",
      error: "",
    },
    goToMessage1: {
      value: "",
      error: "",
    },
    goToMessage2: {
      value: "",
      error: "",
    },
    feedBackOnSuccessfulArrival: {
      value: "",
      error: "",
    },
    stationInstruction: {
      value: "",
      error: "",
    },
    type: {
      value: StationTypes.POINT,
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    code: {
      value: "",
      error: "",
    },
    radius: {
      value: "",
      error: "",
    },
    coordinates: {
      value: "",
      error: "",
    },
    stationImageId: {
      value: "",
      error: "",
    },
    stationImageUrl: {
      value: "",
      error: "",
    },
    isAddressVisible: {
      value: true,
      error: "",
    },
    qrCode: {
      value: "",
      error: "",
    },
    qrText: {
      value: randomString(5),
      error: "",
    },
  },
  isLoading: false,
  hasMore: false,
  questions: [],
  selectedQuestions: [],
  stationsPagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 20,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  questionsPagination: {
    filters: {
      isActive: "true",
    },
    search: "",
    page: 1,
    perPage: 20,
    orderBy: "createdAt",
    orderDirection: OrderDirections.DESC,
    total: 0,
    totalPage: 0,
  },
  stations: [],
  isLoadingQuestions: false,
  isUnsaved: false,
};

export interface Location {
  coordinates?: number[];
  type: StationTypes;
  code?: string;
  radius?: number;
  address?: string;
  isAddressVisible?: boolean;
}

export interface Station {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  organization: string;
  name: string;
  goToMessage1: string;
  goToMessage2: string;
  feedBackOnSuccessfulArrival: string;
  stationInstruction: string;
  location: Location;
  stationQuestions: Question[] | string[];
  noOfQuestions?: number;
  city: City;
  stationImage: {
    _id: string;
    url: string;
  };
  creator?: Creator;
}

export interface Creator {
  _id: string;
  fullName: string;
  email: string;
}
export interface StationsResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: Station[];
}
export interface QuestionsResponse {
  statusCode: number;
  message: string;
  _metadata: Metadata;
  data: Question[];
}
export interface Question {
  _id: string;
  createdAt: string;
  updatedAt: string;
  organization: string;
  body: string;
  rightAnswerComment: string;
  score: number;
  showLink: boolean;
  user: User;
  link: string;
  type: string;
}

export type ContainerState = StationsState;
