import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import DirectionKeys from './components/DirectionKeys';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GroupSelect from './components/GroupSelect';
import { formatUUID } from 'styles/theme/utils';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/containers/Games/redux/slice'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectDirection } from 'styles/theme/slice';

interface StationLinkTileProps {
    readonly isEven?: boolean
    readonly disabled?: boolean
    readonly station:  {id:string; number:number; station: {_id: string, name: string}};
    readonly index: number
    readonly handleRemoveClick: () => void
}

export default function StationLinkTile({
    isEven,
    disabled,
    station,
    index,
    handleRemoveClick
}:StationLinkTileProps) {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState<number>(1)
    const {i18n} = useTranslation()

    const isHebrew = i18n?.language === 'hw'
  const direction = useSelector(selectDirection);

    return (
        <Box
            sx={{
                height: "52px",
                padding: "14px 10px",
                borderRadius: "16px",
                border: "1px solid #86BDE3",
                "&:nth-of-type(odd)": {
                    backgroundColor: "#F3F6F8",
                },
                "&:nth-of-type(even)": {
                    backgroundColor: "#ffffff",
                },
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
            }}
        >

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "30%",
                }}
            >
                <DirectionKeys 
                    index={index}
                    value={value}
                    disabled={disabled}
                    backgroundColor={isEven ? "#F3F6F8" : "#FFFFFF"}
                />
                <Box>
                <Link
                    target="_blank"
                        to={`/app/stations/${station?.station?._id}/view`}
                        style={{
                            fontFamily: direction === "ltr" ? "Inter" : "Rubik",
                            fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16px",
                        letterSpacing: "0em",
                        color: "#2F80ED",
                        cursor: 'pointer'
                        }}
                    >
                        { formatUUID(station?.station?._id) || 'N/A'}
                    </Link>
   
                </Box>
            </Box> 
            <Box
                sx={{
                    width: "50%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "100%",
                        color: "#3C3C43",
                    }}
                >
                    {station?.station?.name}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "20px",
                    width: "20%"
                }}
            >
                <GroupSelect 
                    value={station.number?station.number:1}
                    setValue={(e)=>{
                        dispatch(actions.setUpdateGroupNameSelectedStations({index:index,value:e}))
                    }}
                />
                <IconButton 
                    disabled={disabled}
                    sx={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#3C3C43",
                        "&:disabled": {
                            backgroundColor: "#D7D7E0",
                        },
                    }}
                    onClick={handleRemoveClick}
                >
                    {isHebrew ? (
                        <ArrowBackIosIcon 
                            sx={{
                                fontSize: "10px",
                                color: "#ffffff",
                            }}
                        />
                    ) : (
                        <ArrowForwardIosIcon 
                            sx={{
                                fontSize: "10px",
                                color: "#ffffff",
                            }}
                        />
                    )}
                </IconButton>    
            </Box> 
        </Box>
    )
}
