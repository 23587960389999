import { TableHeaderColumnType } from "app/components/Table/types";

export const TableHeaderDefinition = {
  RESULT_ID: {
    id: '_id',
    label: 'RESULT_ID',
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,
    sortable:true
  }, 
  GROUP_NAME: {
    id: 'groupName',
    label: 'GROUP_NAME',
    format: (value) => value,
    maxWidth: 250,
    minWidth: 250,
    sortable:true
  },
  GAME_NAME: {
    id: 'game.name',
    label: 'GAME_NAME',
    format: (value) => value,
    maxWidth: 250,
    minWidth: 250,
    sortable:true
  },
  TICKET_ID: {
    id: 'ticket',
    label: 'TICKET_ID',
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable:true
  },
  QUESTIONS_AMOUNT: {
    id: 'totalQuestions',
    label: 'QUESTIONS_AMOUNT',
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable:false
  },
  STATIONS_AMOUNT: {
    id: 'totalStations',
    label: 'STATIONS_AMOUNT',
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable:false
  },
  PLAYED_DATE: {
    id: 'playedDate',
    label: 'PLAYED_DATE',
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable:false
  },
  GAME_DURATION: {
    id: 'gameDuration',
    label: 'GAME_DURATION',
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable:false
  }
};

export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);