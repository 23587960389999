import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import CustomTable, { StyledCell } from "app/components/Table";
import { MenuOptions, TableHeaderDefinition, TableHeaders } from "./headers";
import MenuWrapper from "app/components/MenuWrapper";
import FloatingButton from "app/components/Buttons/FloatingButton";
import ConfirmDialog from "app/components/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectEditors,
  selectIsLoading,
  selectPagination,
} from "../../redux/selector";
import { modifyMenuOptions } from "utils/constants";
import { actions } from "../../redux/slice";
import { selectOrganization } from "app/redux/selectors";
import { formatTableDateTime, formatUUID } from "styles/theme/utils";
import CustomTableRow from "app/components/TableRow";
import { Typography } from "@mui/material";
import { OrderDirections } from "app/containers/types";

const List = () => {
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = React.useState({
    status: false,
    id: "",
  });
  const editors = useSelector(selectEditors);
  const pagination = useSelector(selectPagination);
  const isLoading = useSelector(selectIsLoading);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const q = searchParams?.[0]?.get("q");
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction");
  const organization = useSelector(selectOrganization);
  useEffect(() => {
    dispatch(
      actions.getEditors({
        page: 1,
        search: q ? q : undefined,
        organization: organization ? organization.value : undefined,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      })
    );
  }, [q,  dispatch, organization, orderBy, orderDirection]);

  const deleteEditor = () => {
    dispatch(actions.deleteEditor(confirmDelete.id));
    setConfirmDelete({
      status: false,
      id: "",
    });
  };

  const addNewUser = () => {
    navigate("/app/editors/new");
  };

  const onClickAction = (id: string, organizationId: string) => {
    switch (id) {
      case "1":
        navigate(`/app/editors/${organizationId}/edit`);
        break;
      case "2":
        setConfirmDelete({
          status: true,
          id: organizationId,
        });
        break;
      default:
        break;
    }
  };
  const hasMoreItems = pagination.page < pagination.total;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(actions.getEditors({
        page: pagination.page + 1,
        search: q ? q : undefined,
        organization: organization ? organization.value : undefined,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      }));
    }
  };

  return (
    <>
      <Header />
      <CustomTable
        headers={TableHeaders}
        loading={isLoading}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        dataLength={pagination.perPage}
      >
        {editors.map((editor) => (
          <CustomTableRow
            onDoubleClick={() => navigate(`/app/editors/${editor?._id}/view`)}
            key={editor._id}
            hoverOptionComponent={
              <MenuWrapper
                options={modifyMenuOptions(
                  MenuOptions,
                  editor?._id ?? "",
                  onClickAction
                )}
              />
            }
          >
            <StyledCell
                minWidth={TableHeaderDefinition.EDITOR_ID.minWidth}
                maxWidth={TableHeaderDefinition.EDITOR_ID.maxWidth}
            >

                <Typography
                  sx={{
                    color: "#2F80ED",
                    fontSize: "14px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/app/editors/${editor?._id}/view`)}
                >
                  {formatUUID(editor?._id)}
                </Typography>
              </StyledCell>
            <StyledCell
                minWidth={TableHeaderDefinition.CREATED_DATE.minWidth}
                maxWidth={TableHeaderDefinition.CREATED_DATE.maxWidth}
            >{formatTableDateTime(editor?.createdAt)}</StyledCell>
            <StyledCell
                minWidth={TableHeaderDefinition.LAST_LOGGED_IN.minWidth}
                maxWidth={TableHeaderDefinition.LAST_LOGGED_IN.maxWidth}
            >{formatTableDateTime(editor?.lastLoginDate)}</StyledCell>
            <StyledCell
                minWidth={TableHeaderDefinition.EDITOR_NAME.minWidth}
                maxWidth={TableHeaderDefinition.EDITOR_NAME.maxWidth}
            >{editor.fullName}</StyledCell>
            <StyledCell
                minWidth={TableHeaderDefinition.EMAIL.minWidth}
                maxWidth={TableHeaderDefinition.EMAIL.maxWidth}
            >{editor.email}</StyledCell>
            <StyledCell
                minWidth={TableHeaderDefinition.PHONE_NUMBER.minWidth}
                maxWidth={TableHeaderDefinition.PHONE_NUMBER.maxWidth}
            >{editor.mobileNumber}</StyledCell>
          </CustomTableRow>
        ))}
      </CustomTable>
      <FloatingButton label={t("EDITORS.EDITOR")} onClick={addNewUser} />

      <ConfirmDialog
        open={confirmDelete.status}
        handleClose={() =>
          setConfirmDelete({
            status: false,
            id: "",
          })
        }
        onConfirm={deleteEditor}
        title={t("COMMON.DELETE.TITLE", { name: t("EDITORS.EDITOR") })}
        message={t("COMMON.DELETE.MESSAGE", { name: t("EDITORS.EDITOR") })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.DELETE")}
      />
    </>
  );
};

export default List;
