import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL, translate } from 'utils/constants';
import { Res, request } from 'utils/request';
import { selectPagination } from './selector';
import { actions } from './slice';
import { Customer, CustomerResponse } from '../types';
import { removeBlankProperties } from 'utils/helpers';
import i18next from 'i18next';
import { Pagination, Query } from 'app/containers/types';
import toastService from 'utils/toast';
import { actions as authAction } from 'app/redux/slice';

export function* getCustomersRequest({ payload }: { payload: Query }) { 
    yield delay(500);
    try {
        const query = new URLSearchParams(removeBlankProperties(payload)).toString();
        const {data, err, status}: Res<CustomerResponse> = yield call(request, `${API_URL}/v1/admin/customer/list?${query}`);
        if(status === 401){
          const refreshToken = localStorage.getItem("refreshToken");
          yield put(authAction.refreshToken(refreshToken??""));
          return;
        }
        if(err || !data){
          throw err;
        }
        yield put(actions.getCustomersSuccess({
            customers: data.data,
            pagination: data._metadata.pagination
        }));
    } catch (error:any) {
        yield put(actions.getCustomersError());
        let errorMessage = error?.message;
        try {
          errorMessage = JSON.parse(errorMessage);
        } catch (e) {
        }
        toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    }
}

export function* getCustomerRequest({ payload }: { payload: string }) { 
    yield delay(500);
    try {
        const id = payload;
        const {data, err, status}: Res<{ data: Customer }> = yield call(request, `${API_URL}/v1/admin/customer/get/${id}`);
        if(status === 401){
          const refreshToken = localStorage.getItem("refreshToken");
          yield put(authAction.refreshToken(refreshToken??""));
          return;
        }
        if(err || !data){
          throw err;
        }
        yield put(actions.getCustomerSuccess(data?.data));
    } catch (error:any) {
        yield put(actions.getCustomerError());
        let errorMessage = error?.message;
        try {
          errorMessage = JSON.parse(errorMessage);
        } catch (e) {
        }
        toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    }
}

export function* updateCustomerRequest({ payload }: { payload: {customer: Customer; callback?: ()=>void;}}) {
    yield delay(500);
    try {
        const {data, err, status}: Res<{ data: Customer }> = yield call(request, `${API_URL}/v1/admin/customer/update/${payload?.customer?._id}`, {
            method: 'PUT',
            body: JSON.stringify({
                organizationId: payload?.customer?.organizationId,
                companyName: payload?.customer?.companyName,
                email: payload?.customer?.email,
                taxNumber: payload?.customer?.taxNumber,
                name: payload?.customer?.name,
                mobileNumber: payload?.customer?.mobileNumber,
                address: {
                    street: payload?.customer?.address || '',
                    city: payload?.customer?.city?.value || '',
                }
            }),
        });
        if(status === 401){
          const refreshToken = localStorage.getItem("refreshToken");
          yield put(authAction.refreshToken(refreshToken??""));
          return;
        }
        if(err || !data){
          throw err;
        }
        yield put(actions.updateCustomerSuccess(data?.data));
        toastService.success(i18next.t("CUSTOMERS.SUCCESSFULLY_UPDATED"));
        payload?.callback?.();
    } catch (error:any) {
        yield put(actions.updateCustomerError());
        let errorMessage = error?.message;
        try {
            errorMessage = JSON.parse(errorMessage);
        } catch (e) {
        }
        toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    }
}

export function* deleteCustomerRequest({ payload }: { payload: string }) {
    yield delay(500);
    try {
        const pagination: Pagination = yield select(selectPagination);
        const {err, status}: Res<{}> = yield call(request, `${API_URL}/v1/admin/customer/delete/${payload}`, {
            method: 'DELETE',
        });
        if(status === 401){
          const refreshToken = localStorage.getItem("refreshToken");
          yield put(authAction.refreshToken(refreshToken??""));
          return;
        }
        if(err){
          throw err;
        }
        yield put(actions.deleteCustomerSuccess());
        yield put(actions.getCustomers({
            page: 1,
            perPage: pagination?.perPage,
            search: pagination?.search,
            orderBy: pagination?.orderBy,
            orderDirection: pagination?.orderDirection,
            isActive: pagination?.filters?.isActive,
        }));
        toastService.success(i18next.t("CUSTOMERS.SUCCESSFULLY_DELETED"));
    } catch (error:any) {
        yield put(actions.deleteCustomerError());
        let errorMessage = error?.message;
        try {
            errorMessage = JSON.parse(errorMessage);
        } catch (e) {
        }
        toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    }
}

export function* createCustomerRequest({ payload }: { payload: {customer: Customer; callback?: ()=>void;}}) {
    yield delay(500);
    try {
        const {err, status}: Res<{}> = yield call(request, `${API_URL}/v1/admin/customer/create`, {
            method: 'POST',
            body: JSON.stringify({
                organizationId: payload?.customer?.organizationId,
                companyName: payload?.customer?.companyName,
                email: payload?.customer?.email,
                taxNumber: payload?.customer?.taxNumber,
                name: payload?.customer?.name,
                mobileNumber: payload?.customer?.mobileNumber,
                address: {
                    street: payload?.customer?.address,
                    city: payload?.customer?.city?.value || '',
                }
            }),
        });
        if(status === 401){
          const refreshToken = localStorage.getItem("refreshToken");
          yield put(authAction.refreshToken(refreshToken??""));
          return;
        }
        if(err){
          throw err;
        }
        yield put(actions.createCustomerSuccess());
        toastService.success(i18next.t("CUSTOMERS.SUCCESSFULLY_CREATED"));
        payload?.callback?.();
    } catch (error:any) {
        yield put(actions.createCustomerError());
        let errorMessage = error?.message;
        try {
            errorMessage = JSON.parse(errorMessage);
        } catch (e) {
        }
        toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    }
}

export function* customersSaga() {
    yield takeLatest(actions.getCustomer, getCustomerRequest);
    yield takeLatest(actions.getCustomers, getCustomersRequest);
    yield takeLatest(actions.updateCustomer, updateCustomerRequest);
    yield takeLatest(actions.deleteCustomer, deleteCustomerRequest);
    yield takeLatest(actions.createCustomer, createCustomerRequest);
}
