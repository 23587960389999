import { StationTypes } from "app/containers/Stations/types";
import { ListItem } from "app/containers/types";
import i18next from "i18next";

export const APP_API_URL = process.env.REACT_APP_API_URL;

export const APP_VERSION = process.env.REACT_APP_VERSION || "";
export const APP_PUBLIC_URL = process.env.PUBLIC_URL || "";
export const IS_PRODUCTION = process.env.NODE_ENV !== "production";
export const API_URL = APP_API_URL;
export const LOGIN_HEADER_HEIGHT = 96;
export const DASHBOARD_MENU_WIDTH = 217;
export const DASHBOARD_TOP_BAR_HEIGHT = 80;
export const DASHBOARD_MENU_CLOSE_WIDTH = 96;
export const DASHBOARD_TOP_MENU_CLOSE_WIDTH = 48;
export const APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL || "";
export const GOOGLE_PLAY_STORE_URL =
  process.env.REACT_APP_GOOGLE_PLAY_STORE_URL || "";
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || "";

export const DEFAULT_COORDINATES = {
  lat: 31.7683,
  lng: 35.2137,
};

export enum QUESTION_TYPES {
  SINGLE_SELECT = "single_select",
  MULTI_SELECT = "multi_select",
  FREE_TEXT = "free_text",
  MIXED_LETTER = "mixed_letter",
  TEXT_CREATIVE_ASSIGNMENT = "text_creative_assignment",
  IMAGE_CREATIVE_ASSIGNMENT = "image_creative_assignment",
  VIDEO_CREATIVE_ASSIGNMENT = "video_creative_assignment",
  CORRELATION = "correlation",
  NONE = "none",
}

export enum ASSET_TYPE {
  IMAGE = "image",
  VIDEO = "video",
}

export enum Modules {
  TICKETS = "Tickets",
  QUESTIONS = "Questions",
  GAMES = "Games",
  STATIONS = "Stations",
  CUSTOMERS = "Customers",
  ORGANIZATIONS = "Organizations",
  RESULTS = "Results",
  EDITORS = "Editors",
}

export enum MODES {
  NEW = "new",
  EDIT = "edit",
  VIEW = "view",
  DUPLICATE = "duplicate",
}

export const LANGUAGES = [
  {
    label: "ENGLISH",
    value: "en",
  },
  {
    label: "HEBREW",
    value: "hw",
  },
];

export type TAsset = {
  _id: string;
  type: ASSET_TYPE;
  mimeType: string;
  fileName: string;
  filePath: string;
  url?:string;
};

export const generatePageTitle = (title: Modules, mode: MODES) => {
  const name = `${translate(
    `${title?.toUpperCase()}.${title.slice(0, -1)?.toUpperCase()}`
  )}`;
  switch (mode) {
    case MODES.NEW:
      return translate(`COMMON.BREADCRUMB.ADD`, { name });
    case MODES.EDIT:
      return translate(`COMMON.BREADCRUMB.EDIT`, { name });
    case MODES.VIEW:
      return translate(`COMMON.BREADCRUMB.VIEW`, { name });
    case MODES.DUPLICATE:
      return translate(`COMMON.BREADCRUMB.DUPLICATE`, { name });
    default:
      return name;
  }
};

export const translate = (source: string, opt?: {}) => {
  return i18next.t(source, opt);
};

export const debounce = (func: Function, delay: number) => {
  let debounceTimer: any;
  return function () {
    // @ts-ignore
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const modifyMenuOptions = (
  options: ListItem[],
  id: string,
  onClickAction: (optionId: string, id: string, gameId?: string,title?:string) => void,
  gameId?: string,
  title?: string,
): ListItem[] => {
  return options.map((option) => {
    return {
      ...option,
      onClick: () => onClickAction(option.id, id, gameId,title),
    };
  });
}

export const getStationType = (index: number) => {
  switch(index) {
    case 0:
      return StationTypes.POINT;
    case 1:
      return StationTypes.QR;
    case 2:
      return StationTypes.CODE;
    default:
      return StationTypes.POINT;
  }
}

export const getFileNameFromUrl = (url: string, trunc = 0) => {
    if(!url) return ""
    const urlParts = url.split("/")
    const fileName = urlParts[urlParts.length - 1]
    if (trunc > 0 && fileName.length > trunc) {
      // Truncate the filename to the specified length
      return fileName.slice(0, trunc) + "...";
  }

    return fileName
}

export const LEFT_SPACING = '18px';