import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { ResultsState } from '../types';

const selectDomain = (state: RootState) => state.resultsState || ResultsState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectList = createSelector([selectDomain], state => state.list);
export const selectGroupPhotos = createSelector([selectDomain], state => state.list.filter((x)=>x.allowedToPublish && x?.groupPhoto?.url));
export const selectTicket = createSelector([selectDomain], state => state.ticket);

export const selectPagination = createSelector([selectDomain], state => state.pagination);

export const selectError = createSelector([selectDomain], state => state.error);
