import { Box } from "@mui/material";
import CustomTable, { StyledCell } from "app/components/Table";
import { TableHeaderColumnType } from "app/components/Table/types";
import CustomTableRow from "app/components/TableRow";
import { selectList } from "app/containers/Results/redux/selector";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatHumanizedDuration, formatTableTime } from "styles/theme/utils";
const TableHeaderDefinition = {
  NUM: {
    label: "NUM",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  GROUP_NAME: {
    label: "GROUP_NAME",
    format: (value) => value,
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  START_TIME: {
    label: "START_TIME",
    format: (value) => value,
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  END_TIME: {
    label: "END_TIME",
    format: (value) => value,
    maxWidth: 130,
    minWidth: 130,
    sortable: true,
  },
  SCORE: {
    label: "SCORE",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  MISTAKES: {
    label: "MISTAKES",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  LOCATION_MISTAKES: {
    label: "LOCATION_MISTAKES",
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,
    sortable: true,
  },
  POINT: {
    label: "POINT",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  STATIONS_DONE: {
    label: "STATIONS_DONE",
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,
    sortable: false,
  },
  QUESTIONS_DONE: {
    label: "QUESTIONS_DONE",
    format: (value) => value,
    maxWidth: 150,
    minWidth: 150,
    sortable: false,
  },

  TOTAL_TIME: {
    label: "TOTAL_TIME",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },

  STATUS: {
    label: "STATUS",
    format: (value) => value,
    maxWidth: 100,
    minWidth: 100,
    sortable: true,
  },
  CURRENT_STATION: {
    label: "CURRENT_STATION",
    format: (value) => value,
    maxWidth: 200,
    minWidth: 200,
    sortable: true,
  },

};

export const TableHeaders: TableHeaderColumnType[] = Object.values(
  TableHeaderDefinition
);

export default function Table() {
  const list = useSelector(selectList);
  const {t} = useTranslation()
  const getStationFound = (
    assignedStations: string[] | null,
    currentStation: string | null
  ) => {
    if (!assignedStations) {
      return 0;
    }
    if (!currentStation) {
      return assignedStations.length;
    }
    let count = 0;
    for (let index = 0; index < assignedStations.length; index++) {
      const element = assignedStations[index];
      if (element == currentStation) {
        return count;
      }
      count = count + 1;
    }

    return count;
  };
  useEffect(() => {
    console.log("list", list);

    return () => {};
  }, [list]);

  return (
    <Box
      sx={{
        paddingX: "44px",
        marginTop: "32px",
      }}
    >
      <CustomTable headers={TableHeaders} isColoredHeader>
        {list.map((row, index) => (
          <CustomTableRow key={row._id}>
            <StyledCell
              minWidth={TableHeaderDefinition.NUM.minWidth}
              maxWidth={TableHeaderDefinition.NUM.maxWidth}
            >
              {index + 1}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.GROUP_NAME.minWidth}
              maxWidth={TableHeaderDefinition.GROUP_NAME.maxWidth}
            >
              {row.groupName}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.START_TIME.minWidth}
              maxWidth={TableHeaderDefinition.START_TIME.maxWidth}
            >
              {formatTableTime(row.startTime)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.END_TIME.minWidth}
              maxWidth={TableHeaderDefinition.END_TIME.maxWidth}
            >
              {formatTableTime(row.endTime)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.SCORE.minWidth}
              maxWidth={TableHeaderDefinition.SCORE.maxWidth}
            >
              {row.score}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.MISTAKES.minWidth}
              maxWidth={TableHeaderDefinition.MISTAKES.maxWidth}
            >
              {row.mistakes}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.LOCATION_MISTAKES.minWidth}
              maxWidth={TableHeaderDefinition.LOCATION_MISTAKES.maxWidth}
            >
              {row.locationMistakes}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.POINT.minWidth}
              maxWidth={TableHeaderDefinition.POINT.maxWidth}
            >
              {row.point}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.STATIONS_DONE.minWidth}
              maxWidth={TableHeaderDefinition.STATIONS_DONE.maxWidth}
            >
                {t('RESULTS.MIN_OF_MAX', {min: row.completedStations, max: row.totalStations})}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.QUESTIONS_DONE.minWidth}
              maxWidth={TableHeaderDefinition.QUESTIONS_DONE.maxWidth}
            >
                {t('RESULTS.MIN_OF_MAX', {min: row.completedQuestions, max: row.totalQuestions})}
            </StyledCell>

            
            <StyledCell
              minWidth={TableHeaderDefinition.TOTAL_TIME.minWidth}
              maxWidth={TableHeaderDefinition.TOTAL_TIME.maxWidth}
            >
              {formatHumanizedDuration(row.startTime, row.endTime)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.STATUS.minWidth}
              maxWidth={TableHeaderDefinition.STATUS.maxWidth}
            >
              {row.status}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CURRENT_STATION.minWidth}
              maxWidth={TableHeaderDefinition.CURRENT_STATION.maxWidth}
            >
              {row.currentStation?.name}
            </StyledCell>
     
          </CustomTableRow>
        ))}
        {list.length == 0 && (
          <CustomTableRow>
            <StyledCell>No Data</StyledCell>
          </CustomTableRow>
        )}
      </CustomTable>
    </Box>
  );
}
