import { makeStyles } from "@mui/styles";

export const Styles = makeStyles((theme: any) => ({
  root: {
    position: 'absolute',
    left: theme.direction === 'rtl' ? 'auto' : '-14px',  // Adjust for RTL
    right: theme.direction === 'rtl' ? '-8px' : 'auto', // Adjust for RTL
    top: '14px',
    background: '#fff',
  },
}));
