import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "app/components/BreadCrumb";
import Form from "./components/Form";
import { MODES, Modules, generatePageTitle } from "utils/constants";
import ActionButtons from "app/components/ActionButtons";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/slice";
import { useSelector } from "react-redux";
import {
  selectFormValues,
  selectIsLoading,
  selectIsUnsaved,
} from "../../redux/selector";
import { validateOrganization } from "./validator";
import ConfirmDialog from "app/components/ConfirmDialog";
import { useTranslation } from "react-i18next";
import {  actions as orgActions} from 'app/redux/slice';

type Props = { mode: MODES };

const Details = ({ mode }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUnsaved = useSelector(selectIsUnsaved);

  const { id } = useParams();
  const [showConfirm, setShowConfirm] = useState(false);
  const isLoading = useSelector(selectIsLoading);
  const organization = useSelector(selectFormValues);

  useEffect(() => {
    if (!id || mode === MODES.NEW) return;
    dispatch(actions.getOrganization(id));
  }, [mode, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetForm());
    };
  }, [dispatch]);

  const onSubmit = () => {
    const errors = validateOrganization(organization);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.createOrganization({
        organization: {
          name: organization.name.value,
          contactName: organization.contactName.value,
          email: organization.email.value,
          mobileNumber: organization.mobileNumber.value.replace("-", ""),
          taxNumber: organization.taxNumber.value,
          address: organization.address.value,
          note: organization.note.value,
          _id: organization.id.value,
        },
        callback: () => {
          dispatch(orgActions.getOrganizations({}));
          navigate("/app/organizations");
        },
      })
    );
  };

  const onUpdate = () => {
    const errors = validateOrganization(organization);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.updateOrganization({
        organization: {
          name: organization.name.value,
          contactName: organization.contactName.value,
          email: organization.email.value,
          mobileNumber: organization.mobileNumber.value.replace("-", ""),
          taxNumber: organization.taxNumber.value,
          address: organization.address.value,
          note: organization.note.value,
          _id: organization.id.value,
        },
        callback: () => {
          dispatch(orgActions.getOrganizations({}));
          navigate("/app/organizations");
        },
      })
    );
  };

  const handleConfirmBtn = () => {
    if (mode === MODES.NEW || mode === MODES.DUPLICATE) {
      onSubmit();
      return;
    }
    if (mode === MODES.EDIT) {
      onUpdate();
      return;
    }
    navigate(`/app/organizations/${id}/edit`);
  };

  const handleCancelBtn = () => {
    if (mode == MODES.EDIT) {
      if (isUnsaved) {
        setShowConfirm(true);
        return;
      }
    }
    navigate("/app/organizations");
  };

  const handelConfirm = () => {
    setShowConfirm(false);
    navigate("/app/organizations");
  };

  return (
    <>
      <div className={styles.breadCrumbSectionParent}>
        <BreadCrumb
          title={generatePageTitle(Modules.ORGANIZATIONS, mode)}
          onBackClick={handleCancelBtn}
        />
      </div>
      <Form mode={mode} />
      <div style={{ height: "20px" }} />
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      ></Grid>
      <ActionButtons
        isLoading={isLoading}
        handleCancelBtn={handleCancelBtn}
        handleConfirmBtn={handleConfirmBtn}
        mode={mode}
      />
      <ConfirmDialog
        open={showConfirm}
        handleClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={handelConfirm}
        title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
        message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
          name: t("COMMON.SCREEN_NAME.ORGANIZATION"),
        })}
        messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
        cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
        confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
      />
    </>
  );
};

export default Details;
